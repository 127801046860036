import React, { useState, useEffect } from "react";
import Navbar from '../../components/Navbar'
import QRCode from 'qrcode';
import FETCH_API from "../../config/fetch_api";
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import { formatDate } from "../../utils/formatDate";
import { useToasts } from 'react-toast-notifications'
import { send_email, proses_data } from "../../config/config"
import Gap from "../../components/Gap";
import { router } from "../../Constants"
import { encrypt, decrypt } from "../../utils/enkripsi"
import { SocketOnline } from "../../utils/socket";

function Barcode(params) {
    const location = useLocation()
    const { state } = location
    console.log(state)
    const { addToast } = useToasts()
    // console.log("data payment", state.params)
    const navigate = useNavigate()
    const [imageUrl, setImageUrl] = useState('');
    const [checkPembayaran, setCheckPembayaran] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const userServer = JSON.parse(localStorage.getItem("userServer"))
    const id = JSON.parse(localStorage.getItem("idpesanan"))
    const databranch = JSON.parse(localStorage.getItem("databranch"))

    const [timeoutId, setTimeoutId] = useState(null);

    async function CekStatus() {
        try {
            let typeDelivery = JSON.parse(localStorage.getItem("typePembayaran"))
            const drivery = decrypt(localStorage.getItem("saveDelivery"))
            const dataPeyment = state.params
            const user = JSON.parse(localStorage.getItem('login'))
            const orderDate = JSON.parse(localStorage.getItem("orderDate"))
            const id = JSON.parse(localStorage.getItem("idpesanan"))
            const checkPayment = await FETCH_API("payment-gateway/checkSuccessXendit", {
                id: state.params['external_id'],
                userServer: userServer
            })
            const detailData = []
    
            for (const items of state.detailTrans) {
                detailData.push({
                    "namaProduct": items['name'],
                    "qty": items['qty'],
                    "price": items['price'],
                    "subtotal": items['total']
                })
            }
               
            // if (checkPayment['hasil']['status'] == "ACTIVE") {
            if (checkPayment?.['hasil']?.['status'] == "ACTIVE") {
                console.log(detailData)
    
                setLoading(false)
                addToast("Pembayaran belum selesai", {
                    appearance: 'info',
                    autoDismiss: true
                })
            }
            else {
                addToast("Pembayaran Berhasil", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                    // if (drivery.id == "gojek") {
                    //     navigate(`/${router}status-gojek`)
                    // }
                    // else {
                    //     navigate(`/${router}home/lacak-pesanan`)
                    // }
                 
                const result = await proses_data()
                if (result.status == 'OK') {
                    SocketOnline.emit('delivery', {
                        branch_id   : JSON.parse(localStorage.getItem('dataBranch')).id,
                        userServer  : userServer,
                        id          : id,
                        type        : localStorage.getItem('type_delivery') 
                    });
                    send_email({
                        "subject": 'Pembayaran Uruz',
                        "carabayar": 'QR Code',
                        "idTransaksi": state.detailData.id,
                        "subTotal": state.detailData.sub_total,
                        "grandTotal": state.detailData.grand_total_nett,
                        "linkLihatDetail": "https://google.com",
                        "detailData": detailData
                    })
                    addToast("Pembayaran Berhasil", {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    navigate(`/${router}home/lacak-pesanan`)
                    // if (drivery.id == "gojek") {
                    //     navigate(`/${router}status-gojek`)
                    // }
                    // else {
                    //     navigate(`/${router}home/lacak-pesanan`)
                    // }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // GojekStatus()
        // GojekUpdateStatus()
    
    
        SocketOnline.on('connect', () => {
        console.log('Socket connected');
        // Additional logic after the connection is established
        });

        SocketOnline.on("connect_error", (err) => {
                console.log(`aku error guys ${err}`);
                });
        
                SocketOnline.on("payment-success", (data) => {
                console.log("cobaa1", data);
                    // CekStatus()
                        if(data.id === id){
                            CekStatus()
                        }                    
                  
                   
                });
    }, []);
    useEffect(async () => {

        const response = await QRCode.toDataURL(state.params['qr_string']);

        setImageUrl(response);
        window.addEventListener('popstate', (event) => {
            navigate(`/${router}home`)
        });
        setTimeout(() => {
            setCheckPembayaran(true)
        }, 10000)

    }, [])

    return (
        <div>
            <Navbar urlBack='home/lacak-pesanan' paramsUrlBack={{
                params: state['params']
            }} type="PaymentNobu" label="Pembayaran QRIS" />
            <div style={{ paddingTop: '20%' }}>
                <div style={{ margin: '0 20px' }}>
                    <div style={{ fontSize: 17, textAlign: 'center' }} className="fonts600">Buka aplikasi E-Wallet Anda dan Scan QR Code untuk membayar pesanan Anda</div>
                    <Gap height={30} />
                    <div style={{ color: '#5451D6', fontSize: 17, textAlign: 'center' }} className="fonts700">Pembayaran dapat dilakukan melalui OVO, DANA, LinkAja, ShopeePay dan Sakuku</div>
                </div>
                <div style={{ justifyContent: 'center', display: 'grid', padding: '24px 0' }}>
                    {imageUrl ? (
                        <a href={imageUrl} download>
                            <img style={{ width: 250, height: 250 }} src={imageUrl} alt="img" />
                        </a>) : <Loading />}
                </div>
                <div style={{ margin: '0 10%' }}>
                    {
                        // checkPembayaran &&
                        <Button onClick={() => {
                            CekStatus()
                            setLoading(true)
                        }} loading={loading} color="#fff" backgroundColor="#04295E">
                            Cek Pembayaran
                        </Button>
                    }
                </div>
                <div style={{ margin: '2% 10%' }}>
                    {
                        // checkPembayaran &&
                        <a href={imageUrl} download>
                        <Button color="#fff" backgroundColor="#04295E">
                            Save QR
                        </Button>
                        </a>
                    }

                </div>
            </div>
        </div>
    )
}


export default Barcode