import React, { useState, useEffect } from 'react'
import '../../Pages/DetailOrder/detailorder.css';
import Navbar from '../../components/Navbar'
import { FaConciergeBell } from 'react-icons/fa'
import { AiOutlinePlus,AiFillPlusCircle, AiFillMinusCircle, AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai'
import { DistanceMatrixService, LoadScript } from "@react-google-maps/api";
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Select } from 'antd';
import { useSelector, useDispatch } from "react-redux"
import { onHitung as hitung } from "../../utils/onHitung"
import Merch1 from '../../Assets/Merchant/M1.png';

// API

import api from '../../request'

// actions Redux
import { getDistanceAndPrice } from "../../redux/actions/gojekActions"
import { getCharges } from "../../redux/actions/chargesActions"
import { getCoaXendit } from "../../redux/actions/xenditActions"


import formatterMoney from '../../utils/formatMoney'
import Input from '../../components/Input'
import { FiEdit } from 'react-icons/fi'
import { BiMap } from 'react-icons/bi'
import {googleMapApiKey, router,url } from '../../Constants.js'
import Loading from '../../components/Loading'
import Button from "../../components/Button"
import Gap from "../../components/Gap"
import InputPhone from '../../components/InputPhone'
import validateEmail from '../../utils/validateEmail';
import FormatPhone from "../../utils/FormatPhone"
import { encrypt, decrypt } from "../../utils/enkripsi"
import { setKeranjang } from "../../redux/actions/prepareActions"
import { BiHighlight } from "react-icons/bi"
import { AiOutlineCheckCircle } from "react-icons/ai"
import { Container, Row, Col, Form, Dropdown, FormGroup } from 'react-bootstrap';

const { Option } = Select;

export default function DetailOrder({location}) {
    const dispatch = useDispatch()
    const stateGojek = useSelector(state => state?.gojekReducer)
    const { addToast } = useToasts()
    let navigate = useNavigate();
    const [buttonOrder, setButtonOrder] = React.useState('delivery')
    // const [buttonOrder, setButtonOrder] = React.useState('pickup')
    const [dataOrder, setDataOrder] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [loadingPembayaran, setLoadingPembayaran] = React.useState(false)
    const [cekLokasi, setCekLokasi] = React.useState(false)
    const [typeDelivery, setTypeDelivery] = React.useState('delivery')
    const [gojekType, setGojekType] = React.useState('')
    const [detailPembayaran, setDetailPembayaran] = React.useState([])
    let type_delivery =  "gojek"
    const typeDev = localStorage.getItem('type_delivery')
    const [tableid,setTableid] = React.useState(localStorage.getItem('table_id'))
    if (typeDev) {
        type_delivery = typeDev
    }
    const login = localStorage.getItem('login')
    const distance = localStorage.getItem('distance')
    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    var today = year + "-" + month + "-" + day;
    

    var d = new Date();
    let hours   = d.getHours() < 10 ? "0"+d.getHours() : d.getHours()
    let minutes = d.getMinutes() < 10 ? "0"+d.getMinutes() : d.getMinutes() 
    var n = hours +":"+minutes

    const [detailUsers, setDetailUsers] = React.useState({
        nama: '',
        email: '',
        nomerHp: '',
        date: today,
        time: n
    })

    let alamat_users = localStorage.getItem('alamatUsers')
    let dataBranch = JSON.parse(localStorage.getItem('dataBranch'))
    let longlat = JSON.parse(localStorage.getItem('longlat'))
    if (alamat_users == null && alamat_users == undefined) {
        alamat_users = null
    }
    else {
        alamat_users = JSON.parse(alamat_users)
    }

    const [isMenuSubMenu, setMenuSubMenu] = useState(true);

    let boxClassSubMenu = ["sub__menus"];
    if(isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    }else {
        boxClassSubMenu.push('');
    }

    // React.useEffect(async() => {
    //     try {
    //         if(distance !== null){
    //             const xi = [];
    //             if (type_delivery == "delivery") {

    //             const result = await api.charges.getHargaJarak({
    //                 "distance": Number(distance)
    //             });
    //             console.log(result.data.data)
    //             xi = encrypt({
    //             harga: result.data?.data,
    //             km: distance,
    //             validate: true,
    //             id: result.data.id,
    //             });
    //             localStorage.setItem("saveDelivery", xi);
    //             }
    //             if (type_delivery == "gojek") {
    //             const value = stateGojek?.getDistanceAndPrice;
    //             const sii = encrypt({
    //                 harga: value?.price?.total_price,
    //                 km: value?.distance?.toString() + 'km',
    //                 validate: false,
    //                 id: "gojek"
    //             })
    //             localStorage.setItem("saveDelivery", sii);
    //             }
    //             onHitung();
    //             localStorage.setItem("copy_delivery", xi);
    //             localStorage.setItem('longlat', true);
    //     } 
    //     } catch (error) {
   
    //     }
        

    // },[distance,typeDelivery])

    // const handleInputChange = (event,data) => {
    //     console.log(data,event)
    //     const pesanan = decrypt(JSON.parse(localStorage.getItem("pesanan")))
    //     const selectPesanan = pesanan.filter((items) => items['product_id'] == data['product_id'] && items.name == data.name)
    //     data.item = event.target.value
    //     for(const xi of selectPesanan){
    //         xi['item'] = data.item
    //         xi['total'] = data['price'] && data.discount? data['price'] - (data.price*data.discount/100) : data['price'] && !data.discount? data['price'] : data.harga && data.discount ? data.harga - (data.harga*data.discount/100) : data.harga
    //     }
    //     localStorage.setItem("pesanan", JSON.stringify(encrypt(pesanan)))
    //     dispatch(setKeranjang())
    //     setDataOrder(pesanan)
    //     onHitung()
    //   };
    React.useEffect(() => {
        dispatch(getDistanceAndPrice({
            detailData: {
                "originLatLong" : `${alamat_users?.lat}, ${alamat_users?.lng}`,
                "destinationLatLong"  : `${dataBranch?.latitude}, ${dataBranch?.longitude}`
            }
        }))
        dispatch(getCharges())
    },[location])


    React.useEffect(() => {
        const s = localStorage.getItem("type_delivery")
        onHitung()
        handleChange(s)
        
    },[stateGojek.getDistanceAndPrice])



    function handleChange(value) {
        if (alamat_users !== null) {            
            if (value == "delivery") {     
                setGojekType('') 
                const drivery = decrypt(localStorage.getItem("copy_delivery"))
                const drivery1 = decrypt(localStorage.getItem("saveDelivery"))
                localStorage.setItem("saveDelivery", encrypt({
                    harga: drivery?.harga,
                    km: drivery?.km,
                    validate: false,
                    id: drivery?.id
                }))
                localStorage.setItem("type_delivery", "delivery")
                setTypeDelivery(value)
                onHitung()
            }
            else {  
                const value = stateGojek?.getDistanceAndPrice
                if (value?.errors) {
                    addToast(value?.errors[0]['message'], {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
                else {
                    localStorage.setItem("type_delivery", "gojek")
                    setTypeDelivery(value)
                    setGojekType("Instant")
                    localStorage.setItem("saveDelivery", encrypt({
                        harga: value?.price?.total_price,
                        km: value?.distance?.toString() + 'km',
                        validate: false,
                        id: "gojek"
                    }))
                    onHitung()
                }
            }
        }
        else {
            // addToast("Alamat harus di isi", {
            //     appearance: 'error',
            //     autoDismiss: true,
            // })
        }
    }

    function onHitung(){
        const dataOrder = decrypt(JSON.parse(localStorage.getItem("pesanan")))
        const drivery = decrypt(localStorage.getItem("saveDelivery"))
        const pesanan = dataOrder.map(item => ({
            qty: item.item,
            price: item.total
            // price: item.total? item.total : item.price? item.price :  item.harga
        }))
        let total = []
        const s = hitung(pesanan)
        const value = localStorage.getItem('pickup_delivery')     
        total.push({
            label: 'Subtotal',
            value: s?.data?.sub_total
        })
        for(const x of s.chargeNotes){
            if (value  == "pickup") {
                if (!["gosendfee", "deliveryfee"].includes(x.id)) {
                    total.push({
                        label: (x.id + " " + x.percent_value + '%'),
                        value: x.value
                    })
                }
            }
            else {
                total.push({
                    label: ["gosendfee", "deliveryfee"].includes(x.id) ? x.id : (x.id + " " + x.percent_value + '%'),
                    value: x.value
                })
            }
        }
        total.push({
            label:'Total Pembayaran',
            value: s?.data?.grand_total_net 
            // value: s?.data?.sub_total

        })
        // console.log('total >>  '+JSON.stringify(total))
        setDetailPembayaran(total)
    }

    function onPressMinus(data){
        const pesanan = decrypt(JSON.parse(localStorage.getItem("pesanan")))
        const selectPesanan =  pesanan.filter((items) => items['product_id'] == data['product_id'] && items.name == data.name)
        data.item -= 1
        if(data.item  < 1){
            let dataLocal = decrypt(JSON.parse(localStorage.getItem('pesanan')))
            let getData   = dataLocal.filter(item => item['product_id'] !== data['product_id'] || item.name !== data.name)
            const deletPesanan = pesanan.filter((items) => items['product_id'] !== data['product_id']  || items.name !== data.name)
            localStorage.setItem("pesanan", JSON.stringify(encrypt(deletPesanan)))
            dispatch(setKeranjang())
            setDataOrder(getData)
            onHitung()
            if (getData.length == 0) {
                navigate(`/${router}home`)
            }
            return
        }
        for(const xi of selectPesanan){
            if (xi['item'] > 0) {             
                xi['item'] -= 1
                xi['total'] -= data['price'] && data.discount? data['price'] - (data.price*data.discount/100) : data['price'] && !data.discount? data['price'] : data.harga && data.discount ? data.harga - (data.harga*data.discount/100) : data.harga
            }
        }
        localStorage.setItem("pesanan", JSON.stringify(encrypt(pesanan)))
        dispatch(setKeranjang())
        setDataOrder(pesanan)
        onHitung()
    }   

    function onPressPlus(data){
        const pesanan = decrypt(JSON.parse(localStorage.getItem("pesanan")))
        const selectPesanan = pesanan.filter((items) => items['product_id'] == data['product_id'] && items.name == data.name)
        data.item += 1
        for(const xi of selectPesanan){
            xi['item'] += 1
            xi['total'] += data['price'] && data.discount? data['price'] - (data.price*data.discount/100) : data['price'] && !data.discount? data['price'] : data.harga && data.discount ? data.harga - (data.harga*data.discount/100) : data.harga
        }
        localStorage.setItem("pesanan", JSON.stringify(encrypt(pesanan)))
        dispatch(setKeranjang())
        setDataOrder(pesanan)
        onHitung()
    }

    async function refreshDataOrder() {
        let data = localStorage.getItem('pesanan')
        if (data == null) {
            setDataOrder([])
            data = []
        }
        else {
            data = decrypt(JSON.parse(data))
            for(const x of data){
                x['selection'] = false
            }
            setDataOrder(data)
        }

        let result = 0
        for(const x of data){
            result += x['total']
        }
        localStorage.setItem("pesanan", JSON.stringify(encrypt(data)))
        setLoading(false)
        onHitung()
    }

    useEffect(() => {
        localStorage.setItem('detailpembayaran', JSON.stringify(detailPembayaran))
    }, [detailPembayaran]);

    useEffect(async() => {
        refreshDataOrder()
        const users = JSON.parse(localStorage.getItem('login'))
        const orderDate = JSON.parse(localStorage.getItem('orderDate'))
        const pickup_delivery = localStorage.getItem('pickup_delivery')

        if (pickup_delivery) {
            setButtonOrder(pickup_delivery)
        }
        else {
            localStorage.setItem("pickup_delivery", 'delivery')
        }
        // localStorage.setItem("pickup_delivery", 'pickup')
        if (users && !orderDate) {
            setDetailUsers(val => ({
                ...val,
                nama: users.nama,
                nomerHp: FormatPhone(users.telp),
                email: ''
            }))
        }
        if (orderDate) {
            setDetailUsers(val => ({
                ...val,
                nama: orderDate.nama,
                nomerHp: FormatPhone(orderDate.nomerHp),
                email: orderDate.email,
                date: orderDate.date,
                time: orderDate.time
            }))
        }
    },[])

    // useEffect(() => {
    //     const s = localStorage.getItem("type_delivery")
    //     console.log(s,typeDelivery)
    //     onHitung()
    //     handleChange(s)
    // },[longlat,typeDelivery])
    
    async function getAllGuest(){
        const data = await api.apiGuest.getAll({
            "from_origin": "*",
            "userServer": "cooluruz",
            "userId": "admin"
          });
          const dataGuest = data.data.data
          const filtered = dataGuest.filter(data =>
            data.id.toLowerCase().includes(detailUsers['nama'].toLowerCase())
          );
        //   if(filtered[0] === undefined){
        //     addGuest()
        //   }
        //   else if(filtered[0] !== undefined){
        //     get1Guest()
        //   }
        //   for(let i=0;i<dataGuest.length;i++){
        //     if(dataGuest[i].id !== detailUsers.nama){
        //         addGuest()
        //     }
        //     else{
        //         get1Guest()
        //     }
        //   }
    }
    
    async function get1Guest(){
        const data = await api.apiGuest.get1({
            "from_origin": "*",
            "userServer": "cooluruz",
            "userId": "admin",
            "id": detailUsers.nama
          });
          localStorage.setItem("loyalty", JSON.stringify(data.data.data[0]))
    }
    async function addGuest(){
        const data = await api.apiGuest.save({
            "from_origin": "*",
            "userServer": "cooluruz",
            "userId": "admin",
            "mode": "insert",
            "detailData": [
              {
                "old_id": "",
                "id": detailUsers.nama,
                "country_id": "",
                "name": detailUsers.nama,
                "id_card_type": "ktp",
                "id_card_no": "123",
                "gender": "male",
                "nationality": "+62",
                "province": "UMUM",
                "city": "UMUM",
                "address": "",
                "email": detailUsers.email,
                "npwp": "",
                "phone1": detailUsers.nomerHp,
                "phone2": "",
                "fax": "",
                "marketing_id": "",
                "marketing_name": "",
                "mobile": "",
                "person_in_charge": "",
                "coa": "1.01.03",
                "coa_name": "PIUTANG USAHA",
                "coa_deposit": "",
                "coa_deposit_name": "",
                "picpath": "",
                "picpath_thumb": "",
                "top": 0,
                "top_tolerance": 0,
                "disc1": 0,
                "disc2": 0,
                "tax": 0,
                "note": "",
                "ar_limit": 0,
                "created_by": "",
                "created_by_name": "",
                "created_date": "",
                "updated_by": "-",
                "updated_by_name": "",
                "updated_date": "",
                "close_table_closing": 0,
                "active": 1,
                "kirim_nama": "-",
                "kirim_alamat": "-",
                "kirim_penerima": "-",
                "kirim_phone": "-",
                "kirim_province": "-",
                "kirim_city": "-",
                "isLogin": 0,
                "coa_point_internal": "",
                "coa_point_internal_name": "",
                "coa_point_external": "",
                "coa_point_external_name": "",
                "point_reward_internal": "",
                "point_reward_internal_name": "",
                "point_reward_external": "",
                "point_reward_external_name": ""
              }
            ]
          });
    }

     async function onPembayaran () {
        try {
            setLoadingPembayaran(true)
            // getAllGuest()
            const pickup_delivery = localStorage.getItem('pickup_delivery')
            if (!alamat_users && pickup_delivery == "delivery") {
                setLoadingPembayaran(false)
                addToast("Alamat harus di isi", {
                    appearance: 'error',
                    autoDismiss: true,
                })
                return
            }
            if (detailUsers['nama'] == '' && !localStorage.getItem('login')) {
                setLoadingPembayaran(false)
                addToast("Nama harus diisi", {
                    appearance: 'error',
                    autoDismiss: true,
                })
                return
            }
            if (detailUsers['nomerHp'] == '' && !localStorage.getItem('login')) {
                setLoadingPembayaran(false)
                addToast("No Telepon harus diisi", {
                    appearance: 'error',
                    autoDismiss: true,
                })
                return
            }
            if (detailUsers['email'] == '' && !localStorage.getItem('login')) {
                setLoadingPembayaran(false)
                addToast("Email harus diisi", {
                    appearance: 'error',
                    autoDismiss: true,
                })
                return
            }
            if (!validateEmail(detailUsers['email']) && !localStorage.getItem('login')) {
                setLoadingPembayaran(false)
                addToast("Email salah", {
                    appearance: 'error',
                    autoDismiss: true,
                })
                return
            }
            
            else {    
                try {
                    const coa = await api.xendit.getCoaXendit()
                if (coa.data.data.length !== 0) {                
                    localStorage.setItem("coaweb", JSON.stringify(coa?.data?.data[0]))
                    detailUsers['nomerHp'] = '+62' + detailUsers['nomerHp']
                    localStorage.setItem('orderDate', JSON.stringify(detailUsers))
                    if (buttonOrder == 'delivery') {            
                        localStorage.setItem('typePembayaran', JSON.stringify({
                            label: typeDelivery,
                            value: gojekType
                        }))
                    }
                    else {
                        localStorage.setItem('typePembayaran', JSON.stringify({
                            label: "pickup",
                            value: ""
                        }))
                    }
                    setTimeout(() => {
                        setLoadingPembayaran(false)
                    },1000)
                    if(dataOrder != [] ){
                        navigate(`/${router}metode-pembayaran/:id`)
                    }
                    else{
                        addToast('Belum Menambahkan Pesanan', {
                            appearance: 'error',
                            autoDismiss: true,
                        })  
                    }
                    
                }
                else {
                    addToast(`COA Tidak ditemukan di Branch ${dataBranch?.branchName}`, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                    setLoadingPembayaran(false)
                }
                } catch (error) {
                    console.log(error)
                }
                
            }
        
        } catch (error) {
            console.log(error)
        }
       
    }

    const [selectedValue, setSelectedValue] = useState('Pilih Pengiriman');
    const items = [
        { key: 'delivery', label: 'Inhouse Delivery' },
        { key: 'gojek', label: 'Gojek' },
        { key: 'grab', label: 'Grab' }
    ];

    const handleSelect = (eventKey) => {
        const selectedItem = items.find(item => item.key === eventKey);
        const newValue = selectedItem ? selectedItem.label : 'Pilih Pengiriman';
        setSelectedValue(newValue);
    }
    
    return (
        <div className='Main'>
            <Navbar setCekLokasi={setCekLokasi} cekLokasi={cekLokasi} buttonOrder={buttonOrder} urlBack="home" type="Payment" label="Keranjang"/>
            
            <div>
                <Container>
                    <Row xs={2} style={{paddingTop:'1vh', paddingBottom:'1vh'}}>
                        <Col xs={6}>
                            <button onClick={() => { 
                                setButtonOrder('delivery')
                                localStorage.setItem("pickup_delivery", 'delivery')
                                onHitung()
                            }} style={{ width: '100%', marginRight: 10, height: 35, borderRadius: 5, border: 'none', backgroundImage: buttonOrder == 'delivery'? 'linear-gradient(#04295E, #04295E)' : 'linear-gradient(#fff, #c7c7c7)'}}>
                                <span className="fonts500" style={{ color:  buttonOrder == 'delivery'? '#fff' : '#04295E'}}>Delivery</span>
                            </button>
                        </Col>
                        <Col xs={6}>
                            <button onClick={() =>  {
                                setButtonOrder('pickup')
                                localStorage.setItem("pickup_delivery", 'pickup')
                                onHitung()
                            }} style={{ width: '100%', height: 35, borderRadius: 5, border: 'none', backgroundImage: buttonOrder !== 'delivery'? 'linear-gradient(#04295E, #04295E)' : 'linear-gradient(#fff, #c7c7c7)'}}>
                            <span className="fonts500" style={{ color: buttonOrder !== 'delivery'? '#fff' : '#04295E'}}>Pickup</span>
                            </button>
                        </Col>
                    </Row>
                </Container>

                {
                (alamat_users && dataBranch.latitude && dataBranch.longitude && !longlat) && (
                    <LoadScript googleMapsApiKey={googleMapApiKey} onLoad={() => {}}>
                    <DistanceMatrixService
                        options={{
                        destinations: [{ lat: Number(dataBranch.latitude), lng: Number(dataBranch.longitude) }],
                        origins: [{ lng: alamat_users.lng, lat: alamat_users.lat }],
                        travelMode: "DRIVING",
                        }}
                        callback={async (res) => {
                        try {
                            if (res) {
                            try {
                                const distanceMatrixService = res.rows[0].elements[0].distance;
                                const m = distanceMatrixService.value;
                                var km = m / 1000;
                                var x = km.toFixed(1).split('.');
                                localStorage.setItem("distance", Number(x[1]) > 4 ? Number(x[0]) + 1 : Number(x[0]));
                                var result;
                                try {
                                result = await api.charges.getHargaJarak({
                                    "distance": Number(x[1]) > 4 ? Number(x[0]) + 1 : Number(x[0])
                                });
                                    const xi = encrypt({
                                    harga: result.data?.data,
                                    km: distanceMatrixService['text'].toLowerCase(),
                                    validate: true,
                                    id: result.data.id,
                                    });
                
                                    if (type_delivery == "delivery") {
                                    localStorage.setItem("saveDelivery", xi);
                                    }
                                    if (type_delivery == "gojek") {
                                    const value = stateGojek?.getDistanceAndPrice;
                                    const sii = encrypt({
                                        harga: value?.price?.total_price,
                                        km: value?.distance?.toString() + 'km',
                                        validate: false,
                                        id: "gojek"
                                    })
                                    localStorage.setItem("saveDelivery", sii);
                                    }
                                    onHitung();
                                    localStorage.setItem("copy_delivery", xi);
                                    localStorage.setItem('longlat', true);
                                } catch (apiError) {
                                console.error("API error when calling getHargaJarak:", apiError?.response?.data?.message);
                                // addToast(apiError?.response?.data?.message, {
                                //     appearance: 'error',
                                //     autoDismiss: false,
                                // });
                                  const xi = encrypt({
                                    harga: 0,
                                    km: '0km',
                                    validate: true,
                                    id: 'delivery',
                                  });

                                  if (type_delivery == "delivery") {
                                    localStorage.setItem("saveDelivery", xi);
                                  }
                                  onHitung();
                                  localStorage.setItem("copy_delivery", xi);
                                  localStorage.setItem('longlat', true);
                                  return; // Exit the callback
                                }
                            
                            } catch (error) {
                                console.error("An error occurred:", error);
                            }
                            } else {
                            console.error("No response received from DistanceMatrixService");
                            addToast("Permission lokasi belum diaktifkan, mohon ke pengaturan browser untuk mengatifkan lokasi", {
                                appearance: 'error',
                                autoDismiss: false,
                            });
                            }
                        } catch (error) {
                            console.error("An error occurred:", error);
                            // Handle any other unexpected errors
                        }
                        }}
                    />
                    </LoadScript>
                )
                }


                <div className='AppBarOrder'>
                    <Container className='Merchant' xl={2} xs={2}>
                        <Row xs={2} style={{display:'flex', alignItems:'center', textAlign:'center'}}>
                            <Col className='Merchant-Logo' xl={3} xs={3} style={{   }}>
                                {/* <img src={`${url}${dataBranch?.picpath}`}></img> */}
                                <img style={{}} src={`${dataBranch?.picpath}`}></img>

                            </Col>
                            <Col className='Merchant-Address' xs={8} style={{marginLeft:'4%',textAlign:'start'}}>
                            <div style={{fontSize:'16px',fontWeight:'700',display:'flex'}}>
                                    {JSON.parse(localStorage.getItem('dataBranch')).name}
                                </div>
                                <div className='desc'>
                                    {JSON.parse(localStorage.getItem('dataBranch')).address}
                                </div>
                            </Col>
                        </Row>
                    </Container>                    
                </div>

                <div className='horizontal-divider'></div>
            </div>
            
            <div>
            {
                buttonOrder == 'delivery' &&
                <div>
                <Container className='Form-1'>
                    <Row className='Item'>
                        <FormGroup className='Container'>
                            <Form.Label>Pengiriman</Form.Label>
                            
                           
                <div style={{fontWeight:600, borderRadius: 10, marginTop: 20 }}>
                    {/* <div>Gojek</div> */}
                    <Select defaultValue={type_delivery}  style={{ width: '100%' }} onChange={handleChange}>
                      <Option value="delivery">Inhouse Delivery</Option>
                      <Option value="gojek">Gojek</Option>
                    </Select>
                </div>
           
                            {/* <Dropdown onSelect={handleSelect}>
                                <Dropdown.Toggle variant="light" className='button-item d-flex justify-content-between align-items-center' size='md'>
                                    <span>{selectedValue}</span>
                                </Dropdown.Toggle>

                                {
                                    buttonOrder == 'delivery' &&
                                        <Dropdown.Menu className='delivery-opt' defaultValue={type_delivery} onChange={handleChange}>
                                            { items.map(item => (
                                                <Dropdown.Item key={item.key} eventKey={item.key}>
                                                    {item.label}
                                                </Dropdown.Item>
                                            ))}
                                            
                                        </Dropdown.Menu>
                                }
                            </Dropdown> */}
                            
                            {/* <Form.Label>Pilih Pengiriman</Form.Label>
                            {
                                buttonOrder == 'delivery' &&
                                <Select defaultValue={type_delivery} style={{ width: '100%'}} onChange={handleChange}>
                                    <Option value="delivery">Inhouse Delivery</Option>
                                    <Option value="gojek">Gojek</Option>
                                    <Option value="">Grab</Option>
                                </Select>
                            } */}
                            
                        </FormGroup>
                    </Row>
                </Container>
                <div className='horizontal-divider'></div>

                <Container className='Form-1'>
                    <Row className='Item'>
                        <Container>
                            <Row xs={2} style={{}}>
                                <Col xs={8} style={{fontSize:'16px',fontWeight:600}}>
                                    Alamat Pengiriman
                                </Col>
                                {
                                    login ||  alamat_users == null ?
                                    <Col xs={4} className='add-button' onClick={() => navigate(`/${router}alamat-pengiriman`)}>Tambah Alamat</Col>
                                    : null
                                }
                            </Row>
                            
                            <div className='horizontal-divider-thick'></div>

                            {
                                buttonOrder == 'delivery' && alamat_users !== null &&
                                <Container fluid style={{padding:'0'}}>
                                    <Row className='Address'>
                                        <Col xs={10}>
                                            <div className='title'>{alamat_users['type']}</div>
                                        </Col>
                                        <Col xs={2} style={{margin:'auto', textAlign:'end'}}>
                                            <div 
                                                onClick={() => navigate(`/${router}add-alamat-pengiriman`, {state:{
                                                    position: {
                                                        lat: alamat_users.lat,
                                                        lng: alamat_users.lng
                                                    },
                                                    addressUsers: alamat_users,
                                                    type: 'edit'
                                                }})} 
                                                
                                                className="fonts500" style={{color:"#3582B9", fontSize: 14}}>Edit
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <div className='Menu-desc'style={{color: '#BFBFBF',fontSize:'12px'}}>{alamat_users['alamat']}</div>
                                    <div className='Note'> 
                                   {'Note : '+alamat_users['detailAlamat']}
                                    </div>
                                    
                                </Container>
                            }
                        </Container>
                    </Row>
                </Container>
</div> }

                {/* <div className='horizontal-divider'></div> */}

                <Container className='Form-1'>
                    {
                        !localStorage.getItem("login") &&
                        <Row className='Item'>
                             

                            <Form.Group className="Container" >
                                <Form.Label className='title'>Nama</Form.Label>
                                <Input placeholder="Contoh : John" 
                                    onBlur={() => {
                                        localStorage.setItem('orderDate', JSON.stringify(detailUsers))
                                    }} value={detailUsers['nama']} 
                                
                                    onChange={(val) => setDetailUsers((old) => ({
                                        ...old, nama: val.target.value
                                    }))} />
                            </Form.Group>

                            <Form.Group className="Container" >
                                <Form.Label className='title'>Nomor Handphone</Form.Label>
                                <InputPhone type="tel" placeholder="Contoh : 0821218*****"
                                    onBlur={() => {
                                        localStorage.setItem('orderDate', JSON.stringify(detailUsers))
                                    }} value={detailUsers['nomerHp']} onChange={(val) => setDetailUsers((old) => ({
                                        ...old,
                                        nomerHp: val
                                    }))} />
                            </Form.Group>

                            <Form.Group className="Container" >
                                <Form.Label className='title'>Email</Form.Label>
                                <Input type="email" placeholder="Contoh : John@example.com"  
                                    onBlur={() => {
                                        localStorage.setItem('orderDate', JSON.stringify(detailUsers))
                                    }} value={detailUsers['email']} 
                                    onChange={(val) => setDetailUsers((old) => ({
                                        ...old,
                                        email: val.target.value
                                    }))} />
                            </Form.Group>

                            <Form.Group className="Container" >
                                <Form.Label className='title'>Note</Form.Label>
                                <Input
                                    onBlur={() => {
                                        localStorage.setItem('orderDate', JSON.stringify(detailUsers))
                                    }} value={tableid} 
                                
                                    onChange={(val) => {setTableid(val.target.value);localStorage.setItem('table_id',val.target.value)}} />
                            </Form.Group>
                        </Row>
                    }
                </Container>

                <div className='horizontal-divider'></div>

                <Container className='Form-1'>
                    <Row className='Item'>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fonts500" >Pesanan</div>
                    <div onClick={() => navigate(`/${router}home`)} className="fonts500" style={{ color: '#3582B9'}}>Tambah Pesanan</div>
                </div>
                    </Row>

                    <div className='horizontal-divider-thick'></div>
                    {/* {Object.keys(groupedItems).map((promoName) => {
                        console.log(promoName)
                    return (
                        <div key={promoName}>
                        {groupedItems[promoName].map((items, index) =>{ 
                            console.log(items.promo_name)
                            return(
                                <div className='Order-List-Item'>
                                <Row xl={2} className='Order'>
                                    <Col xs={8} >
                                        <div className="fonts700">{items['item']} {items['product_name']?items['product_name']:items.name}</div>
                                        {
                                    items.products?
                                    items.products && items.products.map((items, index) => {
                                        return(
                                            <div style={{color:'grey'}} className="fonts300">{items['item']} {items['product_name']?items['product_name']:items.name}</div>

                                        )})
                                    : null
                                    }

                                    </Col>

                                    <Col xs={4} style={{textAlign:'right'}}>
                                        <div className="fonts500">Rp. {formatterMoney(items['total'])}</div>
                                    </Col>
                                </Row>
                                
                                <Row xl={2} className='Order-Note-Edit'>
                                    <Col xs={9}>
                                        {
                                            items['selection'] ?
                                                <Input value={items['note']} onChange={(val) => {
                                                    let newArr =[...dataOrder]
                                                    newArr[index]['note'] = val.target.value
                                                    setDataOrder(newArr)
                                                }}/>  
                                                :
                                                <div className="Menu-desc">{items['note']}</div>
                                        }  
                                    </Col>

                                    <Col xs={3}>
                                        <a className='Edit-Button'
                                            onClick={() => {
                                                let newArr = [...dataOrder]
                                                for(const x of newArr){
                                                    if (x['product_id'] == items['product_id']) {
                                                        if (items['selection']) {
                                                            x['selection'] = false
                                                        }
                                                        else {
                                                            x['selection'] = true
                                                        }
                                                    }
                                                    else {
                                                        x['selection'] = false
                                                    }
                                                }
                                                if (!items['selection']) {
                                                    localStorage.setItem("pesanan", JSON.stringify(encrypt(newArr)))
                                                }
                                                setDataOrder(newArr)
                                            }}>

                                            {
                                                items['selection'] ? 
                                                    <div className='Edit-Icon'> <AiOutlineCheckCircle size={40}/> </div>
                                                    : 
                                                    <div className='Edit-Text'>Edit Note</div>
                                            }
                                        </a>
                                    </Col>
                                </Row>

                                <Row xl={2} className='Counter-Button'>
                                    <Col xs={3}>
                                        <div className='counter'>
                                            <button onClick={()=>onPressMinus(items)}>-</button>
                                            
                                            <span>{items['item']} </span>
                                            
                                            <button onClick={()=>onPressPlus(items)}>+</button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )})}
                        </div>
                    );
                    })
                    } */}
                    {
                        dataOrder !==[] || dataOrder !== undefined?
                        dataOrder && dataOrder.map((items, index) => {
                            // if(items.promo_name){
                            // return (
                            //     <div className='Order-List-Item'>
                            //         {/* <div className="fonts700">{items.promo_name}</div> */}

                            //         <Row xl={2} className='Order'>
                            //             <Col xs={8} >
                            //                 <div className="fonts700">{items['item']} {items['product_name']?items['product_name']:items.name}</div>
                            //                 {
                            //             items.products?
                            //             items.products && items.products.map((items, index) => {
                            //                 return(
                            //                     <div style={{color:'grey'}} className="fonts300">{items['item']} {items['product_name']?items['product_name']:items.name}</div>

                            //                 )})
                            //             : null
                            //             }

                            //             </Col>

                            //             <Col xs={4} style={{textAlign:'right'}}>
                            //                 <div className="fonts500">Rp. {formatterMoney(items['total'])}</div>
                            //             </Col>
                            //         </Row>

                            //     </div>
                            // )}
                            // else{
                                return (
                                    <div className='Order-List-Item'>
                                        <Row xl={2} className='Order'>
                                            <Col xs={8} >
                                                <div className="fonts700">{items['item']} {items['product_name']?items['product_name']:items.name}</div>
                                                {
                                            items.products?
                                            items.products && items.products.map((items, index) => {
                                                return(
                                                    <ul className={boxClassSubMenu.join(' ')} > 
                                                        <li activeClassName='is-active' style={{color:'grey',fontSize:'12px'}}> {items['product_name']?items['product_name']:items.name}</li>
                                                    </ul>
                                                    // <div style={{color:'grey'}} className="fonts300">{items['item']} {items['product_name']?items['product_name']:items.name}</div>
    
                                                )})
                                            : null
                                            }
    
                                            </Col>
    
                                            <Col xs={4} style={{textAlign:'right'}}>
                                                <div className="fonts500">Rp. {formatterMoney(items['total'])}</div>
                                            </Col>
                                        </Row>
                                        
                                        <Row xl={2} className='Order-Note-Edit'>
                                            <Col xs={9}>
                                                {
                                                    items['selection'] ?
                                                        <Input value={items['note']} onChange={(val) => {
                                                            let newArr =[...dataOrder]
                                                            newArr[index]['note'] = val.target.value
                                                            setDataOrder(newArr)
                                                        }}/>  
                                                        :
                                                        <div className="Menu-desc">{items['note']}</div>
                                                }  
                                            </Col>
    
                                            <Col xs={3}>
                                                <a className='Edit-Button'
                                                    onClick={() => {
                                                        let newArr = [...dataOrder]
                                                        for(const x of newArr){
                                                            if (x['product_id'] == items['product_id']) {
                                                                if (items['selection']) {
                                                                    x['selection'] = false
                                                                }
                                                                else {
                                                                    x['selection'] = true
                                                                }
                                                            }
                                                            else {
                                                                x['selection'] = false
                                                            }
                                                        }
                                                        if (!items['selection']) {
                                                            localStorage.setItem("pesanan", JSON.stringify(encrypt(newArr)))
                                                        }
                                                        setDataOrder(newArr)
                                                    }}>
    
                                                    {
                                                        items['selection'] ? 
                                                            <div className='Edit-Icon'> <AiOutlineCheckCircle size={40}/> </div>
                                                            : 
                                                            <div className='Edit-Text'>Edit Note</div>
                                                    }
                                                </a>
                                            </Col>
                                        </Row>
    
                                        <Row xl={2} className='Counter-Button'>
                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <AiFillMinusSquare onClick={()=>onPressMinus(items)} color="#04295E" size={30}/>
                                            {/* <input value={items.item} onChange={(event)=>handleInputChange(event,items)} style={{
                                            fontSize:'20px',
                                            width: '40px',
                                            textAlign: 'center',
                                            border: `1px solid transparent`,
                                            borderRadius: '10px',
                                            }}></input> */}
                                            <span style={{paddingRight:'10px', paddingLeft:'10px', fontWeight: '700',fontSize:'20px'}}>{items['item']}</span>
                                            <AiFillPlusSquare onClick={()=>onPressPlus(items)} color="#04295E" size={30}/>
                                        </div>
                                        </Row>
                                    </div>
                                )
                            // }
                        }):null
                    }
                    {
                        loading &&
                        <Loading/>
                    }
                </Container>

                <div className='horizontal-divider'></div>

                <Container className='Payment-total'>
                    <Row className='Item'>                        
                        <Col xs={12} style={{fontSize:'16px',fontWeight:600}}>
                            Detail Pembayaran
                        </Col>
                    </Row>

                    {
                        detailPembayaran.map((items, index) => (
                            <Row xs={2} className='Form-summary'>
                                <Col xs={6}>
                                    <div className="title" style={{height:'100%',fontSize:'14px', display:'flex', alignItems:'center'}}>{items.label}</div>       
                                </Col>
                                <Col xs={6}>
                                    <div className="Menu-desc" style={{textAlign:'right'}}>Rp. {formatterMoney(items.value)}</div>        
                                </Col>
                            </Row>
                        ))
                    }
                </Container>
                <Gap height={50}/>
                {
                detailPembayaran && dataOrder !==[]?
                <Container fluid className='BottomBarTwoItem2'>
                    <Row className='Container'>
                        <Col xl={3} lg={3} md={3} sm={3} xs={4}>
                            <div className='title'>Total</div>
                            <div>Rp. {formatterMoney(detailPembayaran[detailPembayaran.length-1]?.value)}</div>
                        </Col>

                        <Col xl={9} lg={9} md={9} sm={9} xs={8}>
                        <div className='price-add'>
                            <button loading={loadingPembayaran} onClick={onPembayaran}>
                                Bayar
                            </button>
                        </div>
                        </Col>
                    </Row>
                </Container>
                : null
            }
            </div>

        
        </div>
    )
}