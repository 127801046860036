import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import { FaConciergeBell } from 'react-icons/fa'
import formatterMoney from '../../utils/formatMoney'
import MenungguPembayaran from '../../Assets/MenungguPembayaran'
import Line from '../../Assets/Line'
import PembayaranDiTerima from '../../Assets/PembayaranDiTerima'
import PesananDiProses from '../../Assets/PesananDiProses'
import PesananDiKirim from '../../Assets/PesananDiKirim'
import PesananSelesai from '../../Assets/PesananSelesai'
import { BiMap } from 'react-icons/bi'
import { useToasts } from 'react-toast-notifications'
import { router } from "../../Constants"
import { decrypt, encrypt } from "../../utils/enkripsi"
import { IoFastFoodSharp } from "react-icons/io5"
import Gap from '../../components/Gap'
import { SocketOnline } from '../../utils/socket';
import api from '../../request'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ListLacakPesanan() {
    const { addToast } = useToasts()
    const navigate = useNavigate()
    const location = useLocation()
    const { state } = location
    // console.log(state)
    // console.log(state.data?.data[0]?.delivery_status)
    // console.log(state?.data[0]?.delivery_status)
    const [totalHarga, setTotalHarga] = React.useState([])
    const [gojekStatus, setGojekStatus] = React.useState(0)
    const typeOrder = state?.data?.data[0]
    if (!state) {
        navigate("/" + router + "home/lacak-pesanan")
    }

    let alamat_users = localStorage.getItem('alamatUsers')
    if (alamat_users == null) {
        alamat_users = null
    }
    else {
        alamat_users = JSON.parse(alamat_users)
    }
    let without = localStorage.getItem('without')
    console.log(without)

    function refreshCharges() {
        let local_charges = JSON.parse(localStorage.getItem('local_charges'))
        let dataBranch = JSON.parse(localStorage.getItem('dataBranch'))
        var chargeNotes = [];
        local_charges.forEach(baris => {
            if (baris.branch_id === dataBranch.branchID) {
                if (baris.dine_in_only == 1) {
                    if (false) {
                        chargeNotes.push({
                            id: baris.id,
                            percent_value: baris.percent_value,
                            min_value: baris.min_value,
                            max_value: baris.max_value,
                            value: 0,
                            coa: baris.coa,
                            pb1: baris.pb1
                        });
                    }
                }
                else {
                    chargeNotes.push({
                        id: baris.id,
                        percent_value: baris.percent_value,
                        min_value: baris.min_value,
                        max_value: baris.max_value,
                        value: 0,
                        coa: baris.coa,
                        pb1: baris.pb1
                    });
                }
            }
        })
        return chargeNotes
    }


    function onHitung() {
        const pesanan = state?.data?.data
        const drivery = decrypt(localStorage.getItem('saveDelivery'))
        const value = localStorage.getItem('pickup_delivery')
        const chargeNotes = refreshCharges()
        let data = {}
        let total = 0
        data.charges_value_non_pb1 = 0;
        data.charges_value_pb1 = 0;
        for (var i = 0; i < pesanan.length; i++) {
            let item = pesanan[i]
            let subTotal = (item.qty * item.price)
            total += subTotal
            data.sub_total = total
        }
        chargeNotes.forEach(d => {
            if (d.pb1 == 0) {
                if ((data.sub_total > d.max_value) && (d.max_value > 0)) {
                    d.value = d.percent_value / 100 * d.max_value;
                    data.charges_value_non_pb1 += d.value;

                }
                else {
                    d.value = (d.percent_value / 100 * data.sub_total);
                    data.charges_value_non_pb1 += d.value;
                }
            }
            if (d.pb1 == 1) {
                if ((data.sub_total > d.max_value) && ((d.max_value || 0) > 0)) {
                    d.value = d.max_value || 0;
                    data.charges_value_pb1 += d.value;
                }
                else {
                    d.value = (d.percent_value / 100 * (data.sub_total + data.charges_value_non_pb1 + drivery?.harga));
                    data.charges_value_pb1 += d.value;
                    data.tax = d.percent_value
                }
            }
        })
        // console.log(data.tax)
        let result = [
            {
                label: 'Subtotal',
                value: data.sub_total
            },
            {
                label: 'Tax' + ' ' + data.tax + '%',
                value: data.charges_value_pb1
            },
            {
                label: 'Delivery Fee' + ' ' + (drivery?.km ? drivery?.km : '0 km'),
                value: drivery?.harga ? drivery?.harga : 0
            },
            {
                label: 'Total Pembayaran',
                value: (data.sub_total + data.charges_value_pb1 + (drivery?.harga ? drivery?.harga : 0))
            }
        ]
        // let res = result.filter(items => {
        //     if (value == "pickup") {
        //         return items.label !== 'Delivery Fee' + ' ' + (drivery?.km ? drivery?.km : '0 km')
        //     }
        //     else {
        //         return items
        //     }
        // })
        // localStorage.setItem('onHitung', encrypt(res))
        setTotalHarga(result)
    }
    async function GojekUpdateStatus() {
        const UpdatestatusGojek = await api.gojek.getUpdateOrderStatus({
            "detailData": {
                "storeOrderId": state?.data?.id
            },
        })
        // console.log(UpdatestatusGojek.data.data)
        setGojekStatus(UpdatestatusGojek.data.data)
    }
    useEffect(() => {
        // GojekUpdateStatus()
        // console.log('mengapa ini?', SocketOnline)

        // SocketOnline.on("connect", (resSocket) => {
        //     console.log("Berhasil konek gaes socket id nya ini :" + SocketOnline.id);
        //     console.log("arg resSocket", resSocket, SocketOnline);
        // });
        // SocketOnline.on("connect_error", (err) => {
        //     console.log(`aku error guys ${err}`);
        // });
        // console.log('lewat sini')
        // SocketOnline.on("delivery-status", (data) => {
        //     console.log("data",(data) );
        // });
        // SocketOnline.on("delivery-status",
        //     () => {
        //         alert('cobaa1', SocketOnline)
        //     }
        // )
        if (state) {
            window.addEventListener('popstate', (event) => {
                navigate("/" + router + "home/lacak-pesanan")
            });
            let result = 0
            for (const x of state.data.data) {
                result += x['total']
            }
            onHitung()
        }
    }, [])



    return (
        <div>
            <Navbar urlBack="home/lacak-pesanan" type="list-lacak-pesanan" label="Lacak Pesanan" />
            <div style={{ backgroundColor: '#F0F0F0' }}>
                <div style={{ padding: '10% 0 20px 0', display: 'flex', margin: '0 10px 0 10px', }}>
                    <FaConciergeBell color="#04295E" size={25} />
                    <div style={{ marginLeft: 20 }}>
                        <div className="fonts700" style={{ fontSize: 15 }}>{JSON.parse(localStorage.getItem('dataBranch')).branchName}</div>
                        <div className="fonts400" style={{ fontSize: 12, color: '#7E7E7E' }}>{JSON.parse(localStorage.getItem('dataBranch')).branchAddress}</div>
                    </div>
                </div>
            </div>
            <div style={{ margin: '20px 10px 20px 10px' }}>
                <div style={{ margin: '20px 10px 0 10px', }}>
                    ID : {state?.data?.id}
                </div>
                <div style={{ margin: '20px 10px 0 10px', backgroundColor: '#fff', borderRadius: 5 }}>
                    <div style={{ display: 'grid', justifyContent: 'center', padding: '10px 0 10px 0', margin: '20px 10px 0px 10px' }}>
                        <div className="fonts500">Status Pesanan</div>
                        <div style={{ marginTop: 30, display: 'grid', justifyContent: 'center' }}>
                            <MenungguPembayaran backgroundColor={state?.data['trans_status'] == 0 || state?.data['trans_status'] == 1 ? "#04295E" : "#C0C4CD"} />
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }}>
                            <Line backgroundColor={state?.data['trans_status'] == 0 || state?.data['trans_status'] == 1 ? "#04295E" : "#C0C4CD"} />
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }}>
                            <PembayaranDiTerima backgroundColor={state?.data['trans_status'] == 1 && (typeOrder?.['is_accepted_rejected'] == 0 || typeOrder?.['is_accepted_rejected'] == 1) ? "#04295E" : "#C0C4CD"} />
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }}>
                            <Line backgroundColor={state?.data['trans_status'] == 1 && (typeOrder?.['is_accepted_rejected'] == 1) ? "#04295E" : "#C0C4CD"} />
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }}>
                            <PesananDiProses backgroundColor={state?.data['trans_status'] == 1 && (state?.data['is_prepared'] == 0 || state?.data['is_prepared'] == 1) && typeOrder?.['is_accepted_rejected'] == 1 ? "#04295E" : "#C0C4CD"} />
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }}>
                            <Line backgroundColor={state?.data?.['trans_status'] == 1 && state?.data?.['is_prepared'] == 1 ? "#04295E" : "#C0C4CD"} />
                        </div>
                        {
                            typeOrder?.['pickup_delivery'] == 1 &&
                            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <PesananDiKirim backgroundColor={state?.data?.['trans_status'] == 1 && state?.data?.['is_prepared'] == 1 && typeOrder?.['si_id'] ? "#04295E" : "#C0C4CD"} />
                                <Gap height={5} />
                                {/* <div>{state?.data?.data[0]?.delivery_status}</div> */}
                                {
                                    // state?.gojek &&
                                    state.data?.data[0]?.delivery_status !== null?
                                    <div onClick={() => navigate("/" + router + "status-gojek", {state:state})} className="fonts700" style={{ cursor:'pointer',marginTop: '10%', color: '#04295E', width: '100%', fontSize: 15, justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                        Lihat Status
                                    </div>
                                    :
                                    null
                                }

                                {/* {
                                    state?.gojek && gojekStatus === 1 ?
                                        <div onClick={() => navigate("/" + router + "status-gojek", state)} className="fonts700" style={{ marginTop: '10%', color: '#04295E', width: '100%', fontSize: 15, justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                            Lihat Status
                                        </div>
                                        : null
                                } */}

                                {/* {
                                    state?.data?.['trans_status'] == 1 && state?.data?.['is_prepared'] == 1 && typeOrder?.['si_id'] &&
                                    <a target="_blank" href={state?.gojek?.liveTrackingUrl}>
                                        <span style={{ fontSize: 12}}>Lihat Driver</span>
                   
                                    </a>
                                }  */}
                                {/* <iframe  src={state?.gojek?.liveTrackingUrl} ></iframe> */}
                                {/* <iframe src={'https://track-integration.gojek.com/?id=bbc70cf2'} height="550px" width="350px"></iframe> */}
                                {/* <div onClick={() => navigate("/" +router + "status-gojek",state.data.id)} className="fonts700" style={{ marginTop: 10, color: '#04295E', width: '100%'}}> */}


                                {/* <a target="_blank" href={state?.gojek?.liveTrackingUrl}>
                                        <span style={{ fontSize: 12}}>Lihat Driver</span>
                                    </a> */}

                            </div>
                        }
                        {
                            typeOrder?.['pickup_delivery'] == 0 &&
                            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ width: 60, height: 60, borderRadius: 60, border: state?.data?.['trans_status'] == 1 && state?.data?.['is_prepared'] == 1 && typeOrder?.['si_id'] ? '1px solid #04295E' : '1px solid #C0C4CD', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <IoFastFoodSharp size={30} color={state?.data?.['trans_status'] == 1 && state?.data?.['is_prepared'] == 1 ? "#04295E" : '#C0C4CD'} />
                                </div>
                                <div style={{ fontSize: 12, marginTop: 10, color: state?.data?.['trans_status'] == 1 && state?.data?.['is_prepared'] == 1 && typeOrder?.['si_id'] ? "#04295E" : '#C0C4CD' }}>Pickup</div>
                                {/* <PesananDiKirim backgroundColor={state.data?.['trans_status'] == 1 && state.data?.['is_prepared'] == 1? "#04295E": "#C0C4CD"}/> */}
                            </div>
                        }
                        {/* <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }}>
                            <Line backgroundColor={state.data['trans_status'] == 1 && state.data['is_prepared'] == 1? "#04295E": "#C0C4CD"}/>
                        </div>
                        <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }}>
                            <PesananSelesai backgroundColor={state.data['trans_status'] == 1 && state.data['is_prepared'] == 2? "#04295E": "#C0C4CD"}/>
                        </div> */}
                    </div>
                </div>

                {
                    alamat_users && state?.max &&
                    <div style={{ margin: '20px 10px 0 10px' }}>
                        <div className="fonts500" >Alamat Pengiriman</div>
                        <div style={{ backgroundColor: '#fff', margin: '10px 0px 0px 0px', borderRadius: 5 }}>
                            <div style={{ padding: '10px', display: 'flex', borderBottom: '1px solid #EFEFF2' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <BiMap color="#04295E" style={{ marginTop: 2 }} />
                                </div>
                                <div style={{ marginLeft: '10px', width: '100%' }}>
                                    <div className="fonts700" style={{ fontSize: 14 }}>{alamat_users['type']}</div>
                                    <div className="fonts400" style={{ fontSize: 12, color: '#BFBFBF', marginTop: 5 }}>{alamat_users['alamat']}</div>
                                    <div className="fonts400" style={{ fontSize: 13, marginTop: 10 }}>Detail Alamat</div>
                                    <div className="fonts400" style={{ fontSize: 12, marginTop: 5, color: '#BFBFBF' }}>{alamat_users['detailAlamat']}</div>
                                    <div className="fonts400" style={{ fontSize: 13, marginTop: 10 }}>Detail Penerima</div>
                                    <div className="fonts400" style={{ fontSize: 12, marginTop: 5, color: '#BFBFBF' }}>{state?.data.data[0]['name']}</div>
                                    <div className="fonts400" style={{ fontSize: 12, marginTop: 5, color: '#BFBFBF' }}>{state?.data.data[0]['delivery_receiver_phone']}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div style={{ margin: '20px 10px 0 10px' }}>
                    <div className="fonts500" >Pesanan</div>
                    <div style={{ margin: '20px 0px 0 0px', backgroundColor: '#fff', borderRadius: 5 }}>
                        {
                            state?.data.data.map((items, index) => {
                                return (
                                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 0', margin: '0px 10px 0px 10px', borderBottom: state?.data.data.length !== index + 1 ? '1px solid #BFBFBF' : '0px solid #BFBFBF' }}>
                                        <div>
                                            <div className="fonts700">{items['qty']}x {items['name']}</div>
                                            <div className="fonts500" style={{ fontSize: 14, color: '#BFBFBF' }}>{items['detail_note']}</div>
                                        </div>
                                        <div>
                                            <div className="fonts700">{formatterMoney(items['price'] * items['qty'])}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div style={{ margin: '20px 10px 20px 10px' }}>
                    <div className="fonts500" >Detail Pembayaran</div>
                    <div style={{ margin: '20px 0px 0 0px', backgroundColor: '#fff', borderRadius: 5 }}>
                        {
                            state?.data?.charge?.map((items, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: items?.label == "Total Pembayaran" ? '10px 0' : '2px 0', margin: '0px 10px', borderTop: items?.label == "Total Pembayaran" ? '1px solid #BFBFBF' : '0px' }}>
                                    <div className={items?.label == "Total Pembayaran" ? 'fonts700' : 'fonts400'}>{items.label}</div>
                                    <div className="fonts400" style={{ textAlign: 'right' }}>{formatterMoney(items.value)}</div>
                                </div>
                            ))
                        }
                    </div>
                    <Gap height={20} />
                </div>
            </div>
        </div>
    )
}