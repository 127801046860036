import { createStore, applyMiddleware, combineReducers, compose } from "redux"
import thunk from "redux-thunk"


import { gojekReducer } from "./reducers/gojekReducer"
import { chargesReducer } from "./reducers/chargesReducer"
import { xenditReducer } from "./reducers/xenditActions"
import { prepareReducer } from "./reducers/prepareReducer"
import { bayarReducer } from "./reducers/bayarReducers"

const initialState = {};


const appReducer = combineReducers({
    gojekReducer: gojekReducer,
    chargesReducer: chargesReducer,
    xenditReducer: xenditReducer,
    dataPrepare: prepareReducer,
    bayarReducer: bayarReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
      state = undefined;
    }
    return appReducer(state, action);
}


const composerEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
    rootReducer,
    initialState,
    composerEnhancer(applyMiddleware(thunk)),
  );

export default store;