import { SET_BAYAR } from "../constants/pembayaranConstans"

let initialState = { 
    dayaBayar : {}
};


export const bayarReducer = (state = initialState , action) => {
    if (action.type ===  SET_BAYAR ) {
        return {
            ...state,
            dayaBayar: action.value
        }
    }
    return state
}