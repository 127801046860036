import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import QRCode from "qrcode";
import FETCH_API from "../../config/fetch_api";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import { formatDate } from "../../utils/formatDate";
import { useToasts } from "react-toast-notifications";
import { send_email, proses_data } from "../../config/config";
import Gap from "../../components/Gap";
import { router } from "../../Constants";
import { encrypt, decrypt } from "../../utils/enkripsi";
import { SocketOnline } from "../../utils/socket";
import api from "../../request";
import { useLocation } from "react-router-dom";

function StatusGojek() {
  const location = useLocation()
  // console.log((JSON.parse(location?.state?.data?.data[0]?.delivery_status)))
  const state = location?.state;
  const bookingID = location?.state?.gojek?.orderNo;
  const status = location?.state?.gojek?.status;
  const driverName = location?.state?.gojek?.driverName;
  const driverPhone = location?.state?.gojek?.driverPhone;
  const livetrackingurl = location?.state?.gojek?.liveTrackingUrl;
  const canceldesc = location?.state?.gojek?.cancelDescription;
  // const databranch = localStorage.getItem('local_branch')
  // console.log(JSON.parse(databranch))
  console.log(location.state);

  console.log(
    bookingID,
    status,
    driverName,
    driverPhone,
    livetrackingurl,
    canceldesc
  );
  const id = location?.state;
  const [dataStatus, setDataStatus] = React.useState();
  const [livetracking, setLivetracking] = React.useState("");
  const alamat_users = JSON.parse(localStorage.getItem("alamatUsers"));
  const databranch = JSON.parse(localStorage.getItem("dataBranch"));
  const longlat = JSON.parse(localStorage.getItem("longlat"));
  const userServer = JSON.parse(localStorage.getItem("userServer"));
  const orderDate = JSON.parse(localStorage.getItem("orderDate"));
  async function GojekOrder() {
    try {
      const gojekorder = await api.gojek.order({
        detailData: {
          OriginName: "Admin",
          OriginNote: "",
          OriginContactName: databranch.branchName + "-" + userServer,
          OriginContactPhone: databranch.phone1,
          OriginLatLong: `${databranch.latitude}, ${databranch.longitude}`,
          OriginAddress: databranch.branchAddress,
          destinationName: orderDate.nama,
          destinationNote: "",
          destinationContactName: orderDate.nama,
          destinationContactPhone: orderDate.nomerHp,
          destinationLatLong: `${alamat_users.lat}, ${alamat_users.lng}`,
          destinationAddress: `Desa: ${alamat_users.dataWithout.desa}, Kec: ${alamat_users.dataWithout.kecamatan}, Kab: ${alamat_users.dataWithout.kabupaten} Prov: ${alamat_users.dataWithout.provinsi}`,
          item: location.state.data.data[0].name,
          storeOrderId: location.state.data.id,
          // "storeOrderId" : items.id +'-'+userServer,
          userId: "admin",
          product_description: "",
          product_price: location.state.data.subTotal.toString(),
        },
      });
      console.log(gojekorder);
      // setLivetracking(statusGojek.data.hasil.liveTrackingUrl)
    } catch (error) {
      console.log(error)
    }
    
  }

  async function GojekUpdateStatus() {
    const UpdatestatusGojek = await api.gojek.getUpdateOrderStatus({
      detailData: {
        storeOrderId: location?.state?.data?.id,
      },
    });
    // console.log('UpdatestatusGojek',UpdatestatusGojek)
  }
  useEffect(() => {
    // GojekStatus()
    // GojekUpdateStatus()

    // console.log('mengapa ini?', SocketOnline)

    SocketOnline.on("connect", (resSocket) => {
      console.log("Berhasil konek gaes socket id nya ini :" + SocketOnline.id);
      // console.log("arg resSocket", resSocket, SocketOnline);
    });
    SocketOnline.on("connect_error", (err) => {
      console.log(`aku error guys ${err}`);
    });
    // console.log('lewat sini')
    // SocketOnline.on("delivery-status", (resSocket) => {
    //     console.log("Berhasil konek gaes socket id nya ini :" + SocketOnline.id);
    //     console.log("arg resSocket", resSocket, SocketOnline);
    // });
    SocketOnline.on("delivery-status", (data) => {
      console.log("cobaa1", data);
      setDataStatus(data);
    });

    // console.log(dataStatus)
  }, [dataStatus]);
  return (
    <div>
      <Navbar urlBack={"home/lacak-pesanan"} type="PaymentNobu" label="Status Gojek" />

      {/* {
                // state?.gojek &&
                <div style={{ paddingTop: '20%', paddingLeft: '10%', paddingBottom: '10%' }}>
                    <div >
                        Booking ID : {state?.gojek?.storeOrderId === null ? '-' : state?.gojek?.storeOrderId}
                    </div>
                    <div>
                        Status : {state?.gojek?.status === null ? '-' : state?.gojek?.status}

                    </div>
                    <div>
                        Driver Name : {state?.gojek?.driverName === null ? '-' : state?.gojek?.driverName}
                    </div>
                    <div>
                        Driver Phone : {state?.gojek?.driverPhone === null ? '-' : state?.gojek?.driverPhone}

                    </div>
                    <div>
                        Cancellation Description : {state?.gojek?.cancelDescription === null ? '-' : state?.gojek?.cancelDescription}

                    </div>
                    <div>
                        Reiceiver Name : {state?.gojek?.receiverName === null ? '-' : state?.gojek?.receiverName}
                    </div>
                </div>
            } */}
      {dataStatus ? (
        <div>
          <div
            style={{
              paddingTop: "20%",
              paddingLeft: "10%",
              paddingBottom: "10%",
              paddingRight:'10%'
            }}
          >
            <div>Booking ID : {dataStatus.booking_id}</div>
            <div>
              Status :{" "}
              {dataStatus.status === "confirmed"
                ? "Finding Driver"
                : dataStatus.status === "allocated"
                ? "Driver is found"
                : dataStatus.status === "out_for_pickup"
                ? "Driver is on their way to pick-up location"
                : dataStatus.status === "picked"
                ? "Item is successfully picked up by the driver"
                : dataStatus.status === "out_for_delivery"
                ? "Driver is enroute to deliver the item"
                : dataStatus.status === "cancelled"
                ? "Booking is cancelled"
                : dataStatus.status === "delivered"
                ? "Item is successfully delivered"
                : dataStatus.status === "rejected"
                ? "Driver returns the item to sender"
                : dataStatus.status === "no_driver"
                ? "Driver Not Found"
                : dataStatus.status === "on_hold"
                ? "Pending Delivery"
                : dataStatus.status}
            </div>
            <div>Driver Name : {dataStatus.driver_name}</div>
            <div>Driver Phone : {dataStatus.driver_phone}</div>
            <div>
              Cancellation Description : {dataStatus.cancellation_reason}
            </div>
            <div>Reiceiver Name : {dataStatus.receiver_name}</div>
          </div>
            <div
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "10%",
              }}
            >
              <Button
                onClick={() => {
                  GojekOrder();
                }}
                color="#fff"
                backgroundColor="#5451D6"
              >
                Booking
              </Button>
            </div>
          )
          <iframe
            src={dataStatus.live_tracking_url}
            height={"700px"}
            width={"100%"}
          ></iframe>
        </div>
      ) : (
        location?.state?.gojek && (
          <div>
            <div
              style={{
                paddingTop: "20%",
                paddingLeft: "10%",
                paddingBottom: "10%",
              }}
            >
              <div>
                Booking ID :{" "}
                {(location?.state?.gojek).orderNo === null
                  ? "-"
                  : (location?.state?.gojek).orderNo}
              </div>
              <div>
                Status :{" "}
                {(location?.state?.gojek).status === null
                  ? "-"
                  : (location?.state?.gojek).status}
              </div>
              <div>
                Driver Name :{" "}
                {(location?.state?.gojek).driverName === null
                  ? "-"
                  : (location?.state?.gojek).driverName}
              </div>
              <div>
                Driver Phone :{" "}
                {(location?.state?.gojek).driverPhone === null
                  ? "-"
                  : (location?.state?.gojek).driverPhone}
              </div>
              <div>
                Cancellation Description :{" "}
                {(location?.state?.gojek).cancelDescription === null
                  ? "-"
                  : (location?.state?.gojek).cancelDescription}
              </div>
              <div>
                Reiceiver Name :{" "}
                {(location?.state?.gojek).receiverName === null
                  ? "-"
                  : (location?.state?.gojek).receiverName}
              </div>
            </div>
              <div
                style={{
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  paddingBottom: "10%",
                }}
              >
                <Button
                  onClick={() => {
                    GojekOrder();
                  }}
                  color="#fff"
                  backgroundColor="#5451D6"
                >
                  Booking
                </Button>
              </div>
            )

            <iframe
              src={(location?.state?.gojek).liveTrackingUrl}
              height={"700px"}
              width={"100%"}
            ></iframe>
          </div>
        )
      )}

      {/* {
                
                <div>
                <div style={{ paddingTop: '20%', paddingLeft: '10%', paddingBottom: '10%',borderRadius: 5, boxShadow: '0px 1px 5px -3px black', }}>
                    <div >
                        Booking ID : {}
                    </div>
                    <div>
                        Status : {}
                    </div>
                    <div>
                        Driver Name : {}
                    </div>
                    <div>
                        Driver Phone : {}
                    </div>
                    <div>
                        Cancellation Description : {}
                    </div>
                    <div>
                        Reiceiver Name : {}
                    </div>
                </div>
               <iframe src={"https://track-integration.gojek.com/?id=87037bf"} height={"700px"} width={'100%'}></iframe>
                </div>
            } */}
      {/* <iframe src={livetracking} height={"700px"} width={'100%'}></iframe> */}
    </div>
  );
}

export default StatusGojek;
