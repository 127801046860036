import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import { AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FETCH_API from "../../config/fetch_api";
import uuid from "react-uuid";
import { useToasts } from "react-toast-notifications";
import LoadingFull from "../../components/LoadingFull";
import { dataPay } from "../../utils/pay";
import { encrypt, decrypt } from "../../utils/enkripsi";
import { router, baseUrl, mode } from "../../Constants";
import { formatDate, formatMinutes } from "../../utils/formatDate";
import { refreshCharges, onHitung } from "../../utils/onHitung";
import { useDispatch } from "react-redux";
import { onBayar } from "../../redux/actions/actionsPembayaran";
import api from "../../request";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../Pages/MetodePembayaran/MetodePembayaran.css";

import QRIS from "../../Assets/Icon/QRIS.png";
import ShopeePay from "../../Assets/Icon/Shopee.png";
import OVO from "../../Assets/Icon/OVO.png";
import StarPay from "../../Assets/Icon/StarPay.png";
import StarPayCoin from "../../Assets/Icon/StarPayCoin.png";
import StarPayPoint from "../../Assets/Icon/StarPayPoint.png";
import formatterMoney from "../../utils/formatMoney";

export default function MetodePembayaran() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation()
  const {state} = location
  const detailData = JSON.parse(localStorage.getItem("coa_transaksi"));
  const [loading, setLoading] = React.useState(false);
  const users = JSON.parse(localStorage.getItem("login"));
  const branch = JSON.parse(localStorage.getItem("dataBranch"));
  const pesanan = decrypt(JSON.parse(localStorage.getItem("pesanan")));
  const orderDate = JSON.parse(localStorage.getItem("orderDate"));
  const pickup_delivery = localStorage.getItem("pickup_delivery");
  const alamat = JSON.parse(localStorage.getItem("alamatUsers"));
  const coaXendit = JSON.parse(localStorage.getItem("coaweb"));
  const loyalty = (localStorage.getItem("loyalty"));
  const table_id = (localStorage.getItem("table_id"));
  console.log(table_id)
  const detailpembayaran = JSON.parse(localStorage.getItem("detailpembayaran"));
  const [poinProduk,setPoinProduk] = React.useState(0)
  const [coinProduk,setCoinProduk] = React.useState(0)
  const userServer = localStorage.getItem("userServer");
  // let xendit = coaXendit?.data?.[0]
  let without = "";
  if (pickup_delivery == "delivery") {
    without = alamat.dataWithout;
  }
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [idData, setIdData] = useState('');

  // const [totalBelanja, setTotalBelanja] = useState(
  //   // state?.data[0]?.payment_others_value
  //   detailpembayaran[3] ? detailpembayaran[3].value : detailpembayaran[2].value
  // );
  const totalBelanja = (detailpembayaran[detailpembayaran.length-1]?.value)
  const [pay, setPay] = React.useState([]);
  async function edc() {
    try {
      const res = await api.apiPay.edc({
        userId: "Admin",
        userServer: userServer,
      });
      setPay(res.data.data);
      return
    } catch (error) {
      console.log(error)
    }
    
    // const bodyres = {
    //   branch_id: location.state[1][0].branch_id,
    //   from_origin: "*",
    //   search1: "",
    //   // "search1": input ? input : '',
    //   userId: "Admin",
    //   userServer: userServer,
    // };

    // console.log(bodyres, configres);
    // const responres = await axios.post(url + "edc-v2/getAll", bodyres, configres);
    // // console.log(url+"edc/getAll")
    // console.log(responres.data.data);
    // setDataedc(responres.data.data);
  }
  const dataPay = [
    {
      name: "URUZ-XEND",
      icon: QRIS,
    },
    {
      name: "OVO",
      icon: OVO,
    },
    {
      name: "Shopeepay",
      icon: ShopeePay,
    },
    {
      name: "Nobu",
      icon: ShopeePay,
    },
  ];

  const royalti = [
    // {
    //   name: "StarPay",
    //   image: StarPay,
    //   saldo: 15000,
    // },
    {
      name: "StarPay Coin",
      image: StarPayCoin,
      saldo: loyalty?.coin,
    },
    {
      name: "StarPay Point",
      image: StarPayPoint,
      saldo: loyalty?.point,
    },
  ];
  const [switchStateStarPay, setSwitchStateStarPay] = useState(false);
  const [switchStateStarPayCoin, setSwitchStateStarPayCoin] = useState(false);
  const [switchStateStarPayPoint, setSwitchStateStarPayPoin] = useState(false);

  const handlePaymentSelection = (paymentOption) => {
    setSelectedPayment(paymentOption);
  };
  const [totalsisa, setTotalsisa] = React.useState(0);
  async function sisapembayaran() {
    var sisa = 0;
    if (
      switchStateStarPay === true &&
      switchStateStarPayCoin === true &&
      switchStateStarPayPoint === true
    ) {
      sisa =
        totalBelanja - royalti[0].saldo - royalti[1].saldo;
    } else if (
      switchStateStarPay === true &&
      switchStateStarPayCoin === false &&
      switchStateStarPayPoint === false
    ) {
      sisa = totalBelanja ;
    } else if (
      switchStateStarPay === false &&
      switchStateStarPayCoin === true &&
      switchStateStarPayPoint === false
    ) {
      sisa = totalBelanja - royalti[0].saldo;
    } else if (
      switchStateStarPay === false &&
      switchStateStarPayCoin === false &&
      switchStateStarPayPoint === true
    ) {
      sisa = totalBelanja - royalti[1].saldo;
    } else if (
      switchStateStarPay === true &&
      switchStateStarPayCoin === true &&
      switchStateStarPayPoint === false
    ) {
      sisa = totalBelanja - royalti[0].saldo;
    } else if (
      switchStateStarPay === true &&
      switchStateStarPayCoin === false &&
      switchStateStarPayPoint === true
    ) {
      sisa = totalBelanja  - royalti[1].saldo;
    } else if (
      switchStateStarPay === false &&
      switchStateStarPayCoin === true &&
      switchStateStarPayPoint === true
    ) {
      sisa = totalBelanja - royalti[0].saldo - royalti[1].saldo;
    } else {
      sisa = totalBelanja;
    }

    setTotalsisa(sisa);
  }
  React.useEffect(async () => {
    if (!pesanan && !state) {
      navigate(`/${router}home`);
    }
    window.addEventListener("popstate", (event) => {
      navigate(
        pesanan ? "/" + router + "detail-order" : "/" + router + "home"
      );
    });
    edc();
    sisapembayaran();
  }, [
    selectedPayment,
    switchStateStarPay,
    switchStateStarPayCoin,
    switchStateStarPayPoint,
  ]);

  function detailTrens(items,index) {
    console.log(items)
    return {
      product_id: items["product_id"],
      warehouse_id: detailData["default_warehouse_id"],
      coa_inv: branch.coa_inv,
      coa_sales: branch.coa_sales_inv,
      name: items["product_name"] ? items["product_name"] : items["name"],
      qty: items["item"] ? items["item"] : items["qty"],
      unit_id: "PCS",
      qty_unit: 1,
      unit_id_small: "PCS",
      curr: "IDR",
      rate: 1,
      price: items["price"] && items.discount? items["price"] - (items["price"]*items.discount/100) : items["price"] && !items.discount? items["price"]:items["total"]?items["total"]:0,
      detail_disc1: 0,
      detail_disc2: 0,
      detail_disc_value: 0,
      detail_note:
      items["note"] !== undefined ? items["note"] : items["detail_note"],
      total: items["total"] ? items["total"] : items["price"] * items["qty"],
      indent: 0,
      picpath1_thumb: items["picpath1_thumb"],
      ordered_by: orderDate["nama"],
      ordered_at: orderDate.date + " " + orderDate.time,
      accepted_rejected_by: "",
      accepted_rejected_note: "Chrome",
      accepted_rejected_at: "null",
      prepared_by: "",
      prepared_at: "",
      kitchen_type_id: "",
      // promo_name: items["name"] ? items["name"] : items["product_name"],
      promo_name: items.promo_name ? items.promo_name : items["name"] ? items["name"] : '',
      promo_qty:  items["item"] ? items["item"] : items["qty"],
      promo_paket: items.promo_name ? 1 : items.promopaket == 0 ? 1 : items.promopaket > 0 ? 2 : 0,
      dtp_bkp: 1,
      // "is_accepted_rejected": 1,
      // "expired": "0000-00-00",
      // "num_paket": -1,
      // "category_id": items.id,
    };
  }

  async function onPembayaran(item) {
    setLoading(true)
    if(item){
    let typeDelivery = JSON.parse(localStorage.getItem("typePembayaran"));
    const drivery = decrypt(localStorage.getItem("saveDelivery"));

    // console.log('item',item)

    let nomerHp = 0,
      local_charges,
      detailTrans = [],
      total = 0,
      result,
      namaProduk = "";

    let grand_total = 0;
    if (users) {
      nomerHp = users.telp;
    } else {
      nomerHp = orderDate.nomerHp;
    }
    const dataDetailData = {
      id: "",
      code: "",
      trans_date: formatDate(new Date()) + " " + formatMinutes(new Date()),
      branch_id: branch.id,
      branch_name: branch.name,
      branch_address: branch.address,
      branch_tel1: branch.phone1,
      customer_id: "URUZ-DELIVERY",
      marketing_id: "admin",
      marketing_name: "admin",
      table_id: "",
      jumlah_tamu: 2,
      default_warehouse_id: detailData["default_warehouse_id"],
      default_warehouse_name: detailData["default_warehouse_name"],
      note:  table_id ? table_id : "",
      harga_a_note: "0",
      harga_a_note_show: "",
      estimation_date: formatDate(new Date()) + " " + formatMinutes(new Date()),
      pickup_delivery: pickup_delivery == "pickup" ? 0 : 1,
      pickup_delivery_show: "",
      branch_id_pickup: branch.id,
      branch_name_pickup: branch.name,
      branch_address_pickup: branch.address,
      branch_tel1_pickup: branch.phone1,
      branch_id_make: "",
      branch_id_make_name: "",
      delivery_receiver_name: `${orderDate.nama}`,
      delivery_receiver_phone: `${nomerHp}`,
      discounts_note: [],
      discounts_value: 0,
      top: 0,
      kembali: 0,
      payment_type_show: "",
      payment_cash_value: 0,
      payment_cash_coa: detailData["coa_cash"],
      payment_cash_coa_name: detailData["coa_cash_name"],
      payment_others_edc: item,
      payment_others_coa: coaXendit?.["coa"],
      payment_others_note: "",
      payment_dc1_edc: "",
      payment_dc1_coa: "",
      payment_dc1_coa_name: "",
      payment_dc1_value: 0,
      payment_dc1_note: "",
      payment_dc2_edc: "",
      payment_dc2_coa: "",
      payment_dc2_coa_name: "",
      payment_dc2_value: 0,
      payment_dc2_note: "",
      payment_cc1_edc: "",
      payment_cc1_coa: "",
      payment_cc1_coa_name: "",
      payment_cc1_value: 0,
      payment_cc1_note: "",
      payment_cc2_edc: "",
      payment_cc2_coa: "",
      payment_cc2_coa_name: "",
      payment_cc2_value: 0,
      payment_cc2_note: "",
      dp_so: 0,
      coa_dp_so: detailData["coa_dp_so"],
      coa_dp_so_name: detailData["coa_dp_so_name"],
      coa_ar_customer: "",
      coa_ar_customer_name: "",
      coa_sales: "",
      coa_sales_name: "",
      trans_status: 0,
      trans_status_show: "",
      realisation_status: 0,
      realisation_status_show: "",
      created_by: "",
      created_by_name: "",
      created_date: "",
      updated_by: "-",
      updated_by_name: "",
      updated_date: "",
      processed_by: "-",
      processed_by_name: "",
      processed_date: "",
      terminated_by: "-",
      terminated_by_name: "",
      terminated_note: "",
      terminated_date: "",
      void_by: "-",
      void_by_name: "",
      void_note: "",
      void_date: "",
      is_accepted_rejected: 0,
      is_accepted_rejected_show: "",
      accepted_rejected_by: "",
      accepted_rejected_by_name: "",
      accepted_rejected_note: "",
      is_prepared: 0,
      is_prepared_show: "",
      prepared_by: "",
      prepared_by_name: "",
      prepared_note: "",
      is_billed: 0,
      is_billed_show: "",
      billed_date: "",
      billed_by: "",
      billed_by_name: "",
      picpath: "",
      print_x_time: 0,
      si_id: "",
      so_id: "",
      is_checked: 0,
      is_checked_show: "",
      checked_by: "",
      checked_by_name: "",
      checked_note: "",
      posted_inv_status: 0,
      posted_inv_note: "",
      posted_inv_date: "",
      posted_acc_status: 0,
      posted_acc_note: "",
      posted_acc_date: "",
      cashlez_link: "",
      cashlez_qr: "",
      loyalty:'[]',
      // total_coin_earned:coinProduk,
      // total_point_earned:poinProduk,
      total_coin_earned:0,
      total_point_earned:0,
      delivery_receiver_id:`${orderDate.nama}`
    };
    // console.log(dataDetailData)
    const dataOrder = decrypt(JSON.parse(localStorage.getItem("pesanan")));
    if (state) {
      for (const si of state?.data) {
        namaProduk += si.product_name + " ";
      }
    } else {
      for (const si of dataOrder) {
        namaProduk += si.product_name + " ";
      }
    }

    let delivery_receiver_address = {
      nama: orderDate.nama,
      nomerHp: nomerHp,
      email: orderDate.email,
      alamatSebagai: without ? without.alamatSebagai : "",
      desa: without ? without.desa : "",
      kecamatan: without ? without.kecamatan : "",
      kabupaten: without ? without.kabupaten : "",
      provinsi: without ? without.provinsi : "",
      kodePos: without ? without.kodePos : "",
      item: namaProduk,
      typeOrder: typeDelivery?.label,
      latitude: without.latitude,
      longitude: without.longitude,
      typeDriver: drivery?.id == "gojek" ? "gojek" : "inhouse delivery",
    };
    var diskon = []
    if (state) {
      dataDetailData["id"] = state?.id;
      for (const items of state?.data) {
        detailTrans.push(detailTrens(items));
      }
      const s = onHitung(state?.data);
      if(switchStateStarPayCoin === true && switchStateStarPayPoint === true){
        diskon.push({
            "id": "coin", // ini di hardcode
            "percent_value": 0,
            "min_value": 0,
            "max_value": 0,
            "value": (s.data.grand_total_net - loyalty?.point - loyalty?.coin) > 0 ? loyalty?.coin : s.data.grand_total_net,
        }, {
            "id": "point", // ini di hardcode
            "percent_value": 0,
            "min_value": 0,
            "max_value": 0,
            "value": (s.data.grand_total_net - loyalty?.point - loyalty?.coin) > 0 ? loyalty?.point : s.data.grand_total_net,
        })

    }
    else if(switchStateStarPayCoin === true && switchStateStarPayPoint === false){
        diskon.push({
            "id": "coin", // ini di hardcode
            "percent_value": 0,
            "min_value": 0,
            "max_value": 0,
            "value": (s.data.grand_total_net - loyalty?.coin) > 0 ? loyalty?.coin : s.data.grand_total_net ,
        })
    }
    else if(switchStateStarPayCoin === false && switchStateStarPayPoint === true){
        diskon.push({
            "id": "point", // ini di hardcode
            "percent_value": 0,
            "min_value": 0,
            "max_value": 0,
            "value":(s.data.grand_total_net - loyalty?.point ) > 0 ? loyalty?.point : s.data.grand_total_net ,
        })
    }
      dataDetailData.loyalty = JSON.stringify(diskon) 
      dataDetailData["grand_total_nett"] = s.data.grand_total_net;
      // dataDetailData['grand_total_nett'] = s.data.sub_total
      dataDetailData["total"] = s.data.sub_total;
      dataDetailData["sub_total"] = s.data.sub_total;
      dataDetailData["grand_total"] = s.data.grand_total;
      dataDetailData["charges_value"] = s.data.charges_value;
      dataDetailData["charges_value_pb1"] = s.data.charges_value_pb1;
      dataDetailData["charges_value_non_pb1"] = s.data.charges_value_non_pb1;
      dataDetailData["charges_note"] = s.chargeNotes;
      dataDetailData["payment_others_name"] = item;
      dataDetailData["payment_others_note"] = item.name;
      dataDetailData["payment_others_value"] = s.data.grand_total_net;
      dataDetailData["total_bayar"] = s.data.grand_total_net;
      dataDetailData["_onUpdate"] = s.data.grand_total_net;
      delivery_receiver_address["grand_total_nett"] = s.data.grand_total_net;
      delivery_receiver_address["delivery"] = s.data.delivery;
    } else {
      const pesanan = dataOrder.map((item) => ({
        qty: item.item,
        price: item.total ? item.total : item.price? item.price : item.harga,
      }));
      const filteredArray = dataOrder.filter(item => !item.product_id );     
      const filteredArray2 = dataOrder.filter(item => item.product_id );      
 
      let produkpaket = []
      for(let a=0;a<filteredArray.length;a++){
        for(let b=0;b<filteredArray[a].products.length;b++){
          const product = filteredArray[a].products[b];
          const promoPrice = filteredArray[0].harga 
          const promopaket = b
          produkpaket.push({ ...product, promoPrice,promopaket }); // Include the promoPrice property in the pushed object
        }
      }
      const a = produkpaket.concat(filteredArray2)
      for (let index = 0; index < a.length; index++) {
        const item = a[index];
        const result = detailTrens(item, index); // Call the function with item and index
        detailTrans.push(result);
      }
      const s = onHitung(pesanan);
      if(switchStateStarPayCoin === true && switchStateStarPayPoint === true){
        diskon.push({
            "id": "coin", // ini di hardcode
            "percent_value": 0,
            "min_value": 0,
            "max_value": 0,
            "value": (s.data.grand_total_net - loyalty?.point - loyalty?.coin) > 0 ? loyalty?.coin : s.data.grand_total_net,
        }, {
            "id": "point", // ini di hardcode
            "percent_value": 0,
            "min_value": 0,
            "max_value": 0,
            "value": (s.data.grand_total_net - loyalty?.point - loyalty?.coin) > 0 ? loyalty?.point : s.data.grand_total_net,
        })

    }
    else if(switchStateStarPayCoin === true && switchStateStarPayPoint === false){
        diskon.push({
            "id": "coin", // ini di hardcode
            "percent_value": 0,
            "min_value": 0,
            "max_value": 0,
            "value": (s.data.grand_total_net - loyalty?.coin) > 0 ? loyalty?.coin : s.data.grand_total_net ,
        })
    }
    else if(switchStateStarPayCoin === false && switchStateStarPayPoint === true){
        diskon.push({
            "id": "point", // ini di hardcode
            "percent_value": 0,
            "min_value": 0,
            "max_value": 0,
            "value":(s.data.grand_total_net - loyalty?.point ) > 0 ? loyalty?.point : s.data.grand_total_net ,
        })
    }
      dataDetailData.loyalty = JSON.stringify(diskon) 
      dataDetailData["id"] = idData !== ''? idData : uuid();
      dataDetailData["grand_total_nett"] = s.data.grand_total_net;
      // dataDetailData['grand_total_nett'] = s.data.sub_total
      dataDetailData["total"] = s.data.sub_total;
      dataDetailData["sub_total"] = s.data.sub_total;
      dataDetailData["grand_total"] = s.data.grand_total;
      dataDetailData["charges_value"] = s.data.charges_value;
      dataDetailData["charges_value_pb1"] = s.data.charges_value_pb1;
      dataDetailData["charges_value_non_pb1"] = s.data.charges_value_non_pb1;
      dataDetailData["charges_note"] = s.chargeNotes;
      dataDetailData["payment_others_name"] = item;
      dataDetailData["payment_others_note"] = item.name;
      dataDetailData["payment_others_value"] = s.data.grand_total_net;
      dataDetailData["total_bayar"] = s.data.grand_total_net;
      dataDetailData["_onUpdate"] = s.data.grand_total_net;
      delivery_receiver_address["grand_total_nett"] = s.data.grand_total_net;
      delivery_receiver_address["delivery"] = s.data.delivery;
    }
    // console.log(dataDetailData)
    delivery_receiver_address["detailTrans"] = detailTrans;
    delivery_receiver_address["detailData"] = dataDetailData;

    dataDetailData["payment_type"] = 3;
    dataDetailData["delivery_receiver_address"] = JSON.stringify(delivery_receiver_address)
      // pickup_delivery == "delivery"
      //   ? JSON.stringify(delivery_receiver_address)
      //   : "";
    try {
      // if (nomerHp.length >= 11 && nomerHp.length <= 15) {
        setLoading(true);
        if (!state) {
          const res = await api.apiPay.save({
            userId: "-",
            transMode: idData !== ''? 'edit' :"new",
            detailData: JSON.stringify(dataDetailData),
            detailTrans: JSON.stringify(detailTrans),
            discountsNote: "[]" ,
            chargesNote:  JSON.stringify(dataDetailData.charges_note)
              // pickup_delivery == "delivery"
              //   ? JSON.stringify(dataDetailData.charges_note)
              //   : "[]",
          });

          result = res.data;
        } else {
          result = state;
        }
      // } 
      // else {
      //   addToast("Nomor telepon yang anda masukkan tidak valid.", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
      //   setIdData(dataDetailData.id)
      // }
      try {
        if (result) {

          localStorage.setItem(
            "detailDataOnBayar",
            encrypt({
              dataDetailData,
              detailTrans,
              local_charges: dataDetailData.charges_note,
            })
          );
          localStorage.setItem("idpesanan", JSON.stringify(dataDetailData.id));
          const enkrip = JSON.stringify({
            userId: "-",
            transMode: "edit",
            detailData: (dataDetailData),
            detailTrans: JSON.stringify(detailTrans),
            discountsNote: JSON.stringify(diskon),
            chargesNote: JSON.stringify(local_charges),
            from_origin: "*",
            userServer: userServer,
            transCode: "so",
            transId: dataDetailData.id,
          });

          const dataenkrip = await FETCH_API("encryption/encrypt", {
            string: enkrip,
          });

          if (item.toLowerCase() == "uruz-xend") {
            try {
              const dataQR = await api.xendit.getQR({
                transId: dataDetailData.id,
                total: parseInt(totalsisa),
                data: dataenkrip.data,
                // branch :{
                //   ...branch,
                //   branchID:branch.id,
                //   branchAddress: branch.address
                // }
              });
              setLoading(false);
              if (dataQR.data["status"] == "ACTIVE" || dataQR['status'] == "ACTIVE") {
                localStorage.removeItem("pesanan");
                navigate(`/${router}metode-pembayaran-qris/:id`,{state:{
                  params: dataQR.data,
                  detailTrans: detailTrans,
                  detailData: dataDetailData,

                }});
                setLoading(false)
              }
              else{
              }
            } catch (error) {
              console.log(error)
              addToast(error.response.data.message, {
                appearance: "error",
                autoDismiss: true,
              });
              setLoading(false)
              setIdData(dataDetailData.id)

            }
           
          } else if (item.toLowerCase()  == "nobu") {
            try {
              const dataQRNobu = await api.xendit.getQRNobu({
                transactionNo: dataDetailData.id,
                referenceNo: JSON.parse(userServer),
                amount: parseInt(totalsisa),
                validTime: 900,
                detailData: dataenkrip.data,
              });
              setLoading(false);
              localStorage.removeItem("pesanan");
              navigate(`/${router}metode-pembayaran-nobu/:id`, {state:{
                params: dataQRNobu.data,
                detailTrans: detailTrans,
                detailData: dataDetailData,
              }});
            } catch (error) {
              console.log(error.message)
            }
           
          } else if (item.toLowerCase()  == "shopeepay-xend" || item.toLowerCase()  == "ovo-xend"){
            if(totalsisa > 100){
            try {
              const data = {
                userId: "-",
                transMode: "edit",
                detailData: dataDetailData,
                from_origin: "*",
                userServer: JSON.parse(userServer),
                transCode: "so",
                transId: dataDetailData.id,
              };
              const params = {
                userServer: JSON.parse(userServer),
                id: dataDetailData.id,
                total: parseInt(totalsisa),
                tipePembayaran:
                  item === "Shopeepay-XEND"
                    ? "ID_SHOPEEPAY"
                    : item === "OVO-XEND"
                    ? "ID_OVO"
                    :item ,
                mode: "prod",
                data: dataenkrip.data,
  
              };
  
              if (item == "Shopeepay-XEND") {
                // https://cool.uruz.id/delivery/transaksi-proses
                params["redirectLink"] = `${baseUrl}${router}#/transaksi-proses`;
                // params['redirectLink'] = `${'http://localhost:8000/'}${router}transaksi-proses`
                params["phoneNumber"] = "";
              }
              if (item == "OVO-XEND") {
                params["phoneNumber"] = nomerHp;
                params["redirectLink"] = "";
              }
              
              const result = await api.xendit.payment(params);
              // console.log('data payment == '+JSON.stringify(result))
  
              // if (result.data.status == 'OK') {
              // localStorage.setItem("id_wallet", JSON.stringify(dataDetailData['id']))
              // const data = JSON.parse(result.config.data)
              localStorage.setItem("id_wallet", JSON.stringify(result?.data?.id));
  
              localStorage.removeItem("pesanan");
              setLoading(false);
              if (item == "Shopeepay-XEND") {
                window.location.assign(
                  result.data.actions.mobile_deeplink_checkout_url
                );
                // navigate(`/${router}transaksi-proses`,{
                //     detailTrans: detailTrans
                // })
              }
              if (item == "OVO-XEND") {
                navigate(`/${router}transaksi-proses`, {state:{
                  detailTrans: detailTrans,
                  detailData: dataDetailData,                
                }});
                // console.log(detailTrans)
              }
              localStorage.setItem("metodeID", item);
              localStorage.setItem("metode", item);
  
              // }
              // else {
              //     setLoading(false)
              // }
            } catch (error) {
              console.log(error.response.data.message)
              addToast(error.response.data.message, {
                appearance: "error",
                autoDismiss: true,
              });
              setLoading(false)
              setIdData(dataDetailData.id)

            }
          }
          else{
            addToast("Total untuk pembayaran minimal 100", {
              appearance: "error",
              autoDismiss: true,
            });
            setLoading(false)
            setIdData(dataDetailData.id)

          }
            
          }
          else{
            addToast("Silahkan Pilih Metode Pembayaran lain", {
              appearance: "error",
              autoDismiss: true,

            });
            setLoading(false)
            setIdData(dataDetailData.id)

          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        addToast(error.response.data.message, {
          appearance: "error",
          autoDismiss: true,

        });
        setLoading(false)
        setIdData(dataDetailData.id)

      }
    } catch (e) {
      // console.log('catch >  '+JSON.stringify(e.response.data.message))
      setLoading(false);
      console.log(e);
      setIdData(dataDetailData.id)

      // addToast(e.response.data.message, {
      //     appearance: 'error',
      // })
      // addToast(e.response, {
      //     appearance: 'error',
      //     autoDismiss: true,
      // })
    }
  }
  else{
    addToast('Silahkan Pilih Metode Pembayaran', {
          appearance: 'error',
          autoDismiss: true,
      })
      setLoading(false)
  }
  }
  const [isOn, setIsOn] = React.useState(false);

  React.useEffect(() => {
    const pesanan = decrypt(JSON.parse(localStorage.getItem("pesanan")));

    var point = 0
    var coin = 0
    for(let i=0;i<pesanan?.length;i++){
        point = point + (pesanan[i]?.point*pesanan[i]?.item)
        coin = coin + (pesanan[i]?.coin*pesanan[i]?.item)

    }
    setPoinProduk(point)
    setCoinProduk(coin)
  }, []);

  return (
    <div className="Main">
      <Navbar
        urlBack={pesanan ? "home" : "home"}
        type="Payment"
        label="Pembayaran"
      />

      {/* <div className='Body'>
                {
                    JSON.parse(localStorage.getItem("coaweb")).length !== 0 &&
                    <Container className='Item'>
                        <Row className="title"> E-Wallet </Row>

                        {
                            dataPay.map((items, index) => {
                                return (
                                    <div key={index} onClick={() => onPembayaran(items)} style={{ display: 'flex', backgroundColor: '#fff', height: 60, margin: '10px 0px 0px 0px', borderRadius: 5, boxShadow: '0px 1px 5px -3px #000', justifyContent: 'center' }}>
                                        <div style={{ margin: 10, width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <div className="fonts700">
                                                    {items['name']}
                                                </div>
                                                
                                            </div>
                                            <AiOutlineRight color="#552CB7" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Container>
                }
                {loading &&
                    <LoadingFull />
                }
            </div> */}

      {/* <div>
        <Container>
          <Container className="Payment1">
            {royalti.map((items, index) => {
              return (
                <div>
                  <Row
                    xs={2}
                    className={`Item ${
                      selectedPayment === items.id ? "selected" : ""
                    }`}
                    key={index}
                  >
                    <Col className="Logo" xs={10}>
                      <img src={items.image} />
                      <Row>
                        <div className="title">{items["name"]}</div>
                        <div className="title">{"Saldo : " + items.saldo}</div>
                      </Row>
                    </Col>
                    <Col xs={2}>
                      <Form>
                        <Form.Check
                          type="switch"
                          id={index}
                          value={
                            items.name === "StarPay"
                              ? switchStateStarPay
                              : items.name === "StarPay Coin"
                              ? switchStateStarPayCoin
                              : switchStateStarPayPoint
                          }
                          onChange={(e) => {
                            if (items.name === "StarPay") {
                              setSwitchStateStarPay(e.target.checked);
                            } else if (items.name === "StarPay Coin") {
                              setSwitchStateStarPayCoin(e.target.checked);
                            } else {
                              setSwitchStateStarPayPoin(e.target.checked);
                            }
                          }}
                        />
                      </Form>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Container>
        </Container>

        {loading && <LoadingFull />}
      </div> */}

      <div>
        {JSON.parse(localStorage.getItem("coaweb")).length !== 0 && (
          <Container className="Form-1">
            <Row
              className="Item"
              style={{
                fontSize: "20px",
                paddingTop: "5%",
                paddingBottom: "5%",
              }}
            >
              <Col className="app-title">Payment Method</Col>
            </Row>

            <Container className="Payment">
              {pay.map((items, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedPayment(items.id);
                          handlePaymentSelection(items.id);
                        }}
                      >
                        <Row
                          xs={2}
                          className={`Item ${
                            selectedPayment === items.id ? "selected" : ""
                          }`}
                          key={index}
                        >
                          <Col className="Logo" xs={10}>
                            <img src={items.image} />
                            <div
                              onClick={() => {
                                setSelectedPayment(items.id);
                                handlePaymentSelection(items.id);
                              }}
                              className="title"
                            >
                              {items['name']}
                              {/* {items["id"] === 'OVO-XEND'?'OVO':items['id']=== 'Shopeepay-XEND' ?'Shopeepay': items['id'] === 'URUZ-XEND' ? 'QRIS' : items.id} */}
                            </div>
                          </Col>
                          <Col xs={2}>
                            <input
                              type="radio"
                              value={items.id}
                              checked={selectedPayment === items.id}
                              onChange={() => handlePaymentSelection(items.id)}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
              })}
            </Container>
          </Container>
        )}
      </div>

      <Container style={{ marginBottom: "20%" }}>
        <Container className="Form-1">
          <Row
            className="Item"
            style={{
              fontSize: "20px",
              paddingTop: "5%",
              paddingBottom: "5%",
            }}
          >
            <Col className="app-title">Ringkasan Pembayaran</Col>
           
          </Row>
          {
                        detailpembayaran.map((items, index) => (
                            <Row xs={2} className='Form-summary'>
                                <Col xs={6}>
                                    <div className="title" style={{height:'100%',fontSize:'14px', display:'flex', alignItems:'center'}}>{items.label}</div>       
                                </Col>
                                <Col xs={6}>
                                    <div className="Menu-desc" style={{textAlign:'right'}}>Rp. {formatterMoney(items.value)}</div>        
                                </Col>
                            </Row>
                        ))
                    }
          {/* <Row xs={2} className="Form-summary">
            <Col xs={6}>
              <div
                className="title"
                style={{
                  height: "100%",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {"Total Belanja "}
              </div>
            </Col>
            <Col xs={6}>
              <div className="Menu-desc" style={{ textAlign: "right" }}>
                Rp. {formatterMoney(totalBelanja)}
              </div>
            </Col>
          </Row> */}

          {/* <Row xs={2} className="Form-summary">
            {switchStateStarPay === true && (
              <Col xs={6}>
                <div
                  className="title"
                  style={{
                    height: "100%",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {"Pakai " + royalti[0].name}
                </div>
              </Col>
            )}
            {switchStateStarPay === true && (
              <Col xs={6}>
                <div className="Menu-desc" style={{ textAlign: "right" }}>
                  -Rp. {formatterMoney(royalti[0].saldo)}
                </div>
              </Col>
            )}
          </Row> */}

          <Row xs={2} className="Form-summary">
            {switchStateStarPayCoin === true && (
              <Col xs={6}>
                <div
                  className="title"
                  style={{
                    height: "100%",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {"Pakai " + royalti[0].name}
                </div>
              </Col>
            )}
            {switchStateStarPayCoin === true && (
              <Col xs={6}>
                <div className="Menu-desc" style={{ textAlign: "right" }}>
                  -Rp. {formatterMoney(royalti[0].saldo)}
                </div>
              </Col>
            )}
          </Row>

          <Row xs={2} className="Form-summary">
            {switchStateStarPayPoint === true && (
              <Col xs={6}>
                <div
                  className="title"
                  style={{
                    height: "100%",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {"Pakai " + royalti[1].name}
                </div>
              </Col>
            )}
            {switchStateStarPayPoint === true && (
              <Col xs={6}>
                <div className="Menu-desc" style={{ textAlign: "right" }}>
                  -Rp. {formatterMoney(royalti[1].saldo)}
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </Container>
      <Container>
        {
          <Container
            fluid
            className="BottomBarTwoItem"
            style={{ backgroundColor: "white" }}
          >
            <Row className="Container">
              <Col xl={3} lg={3} md={3} sm={3} xs={5}>
                <div className="title">Total</div>
                <div>Rp. {formatterMoney(totalBelanja)}</div>
                {/* <div>Rp. {formatterMoney(totalsisa)}</div> */}
              </Col>

              <Col xl={8} lg={9} md={9} sm={9} xs={7}>
                <div className="price-add">
                  <button onClick={() => onPembayaran(selectedPayment)}>
                    Bayar
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        }
      </Container>
      {/* <Container fluid className="BottomBarItem">
        <Row
          className="Container"
          style={{
            textAlign: "center",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="payment">
            <button onClick={() => onPembayaran(selectedPayment)}>
              <a>Bayar</a>
            </button>
          </div>
        </Row>
      </Container> */}
       { (loading)  &&
                    <LoadingFull />
                }
    </div>
  );
}
