import React, { useEffect ,useState} from 'react'
import './ModalBayar.css'
import { AiOutlinePercentage } from "react-icons/ai";
import { BiPlus , BiMinus, BiX} from "react-icons/bi";
import formatterMoney from '../../utils/formatMoney'
import { encrypt, decrypt } from "../../utils/enkripsi"
import { url } from "../../Constants"
import ImageNotFound from "../../Assets/default-img.jpg"
import { useDispatch, useSelector } from "react-redux"
import { setKeranjang } from "../../redux/actions/prepareActions"
import Gap from "../../components/Gap"
import Logo from "../../Assets/Logo"
import { useToasts } from 'react-toast-notifications';

export default function ModalPaket({showModalPaket, setShowModalPaket, onClickTambahPesanan, dataOrder ,setShowBayar,showBayar,dataProduct,setDataProduct}) {
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const state = useSelector(state => state?.dataPrepare)
    const [quantity, setQuantity] = React.useState(1)
    const [quantity2, setQuantity2] = React.useState(0)
    const [note, setNote] = React.useState('')
    const [products, setProducts] = React.useState('')
    const [products2, setProducts2] = React.useState('')
    const [products3, setProducts3] = React.useState('')
    const [statusClick, setStatusClick] = React.useState(0)

    let pesanan = localStorage.getItem('pesanan')

  
    const [selectedProductIds, setSelectedProductIds] = useState({});
      const handleRadioButtonChange = (categoryName, productId) => {
        setSelectedProductIds((prevSelectedProductIds) => ({
          ...prevSelectedProductIds,
          [categoryName]: productId,
        }));
      };
    let datapaket = []
    
    const handleOnChange = (e,index) => {
        console.log(e.target.value,index,products)
        setProducts(e.target.value)
    // setProducts[index]( e.target.value )
        // const a = []
        // for(let i=0;i<dataOrder.category.length;i++){
        //     a.push(e.target.value)
        // }
        // console.log(a)
        // if(products !== []){
        //     console.log('masuk')
        //     for(let i=0;i<products.length;i++){
        //         if(products[i] !== e.target.value){
        //             products.push(e.target.value)
        //         }
        //     }
        // }
        // else{
        //     products.push(e.target.value)
        // }
        // console.log(products)
    }

    useEffect(() => {
        // Initialize selectedProductIds with the first product ID from each category
        const initialSelectedProductIds = {};
        dataOrder?.category?.forEach((items) => {
          if (items.detail.length > 0) {
            initialSelectedProductIds[items['category_name']] = items.detail[0].product_name;
          }
        });
        setSelectedProductIds(initialSelectedProductIds);
        
      }, [dataOrder]);

      useEffect(() => {
        if(selectedProductIds){
          const dataArray = Object.entries(selectedProductIds).map(([category, value]) => ({ category, value }));
      
          for(let i=0;i<dataArray.length;i++){
            if(dataOrder.category[i].category_name === dataArray[i].category) {
              for(let j=0;j<dataOrder.category[i].detail.length;j++){
                if(dataOrder.category[i].detail[j].product_name=== dataArray[i].value){
                  datapaket.push(dataOrder.category[i].detail[j])
                }
            }
          }
        }
        }
    

      }, [selectedProductIds]);
    const handle = (e) => {
        setProducts2( e.target.value)
    }
    const handleProduct = (e) => {
        setProducts3( e.target.value)
    }
    const [selectedValues, setSelectedValues] = React.useState({});

  const handleRadioChange = (val, index, promo) => {
    console.log(val.target.value,index,promo.product_name)
    let nilai=0;
    nilai += val
    for (let i = 0; i < dataOrder.category.length; i++) {
        for(let j = 0; j < dataOrder.category[i].detail.length; j++){
        const e = dataOrder.category[i].detail[j];
            if (e) {							
                    if (promo.product_name == e.product_name) {
                            e["qty_now"] = parseInt(val)
                            e["sub_total"] = nilai
                    }
            }
            // console.log(e)

        }
    }

   
  };
    pesanan = pesanan == null ? [] : decrypt(JSON.parse(pesanan))
    useEffect(() => {
        if (showModalPaket) {            
            let pesanan1 = decrypt(JSON.parse(localStorage.getItem('pesanan')))
            setNote('')
            if (pesanan1) {                
                if (pesanan1.length !== 0) { 
                    let number = 1        
                    let price =  0 
                    for(const x of pesanan1){
                        if (dataOrder['name'] == x['name']) {
                            number = x['item'] + 1
                            price = x['item']
                            setNote(x['note'])
                        }
                    }
                    setQuantity(number) 
                    setQuantity2(price)             
                }
            }
        }
    },[showModalPaket])

    function imageExists(url, callback) {
        var img = new Image();
        img.onload = function() { callback(true); };
        img.onerror = function() { callback(false); };
        img.src = url;
    }

    var imageUrl = url+dataOrder['picpath1'];
    imageExists(imageUrl, function(exists) {
        if (exists == true) {
            dataOrder["show_img"] = true
        }else{
            dataOrder["show_img"] = false
        }
    });



let category = []


for(let data in dataOrder.category) {
    category.push(dataOrder.category[data].category_name)
}
    


    return (
        <div className={showModalPaket ? 'modal-menu active' : 'modal-menu' }>
            <div onClick={() => {setShowModalPaket(false);setShowBayar(false)}} 
                style={{  
                    margin: '10px 0px 0 10px',
                    position: 'fixed',
                    borderRadius: 40,
                    height: 40, 
                    width: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }} >
                <BiX size={30} color="#fff"/>
            </div>
            
            {
                dataOrder['show_img'] && (
                    
                    <>
                    {
                        dataOrder['picpath1_thumb'] !== '-' && dataOrder['picpath1_thumb'] ?
                        <img style={{ 
                                // height: '40%', 
                                width: '100%', 
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20, 
                                marginBottom: 10,
                                }} src={url+dataOrder['picpath1_thumb']}/>
                        :
                        
                        <img style={{ 
                             // height: '40%', 
                             width: '100%', 
                             borderTopLeftRadius: 20,
                             borderTopRightRadius: 20, 
                             marginBottom: 10,
                             }} src={ImageNotFound}/>
                    }
                    </>
                )   
            }

            {
                !dataOrder['show_img'] && <div style={{
                    width: '100%', 
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20, 
                    marginBottom: 10,
                    height: "40%",
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    
                    <Logo height='50' width='200'/>
                </div>
            }
            
            <div style={{ width: '100%' }}>
                <div style={{ margin: 20}}>
            <div className="fonts700" style={{ fontSize: 16, paddingTop: 5}}>{dataOrder['name']}</div>
            <div style={{ fontSize: 14, paddingTop: 5, color: "#BFBFBF"}}>{dataOrder['description']}</div>
            {/* {
                dataOrder?.map((items) => (
                    <div>
                    {
                        items?.category?.map((item) => (
<div>
{
                        item?.detail?.map((itemsa) => (
<div>
    {itemsa.product_name}
</div>
                            ))}
</div>
                            ))
          }
          </div>
                    ))
          } */}
    {/* {dataOrder?.category?.map((items) => (
  <div key={items.category_name} style={{ fontSize: 16, paddingTop: 20 }}>
    <span className="fonts700">{items.category_name}</span>
    {items.detail.map((data) => (
      <div className="row" style={{ paddingTop: 5, fontSize: 14 }} key={data.product_name}>
        <div className="col-sm-12">
          <form>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={data.product_name}
                  onChange={() => {
                    console.log("Selected value:", data.product_name);
                    console.log("Selected values:", selectedValues);
                    // handleRadioChange(items.category_name, data.product_name, data.product_name);
                  }}
                  checked={selectedValues[items.category_name] === data.product_name}
                />
                <span style={{ paddingLeft: 10 }}>{data.product_name}</span>
              </label>
            </div>
          </form>
        </div>
      </div>
    ))}
  </div>
))} */}


{
  dataOrder?.category?.map((items, index) => {
    
    return(
    items['category_name'] === category[index] && (
      <div style={{ fontSize: 16, paddingTop: 20 }} key={index}>
        <span className="fonts700">{items['category_name']}</span>

        {items.detail.map((data) => (
          <div className="row" style={{ paddingTop: 5, fontSize: 14 }} key={data.product_id}>
            <div className="col-sm-12">
              <form>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={data.product_name}
                      onChange={() => handleRadioButtonChange(items['category_name'], data.product_name)}
                      checked={selectedProductIds[items['category_name']] === data.product_name}
                    />
                    <span style={{ paddingLeft: 10 }}>{data.product_name}</span>
                  </label>
                </div>
              </form>
            </div>
          </div>
        ))}
      </div>
    )
  )})
}


         
         {/* {
                dataOrder?.category?.map((items,index) => (
                    items['category_name'] == category[1] &&
                    <div  style={{ fontSize: 16, paddingTop: 5}}  value= {items['category_name']}>
                        <span className="fonts700">{items['category_name']}</span>
                                {items.detail.map((data) => (
                                          <div className="row" style={{paddingTop: 5, fontSize: 14}}>
                                          <div className="col-sm-12">
                                            <form>
                                                <div className="radio" >
                                                  <label >
                                                  <input  type="radio" value={data.product_name} onChange= {handleOnChange} checked= {products == data.product_name} />
                                                  <span  style={{ paddingLeft: 10 }} >{data.qty} {data.product_name}</span>
                                                  </label>
                                                </div>          
                                                </form>
                                          </div>
                                        </div>
                                ))}      
                      
              </div>

                                
                ))
          }

{
                dataOrder?.category?.map((items,index) => (
                    items['category_name'] == category[2] &&
                    <div  style={{ fontSize: 16, paddingTop: 5}}  value= {items['category_name']}>
                        <span className="fonts700">{items['category_name']}</span>
                                {items.detail.map((data) => (
                                          <div className="row" style={{paddingTop: 5, fontSize: 14}}>
                                          <div className="col-sm-12">
                                            <form>
                                                <div className="radio" >
                                                  <label >
                                                  <input  type="radio" value={data.product_name} onChange= {handleProduct} checked= {products3 == data.product_name} />
                                                  <span  style={{ paddingLeft: 10 }} >{data.qty} {data.product_name}</span>
                                                  </label>
                                                </div>          
                                                </form>
                                          </div>
                                        </div>
                                ))}      
                      
              </div>

                                
                ))
          } */}
  {/* {selectedProductId !== null && (
        <div>
          <h4>Selected Product Details:</h4>
          {dataOrder.category[0].detail.map((product) =>
            product.product_id === selectedProductId ? (
              <div key={product.product_id}>
                <p>Name: {product.product_name}</p>
                <p>Price: {product.price}</p>
                <p>Quantity: {product.qty}</p>
              </div>
            ) : null
          )}
           </div>
      )} */}
                    <div style={{ display: 'flex', paddingTop: 100 }}>
                        <div style={{ display: 'flex', width: '40%', backgroundColor: '#fff', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="fonts700" style={{color: "#162A48", paddingTop: 2}}>{'Rp ' +formatterMoney(dataOrder['harga'])}</div>
                        </div>
                        <div style={{ width: '140%', display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={() => {
                            // console.log(selectedValues,dataOrder)
                            // let orderanpaket = []
                            // for(const item of dataOrder.category){
                            //     console.log(item)
                            //     for(let i=0;i<item.length;i++){
                            //         if(item[i].category_name === selectedValues){
                                        
                            //         }
                            //     }
                            // }
                          
                          
                          if (pesanan.length !== 0) {
                            let foundMatchingOrder = false;
                            
                            for (const xi of pesanan) {
                                if (xi['name'] === dataOrder['name']) {
                                    xi['item'] = quantity;
                                    xi['total'] = dataOrder['harga'] * quantity;
                                    foundMatchingOrder = true;
                                    break; // Exit the loop once a match is found
                                }
                            }
                            
                            if (!foundMatchingOrder) {
                                pesanan.push(Object.assign(dataOrder, {
                                  products:datapaket,
                                    item: quantity,
                                    total: dataOrder['harga'] * quantity,
                                    note: note
                                }));
                            }
                        } else {
                                pesanan.push(Object.assign(dataOrder, {
                                  products:datapaket,
                                    item: quantity,
                                    total: dataOrder['harga'] * quantity,
                                    note: note
                                }));
                            
                        }
                        
                         
                          
                     
                         
                          const resultPesanan = pesanan.filter(items => items['item'].toString() !== '0')
                          console.log(resultPesanan)
                          localStorage.setItem('pesanan', JSON.stringify(encrypt(resultPesanan)))
                          setShowModalPaket(false)
                          setShowBayar(false)
                          dispatch(setKeranjang())
                            onClickTambahPesanan()
                            }} style={{ padding: '7px 0 7px 0',  borderRadius: 5, backgroundColor: '#552CB7', width: '100%',border: 'none', marginLeft: '30px'}}>
                                <span style={{ color: '#fff'}} className="fonts500">Tambah Pesanan</span>
                            </button>
                         </div>
                        </div>
                        
                     </div>
                    </div>
                
            </div>
        
    )
}