import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { router } from "../../Constants"
import { BiBasket, BiHome, BiFoodMenu } from "react-icons/bi"
import { IoCartOutline, IoCartSharp } from "react-icons/io5"
import { FaUser, FaRegUser } from "react-icons/fa"
import { RiHome3Fill, RiHome3Line, RiFileList2Fill, RiFileList2Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import UruzB from '../../Assets/UruzBlue.png';
import '../BottomBar/BottomBar.css';

function MenuBottomNav({children}){
  const [value, setValue] = React.useState(0);

  const dataTab = [
    {
        label: "Menu",
        icons:  <BiFoodMenu size={30} color="#808080"/>,
        icons_active: <BiFoodMenu  size={30} color="#5451D6"/>,
        url: `/${router}home`
    },
    {
        label: "Keranjang",
        icons:  <IoCartSharp size={30} color="#808080"/>,
        icons_active: <RiFileList2Fill  size={30} color="#5451D6"/>,
        url: `/${router}detail-order`
    },
    {
        label: "Transaksi",
        icons:  <RiFileList2Line size={30} color="#808080"/>,
        icons_active: <RiFileList2Fill  size={30} color="#5451D6"/>,
        url: `/${router}home/lacak-pesanan`
    },
  ]

  let navigate = useNavigate()
  const state = useSelector(state => state?.dataPrepare?.order)   
  const [url, setUrl] = React.useState()

  React.useEffect(() => {
    navigate.listen( location =>  {
      setUrl(location?.pathname)
    });
    if (!url) {
      setUrl(window.location.pathname)
    }
  },[])


  return (
    <Container fluid className='BottomBarThreeMenu'style={{backgroundColor:'white'}}>
      <Row xl={3} className='Menu-item'>
        {
          dataTab?.map((items, index) => (
            <Col className="icon">
              <Link key={index} to={items.url}>
                {
                    url !== items.url ? (<>
                        {items.icons}
                    </>
                    )
                    : (
                    <>
                        {items.icons_active}
                    </>
                    )
                }
                
                <div style={{ fontSize: 15, color: url !== items.url ? '#808080' : '#5451D6'}}>{items.label}</div>
                      
                {
                  items.label == "Transaksi" && <>
                    {
                      state.length !== 0 &&
                      <div style={{ width: '20%', height: '100%', position: 'absolute', display: 'flex', justifyContent:'flex-end'}}>
                        <div style={{ height: 18, width: 25, backgroundColor: 'red', borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <span style={{ color: '#fff', fontSize: state.length < 10 ? 10 : 8, fontWeight: 'bold' }}>{ state.length < 99 ? state.length : '+99'}</span>
                        </div>
                      </div>
                    }
                  </>
                }
              </Link>
            </Col>
          ))
        }
      </Row>
    </Container>
  );
}

function MenuAddNav() {
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleAddClick = () => {
    setIsLoading(true);

    // Simulate an API call or database query
    setTimeout(() => {
      setIsLoading(false);
      navigate('/Customer');
    }, 6000);
  };

  return (
    <Container fluid className='BottomBarTwoItem'>
      <Row className='Container'>
        <Col xl={3} lg={3} md={3} sm={3} xs={4}>
          <div className='counter'>
            <button onClick={handleDecrement}>-</button>
            <span>{count}</span>
            <button onClick={handleIncrement}>+</button>
          </div>
        </Col>

        <Col xl={9} lg={9} md={9} sm={9} xs={8}>
          <div className='price-add'>
            <button onClick={handleAddClick} disabled={isLoading}>
              {isLoading ? (
                <span className='spinner' />
              ) : (
                <a>Add Rp. 36.000</a>
              )}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function MenuPayNav() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddClick = () => {
    setIsLoading(true);

    // Simulate an API call or database query
    setTimeout(() => {
      setIsLoading(false);
      navigate('/Payment');
    }, 6000);
  };

  return (
    <Container fluid className='BottomBarTwoItem'>
      <Row className='Container'>
        <Col xl={3} lg={3} md={3} sm={3} xs={4}>
          
          
        </Col>

        <Col xl={9} lg={9} md={9} sm={9} xs={8}>
          <div className='price-add'>
            <button onClick={handleAddClick} disabled={isLoading}>
              {isLoading ? (
                <span className='spinner' />
              ) : (
                <a>Bayar</a>
              )}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function MenuSelectPayNav() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddClick = () => {
    setIsLoading(true);

    // Simulate an API call or database query
    setTimeout(() => {
      setIsLoading(false);
      navigate('/Confirm');
    }, 6000);
  };

  return (
    <Container fluid className='BottomBarItem'>
      <Row className='Container'>
        <Col>
          <div className='payment'>
            <button onClick={handleAddClick} disabled={isLoading}>
              {isLoading ? (
                <span className='spinner' />
              ) : (
                <a>Bayar</a>
              )}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function Footer(){
  return (
      <Container fluid className='FooterItem'>
          <div className='bottomNav'>
              Powered by <img className='Footer-Logo' src={UruzB}></img>
          </div>
      </Container>
  )
}

export { MenuBottomNav, MenuAddNav, MenuPayNav, MenuSelectPayNav, Footer };