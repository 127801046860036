// export const url = 'http://localhost:3000/'
// export const userServer = 'uruz_local'
const server = JSON.parse(localStorage.getItem("dataBranch1"))


// export const baseUrl = 'https://cool.uruz.id/'
export const baseUrl = 'https://delivery.uruz.id/'
// export const baseUrl = 'http://localhost:8000/'
// export const url = 'https://cool.uruz.id/api/'
export const url = 'https://hot.uruz.id/api/'

export const userServer = server ? server.connection : null
export const googleMapApiKey = "AIzaSyB1wTcrhxag0w5wmqHK7MYSLq80sWrnzGw"
export const router = ""
// export const mode = "dev"
export const mode = "prod"



