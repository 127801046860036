import React from 'react'
import CopyRight from '../../Assets/CopyRight'
import { getFirestore, doc, getDoc} from "firebase/firestore";
import Responsif from '../../utils/Responsif'
import firebase from '../../firebase'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged} from "firebase/auth";
import Input from '../../components/Input'
import OtpInput from 'react-otp-input';
import Button from '../../components/Button';
import Gap from '../../components/Gap';
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { router } from "../../Constants"
import ScreenOTP from './ScreenOTP';


function KodeOTP(params) {
	const { addToast } = useToasts()
    let navigate = useNavigate()
	const [input, setInput] = React.useState({
		mobile : '',
		otp : ''
	})
	const [loading, setLoading] = React.useState(false)


	async function onOtp() {
		const code = input.otp;
		if (window.confirmationResult !== undefined) {			
			window.confirmationResult.confirm(code).then( async (result) => {
			  // User signed in successfully.
			  const user = result.user;
			  // User couldn't sign in (bad verification code?)
			  if (user) {
				const phone   = user.phoneNumber;
				const db      = getFirestore();
				const docRef  = doc(db, "users", phone);
				const docSnap = await getDoc(docRef);
				let data = docSnap.data()
				localStorage.setItem('login', JSON.stringify(data))
				navigate(`/${router}home`)
			  } 
			  setLoading(false)
			  console.log("berhasil konfirmasi", user)
			  // ...
			}).catch((error) => {
				switch(error.code) {
					case 'auth/too-many-requests':
						setLoading(false)
						addToast("Too many requests, please try again after 5 minutes.", {
							appearance: 'error',
							autoDismiss: true,
						})
						break;
						case 'auth/invalid-verification-code':
						setLoading(false)
						addToast("Nomor OTP tidak sama dengan yang dikirimkan", {
							appearance: 'error',
							autoDismiss: true,
						})
						setInput(val => ({
							...val,
							otp: ''
						}))
						break;
						case 'auth/code-expired':
						setLoading(false)
						addToast("Nomor OTP sudah expired, silahkan kirim ulang", {
							appearance: 'error',
							autoDismiss: true,
						})
						break;
						default:
						setLoading(false)
						addToast("An unknown error occured.", {
							appearance: 'error',
							autoDismiss: true,
						})
						break;
				}
			  console.log(error)
			});
		}
		else {
			navigate(`/${router}login`)
		}
	}
	
	function onSubmitOTP(e){
		setLoading(true)
		e.preventDefault()
		onOtp()
	}
	const { width } = Responsif()

    return(
		<ScreenOTP onClickBack={() => navigate(`/${router}login`)} input={input} setInput={setInput} onSubmitOTP={onSubmitOTP} loading={loading} ResendOTP={() => {
			{navigate(`/${router}login`)}
		}}/>
    )
}

export default KodeOTP
