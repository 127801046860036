import React from "react";

export default function useWindowSize(isSSR) {
    const [windowSize, setWindowSize] = React.useState({
        width: isSSR !== undefined ? 1200 : window.innerWidth,
        height: isSSR !== undefined ? 800 : window.innerHeight,
      });

    React.useEffect(() => {
        window.addEventListener("resize", () => {
          setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        });
        return () => {
            window.removeEventListener("resize", () => {
              setWindowSize({ width: window.innerWidth, height: window.innerHeight });
            });
        }
    }, [])

    if (typeof window !== "undefined") {
        return { width: 1200, height: 800 };
    }

    console.log('responsif',windowSize)

    return windowSize;
}