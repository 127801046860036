import baseRequest  from "../utils/request"


export default class Charges extends baseRequest {
    constructor() {
        super();
        this.urls = {
            getCharges: "products-web/get-charges",
            getHargaJarak: "products-web/get-harga-jarak",
        }
    }

    getCharges = params => this.post(this.urls.getCharges, params)
    getHargaJarak = params => this.post(this.urls.getHargaJarak, params)
}