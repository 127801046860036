import { SET_KATEGORY, SET_LIST_KATEGORI, SET_PRODUK, SET_SHIFT, SET_KERANJANG, SET_ORDER, SET_GET_PAKET } from "../constants/prepareConstants"

let initialState = { 
    kategory : [],
    listKategori: [],
    produk: {
        loading: true,
        data: []
    },
    shift: {},
    keranjang: 0,
    order: []
};


export const prepareReducer = (state = initialState , action) => {
    if (action.type ===  SET_KATEGORY ) {
        return {
            ...state,
            kategory: action.value
        }
    }
    if (action.type ===  SET_LIST_KATEGORI ) {
        return {
            ...state,
            listKategori: action.value
        }
    }
    if (action.type ===  SET_PRODUK ) {
        return {
            ...state,
            produk: action.value
        }
    }
    if (action.type ===  SET_SHIFT ) {
        return {
            ...state,
            shift: action.value
        }
    }
    if (action.type ===  SET_KERANJANG ) {
        return {
            ...state,
            keranjang: action.value
        }
    }
    if (action.type ===  SET_ORDER ) {
        return {
            ...state,
            order: action.value
        }
    }
    if (action.type === SET_GET_PAKET) {
        return {
        ...state,
        data: action.value
    }
    }
    return state
}