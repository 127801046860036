export  const dataPay = [
    {
        name: 'URUZ-XEND',
        id: 'URUZ-XEND'
    },
    {
        name: 'Shopee Pay',
        id: 'ID_SHOPEEPAY'
    },
    {
        name: 'OVO',
        id: 'ID_OVO'
    },
    {
        name: 'Nobu',
        id: 'nobu'
    },
]