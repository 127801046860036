import { decrypt, encrypt} from "./enkripsi"



export function refreshCharges() {
    let local_charges = JSON.parse(localStorage.getItem('local_charges'))
    let dataBranch = JSON.parse(localStorage.getItem('dataBranch'))
    const drivery =  decrypt(localStorage.getItem('saveDelivery'))
    const pickup_delivery =  localStorage.getItem('pickup_delivery')
    var chargeNotes = [];
    local_charges?.forEach(baris=>{
        if (baris.branch_id === dataBranch.branchID) {  
            if (baris.dine_in_only == 1){
            //     if(pickup_delivery === 'delivery'){
                chargeNotes.push({
                    id : baris.id,
                    percent_value : baris.percent_value,
                    min_value : baris.min_value,
                    max_value : baris.max_value,
                    value : 0,
                    coa : baris.coa,
                    pb1 : baris.pb1
                });
            // }
            }
            else {
                chargeNotes.push({
                    id : baris.id,
                    percent_value : baris.percent_value,
                    min_value : baris.min_value,
                    max_value : baris.max_value,
                    value : 0,
                    coa : baris.coa,
                    pb1 : baris.pb1
                });
            }
        }
    })

    return chargeNotes
}




export function onHitung(pesanan) {
    const chargeNotes = refreshCharges()  
    const drivery =  decrypt(localStorage.getItem('saveDelivery'))
    const pickup_delivery =  localStorage.getItem('pickup_delivery')
    const login = JSON.parse(localStorage.getItem("login"))
    let data = {}
    let total = 0
    data.charges_value_non_pb1 = 0;
    data.charges_value_pb1 = 0;
    for(var i = 0; i < pesanan?.length; i++){
        let item = pesanan[i]
        let subTotal = item.price !== undefined? item.price : 0 
        total += subTotal
        data.sub_total = total
    }

    chargeNotes.forEach(d=>{
        if (d.pb1 == 0) {
            if ((data.sub_total > d.max_value) && (d.max_value > 0) ){
                d.value = d.percent_value / 100 * d.max_value;
                data.charges_value_non_pb1 += d.value; 
                
            }
            else {
                d.value = (d.percent_value / 100 * data.sub_total);
                data.charges_value_non_pb1 += d.value; 

            }
        }
        if (d.pb1 == 1) {
            if ((data.sub_total > d.max_value) && ((d.max_value || 0) > 0) ){
                d.value = d.max_value || 0;
                data.charges_value_pb1 += d.value; 
            }
            else {
                if(pickup_delivery === 'pickup'){
                    d.value = (d.percent_value / 100 * (data?.sub_total+ data?.charges_value_non_pb1));
                }
                else if(pickup_delivery !== 'pickup'){
                    d.value = (d.percent_value / 100 * (data?.sub_total + data?.charges_value_non_pb1 + drivery?.harga));
                }
                data.charges_value_pb1 += d.value; 

            }
        }
    })

    // data['grand_total'] = (data['sub_total'] +  (drivery && pickup_delivery == "delivery" ? drivery?.harga : 0))
    data['grand_total'] = (data['sub_total'] +  (drivery?.id == 'gojek' && pickup_delivery == "delivery"  && drivery?.harga !== undefined? drivery?.harga : drivery?.id !== 'gojek' && pickup_delivery == "delivery" && drivery?.harga !== undefined? drivery?.harga : 0))
    data['grand_total_net'] = data['grand_total'] + (data.charges_value_pb1 === NaN ? 0:data.charges_value_pb1)
    // data['grand_total_net'] = data['sub_total'] + data.charges_value_pb1
    data['delivery'] = drivery && pickup_delivery == "delivery" ? drivery?.harga : 0
    data['charges_value'] =  drivery?.harga === NaN || drivery?.harga === undefined ?0:(data.charges_value_pb1 +  (drivery && pickup_delivery == "delivery" ? drivery?.harga : 0))
    if(drivery && pickup_delivery === 'delivery'){
        if (drivery?.id == "gojek") {
            chargeNotes.push({
                "id":  'gosendfee',
                "percent_value":  data['delivery'] / data['sub_total'] * 100,
                "min_value": 0,
                "max_value": 0,
                "value": drivery?.harga ,
                "coa": chargeNotes?.[0]?.['coa'],
                "pb1": 0
            })
        }
        else {
            chargeNotes.push({
                "id":  'deliveryfee',
                "percent_value": 5,
                "min_value": 0,
                "max_value": 0,
                "value": (drivery?.harga === NaN || drivery?.harga === undefined ? 0 : drivery?.harga),
                "coa": chargeNotes?.[0]?.['coa'],
                "pb1": 0
            })
        }
    }


    return {
        chargeNotes: chargeNotes,
        data: data
    }
}
