import React from "react";
import "./modal.css"



export default function Modal({ children, visible }) {
    return (
        <div className={visible ? 'modal_global active' : 'modal_global'}>
            {/* {children} */}
        </div>
    )
}