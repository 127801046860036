    import React, { useState } from 'react'
    import Navbar from '../../components/Navbar'
    import './Order.css'
    import { BiMap, BiChevronDown } from "react-icons/bi";
    import { FiPhone } from "react-icons/fi";
    import { Link, useNavigate } from 'react-router-dom'
    import ModalCategory from './ModalCategory';
    import FETCH_API from '../../config/fetch_api'
    import formatterMoney from '../../utils/formatMoney'
    import ModalBayar from './ModalBayar';
    import { url, router } from '../../Constants.js'
    import Loading from '../../components/Loading';
    import { useToasts } from 'react-toast-notifications'
    import { decrypt, encrypt } from "../../utils/enkripsi"
    import api from '../../request';
    import moment from 'moment';
    import { useDispatch, useSelector } from "react-redux"
    import { RiEditBoxLine } from "react-icons/ri"
    import { AiOutlineCloseCircle } from "react-icons/ai"
    import { GrRefresh } from "react-icons/gr"
    import Modal from '../../components/Modal';
    import { getFirestore, doc, getDoc, setDoc, collection, getDocs} from "firebase/firestore";
    import Logo from "../../Assets/Logo"
    import { getDistanceAndPrice } from "../../redux/actions/gojekActions"
    import { SetKategori, getKategory, Produk, Shift, promoBuyXGetYActions } from "../../redux/actions/prepareActions"
    import { setOrder ,setKeranjang,paketActions} from "../../redux/actions/prepareActions"
    import ImageNotFound from "../../Assets/default-img.jpg"
    import ModalPaket from './ModalPaketPromo';


    import { Kategory as LayoutKategory, TabBottom } from "../../Layout"
    import Gap from '../../components/Gap';
    import { Col, Container, Row } from 'react-bootstrap';
    import Food1 from '../../Assets/Food/Food1.png';
    import Time from '../../Assets/Icon/Time.png';
    import Phone from '../../Assets/Icon/Phone.png';
import ModalBuyXFreeY from './ModalBuyXFreeY';
import ModalBuyXFreeYReward from './ModalBuyXFreeYReward';

    export default function Order() {
        const navigate = useNavigate()
        // const navigate = useNavigate();
        const dispatch = useDispatch()
        const state = useSelector(state => state?.dataPrepare)
        const produk = state?.produk
        const shift = state?.shift
        const { addToast } = useToasts()
        const [showBayar, setShowBayar] = React.useState(false)
        const [sidebar, setSidebar] = React.useState(false)
        const [dataProduct, setDataProduct] = React.useState([])
        let login = localStorage.getItem('login')
        const [dataOrder, setDataOrder] = React.useState({})
        const [dataBranch, setDataBranch] = React.useState({})
        const [visible, setVisible] = React.useState(false)
        const [back, setBack] =React.useState({})
        const [showModalPaket, setShowModalPaket] = React.useState(false)
        const [showModalBuyXFreeY, setShowModalBuyXFreeY] = React.useState(false)
        const [showModalBuyXFreeYReward, setShowModalBuyXFreeYReward] = React.useState(false)

        const [showCategoryBuyXFreeY, setShowCategoryBuyXFreeY] = React.useState(false)
        const [dataRequirementAll, setDataRequirementAll] = React.useState([])
        const [dataRewardAll, setDataRewardAll] = React.useState([])

        let pesanan = localStorage.getItem('pesanan')
        pesanan = pesanan == null ? [] : decrypt(JSON.parse(pesanan))

        const [isMenuSubMenu, setMenuSubMenu] = useState(true);

        let boxClassSubMenu = ["sub__menus"];
        if(isMenuSubMenu) {
            boxClassSubMenu.push('sub__menus__Active');
        }else {
            boxClassSubMenu.push('');
        }
        const [style, setStyle] = React.useState({})
        function getUrlVars() {
            var vars = {};
            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,   
            function(m,key,value) {
                vars[key] = value;
            });
            return vars;
        }
        const [requirement,setRequirement] = React.useState([])
        const [reward,setReward] = React.useState([])
        // const handleScroll = (e) => {
        //     const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        //     const body = document.body;
        //     const html = document.documentElement;
        //     const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        //     const windowBottom = windowHeight + window.pageYOffset;
        //     if (windowBottom + 1 >= docHeight) {
        //         if (produk.total_baris > 0) {                
        //             dispatch(Produk({
        //                 ...produk,
        //                 currentPage : produk.currentPage + 10,
        //                 total_baris : produk.total_baris - 10
        //             }))
        //         }
        //     }
        // };
        const handleScroll = () => {
            const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            const body = document.body;
            const html = document.documentElement;
            const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            const windowBottom = windowHeight + window.pageYOffset;
            if (windowBottom + 1 >= docHeight) {
                if (produk.total_baris > 0) {                
                    dispatch(Produk({
                        ...produk,
                        currentPage : produk.currentPage + 10,
                        total_baris : produk.total_baris - 10
                    }))
                }
            }
        };
        React.useEffect( async () => {
            try {
            const server = localStorage.getItem('userServer')
            if (server) {        
                const local_charges = await api.apiPrepare.local_charges()
                localStorage.setItem("local_charges", JSON.stringify(local_charges.data?.data))
                dispatch(setKeranjang())
                dispatch(setOrder())
            }
            } catch (error) {
                console.log(error)
            }
            
        },[])
        React.useEffect(() => {
            
            if (produk.data) {
                if(produk.category_id === 'Promo Buy X Free Y %'){
                    setShowCategoryBuyXFreeY(true)
                }
                setDataProduct(produk.data)
            }
            document.addEventListener('scroll', handleScroll);		
    
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
        }, [produk]);

        React.useEffect(async () => {
            const prepareLogin = JSON.parse(sessionStorage.getItem('prepareLogin'))
            const val = getUrlVars()
            if (!login && !prepareLogin) {   
                // setTimeout(() => {
                //     setSidebar(true)
                //     setStyle({
                //         width: '90%',
                //         top: '30%',
                //         position: 'absolute',
                //         left: '5%'
                //     })
                // }, 2000)    
            }
            if (val?.x2) {   
                try {
                    setBack('')
                    const dataencrypt = decrypt(val.x2)
                    const branchQueryString = dataencrypt.split(',')
                    if (branchQueryString[0] && branchQueryString[1]) {
                        localStorage.setItem("userServer", JSON.stringify(branchQueryString?.[0]))
                        const coa_transaksi = await api.order.coa_branch({
                            userId: ""
                        })
                        console.log(coa_transaksi)
                        localStorage.setItem("coa_transaksi", JSON.stringify(coa_transaksi.data.data[0]))
                        
                        const db = getFirestore();
                        const querySnapshot = await getDocs(collection(db, "clients"));
                        querySnapshot.forEach(async (doc) => {
                            const restoran = {
                                namaRestoran : doc.id,
                                cabang: doc.data().branches,
                                connection: doc.data().connection,
                                location: ""
                            }
                            if (restoran?.connection == branchQueryString?.[0]) {
                                let branch = []
                                for(const dataBranch of restoran?.cabang){
                                    branch.push(dataBranch.branch_id)
                                }
                                const resultData = await api.branches.getPos({ "branches"   : branch })
                                const res = resultData?.data?.data
                                setDataBranch(res?.[0])
                                localStorage.setItem('dataBranch', JSON.stringify(res?.[0]))
                                localStorage.setItem('local_branch', JSON.stringify(res))
                                // if(branchQueryString && branchQueryString[branchQueryString.length - 1]){
                                //     localStorage.setItem('table_id',branchQueryString[branchQueryString.length-1])
                                // }
                                // console.log(branchQueryString[branchQueryString.length-1])
                                if (branchQueryString && branchQueryString[branchQueryString.length-1]) {
                                    console.log(branchQueryString)
                                    const result = await api.apiPrepare.get1Produk({ "id"   : branchQueryString[branchQueryString.length-1] ,"userId":"admin"})
                                    console.log(result.data.data)
                                    if(result.data.data.length !== 0){
                                        const datagetorder = result?.data?.data[0]
                                        const dataorderan = {
                                        "weight": datagetorder.weight,
                                        "height": datagetorder.height,
                                        "width": datagetorder.width,
                                        "id": datagetorder.id,
                                        "description": datagetorder.description,
                                        "description_menu": datagetorder.description,
                                        "picpath1": datagetorder.picpath,
                                        "picpath1_thumb": datagetorder.picpath1_thumb,
                                        "product_id": datagetorder.id,
                                        "product_name": datagetorder.name,
                                        "price": datagetorder.price,
                                        "price_a": datagetorder.price_a,
                                        "price2": datagetorder.price2,
                                        "price2_a": datagetorder.price2_a,
                                        "price3": datagetorder.price3,
                                        "price3_a": datagetorder.price3_a,
                                        "price4": datagetorder.price4,
                                        "price4_a": datagetorder.price4_a,
                                        "price5": datagetorder.price5,
                                        "price5_a": datagetorder.price5_a,
                                        "pakai_harga": datagetorder.price,
                                        "base_url": "https://posimages.s3.ap-southeast-1.amazonaws.com/",
                                        "usePrice": datagetorder.price,
                                        "usePriceA": datagetorder.price,
                                        "item": 1,
                                        "show_img": true
                                    }
                                        setShowBayar(true);
                                        setDataOrder(dataorderan);
                                    }
                                    else{
                                        localStorage.setItem('table_id',branchQueryString[branchQueryString.length-1])
                                    }
                                }
                                
                                dispatch(getKategory())
                                dispatch(Produk())
                                dispatch(Shift()) 
                            }
                        });
                    }
                    else {
                        navigate("/")
                    }
                } catch (error) {
                    
                }
               
            }
            else {
                try {
                    const dataBranch = JSON.parse(localStorage.getItem('dataBranch'))

                    if (dataBranch) {
                        setBack('pickresto')
                        setDataBranch(dataBranch)
                        const coa_transaksi = await api.order.coa_branch({
                            userId: ""
                        })
                        
                        localStorage.setItem("coa_transaksi", JSON.stringify(coa_transaksi.data.data[0]))
                        if (produk.data.length == 0) {           
                            dispatch(getKategory())
                            dispatch(Produk())
                            dispatch(Shift())
                        }
                    }
                    else {
                        // navigate("/")
                    }
                } catch (error) {
                    
                }
               
            }

        },[])

        function onOrder(items) {
            setShowBayar(true)
            // navigate("/delivery/detailproduct",{items})
            setDataOrder(items)
        }

        if (pesanan.length !== 0) {
            for(const item of dataProduct){
                for(const items of pesanan){
                    if (item['product_id'] == items['product_id'] && item.name === items.name) {
                        item['item'] = items['item']
                    }
                }
            }
        }
        else {
            for(const item of dataProduct){
                item['item'] = null
            }
        }

        function imageExists(url, callback) {
            var img = new Image();
            img.onload = function() { callback(true); };
            img.onerror = function() { callback(false); };
            img.src = url;
        }

        const [isVisible, setIsVisible] = useState(false);

        const handlePress = () => {
            setIsVisible(true);
        }

        const handleClose = () => {
            setIsVisible(false);
        }

        function onOrderPaket(items) {
            setShowModalPaket(true)
            setDataOrder(items)
        }

        async function onOrderPromoBuyXFreeY(items) {
            try {
                         // setShowBayar(false)
            // let a = []
            // let b = []
            // if(items){
            //     for(let i=0;i<items?.detailProducts?.length;i++){
            //       if(items.detailProducts[i].sebagai === 'Requirement'){
            //         a.push(items.detailProducts[i])
            //       }
            //       else{
            //         b.push(items.detailProducts[i])
            //       }
            //     }
            //    setRequirement(a)
            //    setReward(b)
            //    console.log(a,b)
            //   }
            const tipeRequirement = items.dataMaster[0].tipe_requirement
            const tipeReward = items.dataMaster[0].tipe_reward
            const datarequirement = []
            const datareward = []
            for(const item of items.detailProducts){
                if(item.sebagai === 'Requirement'){
                    datarequirement.push(item)
                }
                else{
                    datareward.push(item)
                }
            }
            const res = await api.apiPrepare.produk({
                "search1": "",
                "pageSize" : "1000",
                "currentPage" : '0',
                "category_id" : 'all'
            })
            const response = res?.data?.data
            const dataprodukrequirement = []
            const dataprodukreward = []

            for(let i=0;i<response.length;i++){
                for(let j=0;j<datarequirement.length;j++){
                    if(response[i].product_id === datarequirement[j].product_id){
                        // dataprodukrequirement.push(response[i])
                        dataprodukrequirement.push({
                            ...response[i],
                            qty: datarequirement[j].qty
                          });   
                    }
                }
                for(let k=0;k<datareward.length;k++){
                    if(response[i].product_id === datareward[k].product_id){
                        dataprodukreward.push({
                            ...response[i],
                            discount: datareward[k].discount,
                            qty: datareward[k].qty
                          });                    
                        }
                }
            }

            const a = dataprodukrequirement.concat(dataprodukreward)
            setDataOrder(items)

            setDataRequirementAll(dataprodukrequirement)
            setDataRewardAll(dataprodukreward)
            setShowModalBuyXFreeY(true)
            } catch (error) {
                
            }
   
        }
        function updateOrder() {
            let pesanan = decrypt(JSON.parse(localStorage.getItem('pesanan')))
            let items = 0
            let total = 0
            if (pesanan) {              
                for(const xi of pesanan){
                    items += xi['item']
                    total += xi['total']
                    setOrder({
                        item: items,
                        total: total
                    })
                }
            }
        }
        function onClickTambahPesanan(val) {
            addToast("Pesanan sudah ditambah", {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 1500,
            })
            setShowBayar(false)
        }
        function onClickCategory(items) {
            let newArr = [...state?.kategory]
            for(const x of newArr){
                if (x.id == items.id) {
                    x['selection'] = true
                }
                else {
                    x['selection'] = false
                }
            }
            if (items.label == "Promo Paket") {
                dispatch(paketActions({
                    category_id: items.id
                }))
                setShowCategoryBuyXFreeY(false)

            }
            else if (items.label == "Promo Diskon") {
                dispatch(promoBuyXGetYActions({
                    category_id: items.id
                }))
                setShowCategoryBuyXFreeY(true)
            }
            
            else {
                dispatch(Produk({
                    category_id: items.id
                }))
                setShowCategoryBuyXFreeY(false)

            }
            dispatch(SetKategori(newArr))
            dispatch({
                type: 'SET_PRODUK',
                value: {
                    data: [],
                    loading: true
                }
            })
            dispatch({
                type: 'SET_GET_PAKET',
                value : {
                    data: [],
                    loading: false
                }
            })
     
          

        }

        return (
            <div>
                {/* <Navbar  setStyle={setStyle} style={style} sidebar={sidebar} setSidebar={(val) => {
                    if (!val && style.width) {
                        sessionStorage.setItem('prepareLogin', JSON.stringify('1'))
                    }
                    setSidebar(val)
                }} label={"Guest"}/> */}

                <Modal visible={visible}>
                    <div style={{ margin: 10 }} onClick={() => setShowBayar(false)}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                            <div onClick={() => {
                                if (localStorage.getItem('dataBranch')) {
                                    setVisible(false)
                                }
                                else {
                                    addToast("Pilih Cabang Terdekat", {
                                        appearance: 'error',
                                        autoDismiss: true,
                                        autoDismissTimeout: 1500,
                                    })
                                }
                            }}>
                                <AiOutlineCloseCircle  size={25}/>
                            </div>
                        </div>
                        <div>
                            {
                                JSON.parse(localStorage.getItem('local_branch'))?.map((items, index) => (
                                    <div onClick={ async () => {
                                        let getCurrentLoginData = {}
                                        getCurrentLoginData.branchID = items.id
                                        getCurrentLoginData.branchName = items.name
                                        getCurrentLoginData.branchAddress = items.address
                                        getCurrentLoginData.branchTel = items.mobile
                                        getCurrentLoginData.picpath = items.picpath
                                        localStorage.setItem('dataBranch', JSON.stringify( Object.assign(getCurrentLoginData, items)))
                                        setDataBranch(Object.assign(getCurrentLoginData, items))
                                        const coa_transaksi = await api.order.coa_branch({
                                            userId: ""
                                        })
                                        const local_charges = await api.apiPrepare.local_charges()
                                        localStorage.setItem("local_charges", JSON.stringify(local_charges.data?.data))

                                        localStorage.setItem("coa_transaksi", JSON.stringify(coa_transaksi.data.data[0]))
                                        dispatch(getKategory())
                                        dispatch(Produk())
                                        dispatch(Shift())    
                                        setVisible(false)
                                    }} 
                                    className='fonts700' key={index} style={{ padding: '5px 0', borderBottom: '1px solid #9c9c9c'}}>{items.name}</div>
                                ))
                            }
                        </div>
                    </div>
                </Modal>

                <ModalBayar dataOrder={dataOrder} showBayar={showBayar} setShowBayar={setShowBayar}/>
                <ModalPaket dataProduct={dataProduct} setDataProduct={setDataProduct} updateOrder= {updateOrder} dataOrder={dataOrder} showModalPaket={showModalPaket} setShowBayar={setShowBayar} showBayar={showBayar}  setShowModalPaket= {setShowModalPaket} onClickTambahPesanan={() => {
                if ((Number(dataOrder['item']) == 0)) {
                    onClickTambahPesanan()
                }
            }}/>
            <ModalBuyXFreeY setShowModalBuyXFreeYReward={setShowModalBuyXFreeYReward} showModalBuyXFreeYReward={showModalBuyXFreeYReward} dataProduct={dataProduct} setDataProduct={setDataProduct} updateOrder= {updateOrder} dataOrder={dataOrder} dataRequirementAll={dataRequirementAll} dataRewardAll={dataRewardAll} showModalBuyXFreeY={showModalBuyXFreeY} setShowBayar={setShowBayar} showBayar={showBayar}  setShowModalBuyXFreeY= {setShowModalBuyXFreeY} onClickTambahPesanan={() => {
                if ((Number(dataOrder['item']) == 0)) {
                    onClickTambahPesanan()
                }
            }}/>
             <ModalBuyXFreeYReward setShowModalBuyXFreeYReward={setShowModalBuyXFreeYReward} showModalBuyXFreeYReward={showModalBuyXFreeYReward} dataProduct={dataProduct} setDataProduct={setDataProduct} updateOrder= {updateOrder} dataOrder={dataOrder} dataRequirementAll={dataRequirementAll} dataRewardAll={dataRewardAll} showModalBuyXFreeY={showModalBuyXFreeY} setShowBayar={setShowBayar} showBayar={showBayar}  setShowModalBuyXFreeY= {setShowModalBuyXFreeY} onClickTambahPesanan={() => {
                if ((Number(dataOrder['item']) == 0)) {
                    onClickTambahPesanan()
                }
            }}/>
                <div>
                    <div >
                       { showBayar == false &&
                       
                       <div>
                        

                                    <div className='Branch' onClick={() => setShowBayar(false)}>
                                        <Container className='Merchant'>
                                            <Row className='Merchant-Logo'>
                                            
                                                {
                                                    dataBranch?.picpath?.length > 3 &&
                                                    <Col className='Logo' xs={5}>
                                                        {/* <img style={{}} src={`${url}${dataBranch?.picpath}`}></img> */}
                                                        <img style={{width:'100%'}} src={`${dataBranch?.picpath}`}></img>
                                                    </Col>
                                                }
                                                {
                                                    dataBranch?.picpath?.length < 4 && 
                                                    <Col className='Logo' xs={3}>
                                                        <Logo/>
                                                    </Col>
                                                }
                                                <Col className='Merchant-Address' xs={7}>
                                                    <div style={{fontSize:'16px',fontWeight:'700',display:'flex'}}>
                                                        {dataBranch?.name}
                                                    </div>
                                                    <div className='desc'>
                                                        {dataBranch?.address}
                                                    </div>
                                                </Col>
                                                <Col className='Icon' xs={1}>
                                                </Col>
                                            </Row>

                                            <Row className='Time' xs={2}>
                                                <Col xs={2}>
                                                    <img src={Time} style={{width:'20px', height:'20px'}}></img>
                                                </Col>
                                                <Col xs={8} style={{textAlign:'start'}}>
                                                    {
                                                        shift.min &&
                                                        <div> Buka, Pukul {shift.min} - {shift.max} WIB</div>
                                                    }
                                                </Col>
                                            </Row>

                                            <Row className='Time' href='tel:number'>
                                                <Col xs={2}>
                                                    <a href={`https://wa.me/${dataBranch?.phone1}`}><img src={Phone} style={{width:'20px', height:'20px'}}></img></a>
                                                    
                                                </Col>
                                                <Col style={{textAlign:'start'}}>
                                                    {
                                                        dataBranch?.phone1 && 
                                                        <a style={{color:'black'}} href={`https://wa.me/${dataBranch?.phone1}`}>{dataBranch?.phone1}</a>
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                    
                        </div>
                        
                        
                        }

                        <Container className='Body-3' fluid>
                            <Gap height={30}/>
                            <Container className='Slider'>
                                <Row xs={3}>
                                

                                    <Col md={12} xs={12}>
                                        <div className='List'>
                                        {
                                            state?.kategory?.map((items, index) => (
                                                <a style={{color: items['selection'] ? "#04295E" : 'black'}} className='List-Item' onClick={() => onClickCategory(items)} key={index}>{items.label}</a>
                                            ))
                                        }  
                                        </div>
                                    </Col>

                                    {/* <Col md={1} xs={2}>
                                        <div className='Search'>
                                            <div className='button' onClick={handlePress} style={{cursor:'pointer'}}>Cari</div>
                                            {
                                                isVisible &&
                                                    <div className="slide-up-screen">
                                                        <div className="slide-up-content">
                                                            <h2>Screen content</h2>
                                                            <button onClick={handleClose}>Close</button>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Col> */}
                                </Row>

                                <div className='horizontal-divider-thick'></div>

                            </Container>

                            <Container className='Menu-Contain-Scroll' style={{paddingBottom:'50px'}} onClick={() => {}}>
                                {
                                    dataProduct?.map((items, index) => {
                                        // console.log(items)
                                        var imageUrl = (url) + items.picpath1;
                                        imageExists(imageUrl, function(exists) {
                                            if (exists == true) {
                                                items["show_img"] = true
                                            }else{
                                                items["show_img"] = false
                                            }
                                        });
                                        return (
                                            
                                            <div onClick={ () => 
                                            {
                                                if (items.name != null && items.name !== 'undefined') {
                                                    setShowBayar(true)
                                                    onOrderPaket(items)
                                                }
                                                else {
                                                    if(items.id && items.beli && items.dapat){
                                                        onOrderPromoBuyXFreeY(items)
                                                    }
                                                    else{
                                                        onOrder(items)

                                                    }
                                                }
                                            }
                                            
                                            } key={index} style={{ display: 'flex', alignItems: 'center', borderRadius: 5, margin: 10,cursor:'pointer' }}>
                                                
                                            <div style={{ position: 'relative'}}>
                                                
                                                {
                                                            items['picpath1']!== '-'&& items['picpath1'] !== null?
                                                            // <img style={{ height: 100, width: 100, borderRadius: 5, marginBottom: 10}} src={(items['base_url'] !== "" ? items['base_url'] : url) + items['picpath1']}/> 
                                                    <img style={{ height: 100, width: 100, borderRadius: 5, marginBottom: 10}} src={items.base_url === ''? url + items['picpath1'] : items.base_url + items.picpath1}/> 
                                                                                                        
                                                                                                        :

                                                    <div style={{ 
                                                        height: 100, 
                                                        width: 100, 
                                                        borderRadius: 5, 
                                                        marginBottom: 10, 
                                                        display: 'flex', 
                                                        justifyContent: 'center', 
                                                        alignItems: 'center'
                                                    }}>
                                                        <img src={ImageNotFound} />
                                                    </div>
                                                }
                                                {
                                                    
                                                    items['item'] !== null && items['item'] > 0 &&
                                                    <div 
                                                        style={{
                                                            position: 'absolute', 
                                                            bottom: 14, 
                                                            right: 0, 
                                                            width: 20, 
                                                            backgroundColor: '#fff', 
                                                            textAlign: 'center', 
                                                            fontWeight: 'bold', 
                                                            borderTopLeftRadius: 5, 
                                                            borderBottomRightRadius: 5, 
                                                            border: '1px solid #BFBFBF'
                                                        }}>
                                                        {items['item']}
                                                    </div>
                                                }
                                            </div>
                                            
                                            <div style={{ margin: '0 10px 0 10px' }}>
                                                <div className="fonts700">
                                                    {items['product_name'] || items['name'] || items.id}
                                                </div>
                                                
                                                <span style={{ marginRight: 10, fontSize:'13px'}}>
                                                    {items.description_menu}
                                                </span>
                                                {
                                                showCategoryBuyXFreeY === true && (
                                                    <div style={{ display: 'flex',width:'100%' }}>
                                                    <div style={{ flex: 1 ,width:'50%'}}>
                                                        <div className="fonts500">
                                                        <div>
                                                        {items?.dataMaster?.[0]?.tipe_requirement !== 'or' ? 'Beli(Semua)' : `Beli(Pilih ${items?.beli})`}
                                                        </div>
                                                        {items?.detailProducts?.map((item, index) => {
                                                            if (item.sebagai === 'Requirement') {
                                                            return (
                                                                <ul className={boxClassSubMenu.join(' ')} > 
                                                                <li activeClassName='is-active' style={{color:'grey',fontSize:'12px'}}>{item.name}</li>
                                                                </ul>
                                                          
                                                            );
                                                            }
                                                            return null;
                                                        })}
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 ,width:'50%'}}>
                                                        <div className="fonts500">
                                                        <div>{items?.dataMaster?.[0]?.tipe_reward !== 'or'? 'Dapat(Semua)':'Dapat(Pilih)'}</div>
                                                        {items?.detailProducts?.map((item, index) => {
                                                            if (item.sebagai === 'Reward') {
                                                            return (
                                                                <ul className={boxClassSubMenu.join(' ')} > 
                                                                <li activeClassName='is-active' style={{color:'grey',fontSize:'12px'}}>{item.name}</li>
                                                                </ul>
                                                            );
                                                            }
                                                            return null;
                                                        })}
                                                        </div>
                                                    </div>
                                                    </div>
                                                )
                                                }




                                                <br/>
                                                {
                                                    showCategoryBuyXFreeY === false &&
                                                <span className="fonts700" style={{ marginRight: 10}}>
                                                {'Rp '+formatterMoney(items['usePrice'] || items['harga'])}
                                                </span>
                                                }
                                               
                                                <Gap height={20}/>

                                            </div>
                                        </div>
                                            // <a className='Menu-Contain' onClick={ () => onOrder(items)} key={index}>
                                            //     <Row>
                                            //         <Col md={2} xs={4} className='Contain-Img' style={{position:'relative'}}>
                                            //             {
                                            //                 items['picpath1']!== '-'&& items['picpath1'] !== null?
                                            //                 <img style={{ height: 100, width: 100, borderRadius: 5, marginBottom: 10}} src={items.base_url === ''? url + items['picpath1'] : items.base_url + items.picpath1}/> :
                                            //                 <div style={{ 
                                            //                     height: 120, 
                                            //                     width: 120,
                                            //                     margin:'auto',
                                            //                     display: 'flex',
                                            //                     textAlign:'center',
                                            //                     alignItems:'center',
                                            //                     justifyContent:'center'
                                            //                 }}>
                                            //                     <Logo/>
                                            //                 </div>
                                            //             }
                                            //             {
                                            //                 items['item'] !== null && items['item'] > 0 &&
                                            //                 <div 
                                            //                     style={{
                                            //                         position: 'absolute', 
                                            //                         bottom: 14, 
                                            //                         right: 0, 
                                            //                         width: 20, 
                                            //                         backgroundColor: '#fff', 
                                            //                         textAlign: 'center', 
                                            //                         fontWeight: 'bold', 
                                            //                         borderTopLeftRadius: 5, 
                                            //                         borderBottomRightRadius: 5, 
                                            //                         border: '1px solid #BFBFBF'
                                            //                     }}>
                                            //                     {items['item']}
                                            //                 </div>
                                            //             }
                                            //         </Col>

                                            //         <Col md={10} xs={8} className='Contain-Desc'>
                                            //             <div className="fonts700" style={{fontSize:'14px'}}>
                                            //                 {items['product_name']}
                                            //             </div>
                                            //             <div className="desc" style={{fontSize:'12px',fontWeight:100,color:'#7E7E7E'}}>
                                            //                 {(items.description_menu)}
                                            //             </div>
                                            //             <div className='Desc-price' style={{fontSize:'14px'}}>
                                            //                 {'Rp '+formatterMoney(items['usePrice'])}
                                            //             </div>
                                            //         </Col>
                                            //     </Row>
                                            //     <Gap height={20}/>

                                            // </a>
                                        )
                                    })
                                }
                                {
                                    produk.loading && 
                                    <Loading />
                                }
                                {
                                    !produk.loading && 
                                    <Gap height={1} />
                                }
                            </Container>
                        </Container>
                        <TabBottom sidebar={sidebar} setSidebar={(val) => {
                    if (!val && style.width) {
                        sessionStorage.setItem('prepareLogin', JSON.stringify('1'))
                    }
                    setSidebar(val)}}
                    />
                    </div>
                </div>
             
            </div>
        )
    }