import FETCH_API from "./fetch_api";
import { formatDate } from "../utils/formatDate";
import { baseUrl,router } from "../Constants";
export async function send_email(params) {
    let users = {}
    if (localStorage.getItem('login')) {
        users.nama = JSON.parse(localStorage.getItem('login')).nama
    }
    else {
        users.nama = JSON.parse(localStorage.getItem('orderDate')).nama
        users.email = JSON.parse(localStorage.getItem('orderDate')).email
    }
    try {
        const result = await FETCH_API('send_email_web/afterPayment', {
            "subjectnya": params.subject,
            "namaPembeli": users.nama,
            "carabayar"  : params.carabayar,
            "idTransaksi" : params.idTransaksi,
            "tglTransaksi": formatDate(new Date),
            "subTotal" : params.subTotal,
            "grandTotal" : params.grandTotal,
            "linkLihatDetail" : `${baseUrl}${router}#/home/lacak-pesanan`,
            "email" : users.email,
            "detailData" : params.detailData
        }, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImlyZmFuIiwiaWF0IjoxNjI1MjA0NTQ2LCJleHAiOjE2MjUzODA5NDZ9.pd_86GRYqBZzAhccYtXkydh-dlXesj2B6Ec5HexO4f4')
    } catch (error) {
        console.log(error)
    }
}

export async function proses_data() {
    console.log("masuk sini")
    const userServer = JSON.parse(localStorage.getItem("userServer")) 
    const id = JSON.parse(localStorage.getItem("idpesanan"))

    let users = {}
    if (localStorage.getItem('login')) {
        users.nama = JSON.parse(localStorage.getItem('login')).nama
    }
    else {
        users.nama = JSON.parse(localStorage.getItem('orderDate')).nama
    }

    const process = await FETCH_API('process-web', {
        "from_origin": "*",
        "userServer" : userServer,
        "transCode": "so",
        "transId": id,
        "userId": "-"
    })
    console.log("masuk sini", process)
    return process
}