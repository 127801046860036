import baseRequest  from "../utils/request"

export default class Prepare extends baseRequest {
    constructor() {
        super();
        this.urls = {
            getChargesNote: "trans-s-so-web/getChargesNote",
            getKategory: "products-web-v3/get-products-category",
            produk: "products-web-v3",
            get1Produk: "products-v7/get1WithoutToken",
            shift: "products-web/get-shift",
            getOrder: 'trans-s-so-web/get',
            local_charges: "products-web/get-charges",
            void: 'void/v3',
            getPaket : 'promos/getWeb',
            getBuyXGetY : 'promotion/web/getAll'

        }
    }
    getChargesNote = params => this.post(this.urls.getChargesNote, params)
    getKategory = params => this.post(this.urls.getKategory, params)
    produk = params => this.post(this.urls.produk, params)
    get1Produk = params => this.post(this.urls.get1Produk, params)
    getPaket = params => this.post(this.urls.getPaket, params)
    getBuyXGetY = params => this.post(this.urls.getBuyXGetY, params)

    shift = params => this.post(this.urls.shift, params)
    getOrder = params => this.post(this.urls.getOrder, params)
    local_charges = params => this.post(this.urls.local_charges, params)
    void = params => this.post(this.urls.void, params)

}