import React from 'react'
import CopyRight from '../../Assets/CopyRight'
import Responsif from '../../utils/Responsif'
import firebase from '../../firebase'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged} from "firebase/auth";
import { getFirestore, doc, getDoc} from "firebase/firestore";
import Input from '../../components/Input'
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom'
import {BiArrowBack} from 'react-icons/bi'
import InputPhone from '../../components/InputPhone';
import Button from '../../components/Button';
import Gap from "../../components/Gap"
import { useToasts } from 'react-toast-notifications'
import { router } from "../../Constants"

function Home() {
	const { addToast } = useToasts()
	const navigate = useNavigate()
	const [input, setInput] = React.useState({
		mobile : '',
		otp : ''
	})
	const [captchaCalled, setCaptchaCalled] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [timeOut, setTimeOut] = React.useState('Send OTP')
	
	function configureCaptcha(){
		const auth = getAuth();
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response) => {
			// reCAPTCHA solved, allow signInWithPhoneNumber.
			setCaptchaCalled(true)
			onSignInSubmit();
		}
	}, auth)}


	function startTimer(duration) {
		let timer = duration, minutes, seconds;
		let myVar = setInterval(function () {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);
	
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
	
			let date = seconds;
			if (date == '00') {
				clearInterval(myVar);
				setTimeOut('Send OTP')
				localStorage.removeItem("timeout");
			}
			else {
				setTimeOut(date)
				localStorage.setItem('timeout', parseInt(timer))
			}
			if (--timer < 0) {
				timer = duration;
			}
		}, 1000);
	}


	React.useEffect(() => {
		const xi = localStorage.getItem('timeout')
		if (xi) {
			startTimer(Number(xi))
		}
	},[])


	async function onSignIn(e) {
		setLoading(true)
		const phoneNumber = "+62"+input.mobile.replace(/ /g,'');
		const db      = getFirestore();
		const docRef  = doc(db, "users", phoneNumber);
		const docSnap = await getDoc(docRef);
		if(!input.mobile) {
			setLoading(false)
			addToast("No Telepon harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })
            return
        }

		if(docSnap.data()) {
			if(captchaCalled === false) {
				configureCaptcha()
			}
			// const phoneNumber = '+62'+input.mobile.replace(/\s/g, '');
			const appVerifier = window.recaptchaVerifier;
			const auth = getAuth();
			signInWithPhoneNumber(auth, phoneNumber, appVerifier)
			.then((confirmationResult) => {
				window.confirmationResult = confirmationResult;
				navigate(`/${router}kode-otp`)
				startTimer(15)
				setLoading(false)
				localStorage.setItem('phoneNumberLogin', phoneNumber)
			}).catch((error) => {
				setLoading(false)
				if(error.code == 'auth/invalid-phone-number') {
					addToast("Nomor telepon yang dimasukkan tidak valid.", {
						appearance: 'error',
						autoDismiss: true,
					})
				} 
				if(error.code == 'auth/too-many-requests') {
					addToast("Nomor telepon anda terlalu banyak melakukan percobaan, tunggulah berapa menit lagi", {
						appearance: 'error',
						autoDismiss: true,
					})
				} 
			});
		} else {
			setLoading(false)
			addToast("Nomor Telepon anda tidak terdaftar, mohon untuk mendaftar terlebih dahulu", {
				appearance: 'error',
				autoDismiss: true,
			})
		}
	}

	function onSignInSubmit(e){
		e.preventDefault()
		onSignIn()
	}
	
	// function onSubmitOTP(e){
	// 	e.preventDefault()
	// 	onOtp()
	// }
	const { width } = Responsif()
    return(

		<div>
			<div>
				<div style={{marginTop:10, marginLeft: 5, padding:10 }} onClick={()=>{navigate(`/${router}home`)}}>
					<BiArrowBack size={20}/>
				</div>
			</div>
			<div style={{ margin: '20% 10px 10px 10px' }}>
				<div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', padding: '30px 0px' }}>
					<div style={{ fontSize: 25}} className="fonts700">HI, Selamat Datang</div>
				</div>
				<InputPhone type="input" value={input.mobile} label="Nomer Handphone" placeholder="8123456789" onChange = {
					(val) => {
						setInput((prevState) => ({
							...prevState,
							mobile : val
						}));
					}
				}/>
				<Gap height={20} />
				<Button onClick={(e) => {
                    if (!loading) {
						if (timeOut == "Send OTP") {
							onSignInSubmit(e)
						}
                    }
                }} loading={loading} color="#fff" backgroundColor={timeOut == "Send OTP" ? "#5451D6" : '#b5b5b5'}>
					{timeOut}
				</Button>
			</div>	

			<div style={{ marginTop: '70%', textAlign: 'center'}}>
                <CopyRight />
            </div>
			<div id="sign-in-button"></div>
      </div>
    )
}

export default Home
