import React from "react";


export default function Line({backgroundColor}) {
    return(
    <svg width="2" height="68" viewBox="0 0 2 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 68L1 0" stroke={backgroundColor}/>
    </svg>

    )
}