import {url} from '../Constants.js'
async function FETCH_API(api, body, token, signal) {
    const userServer = JSON.parse(localStorage.getItem("userServer"))  
    body['from_origin'] = "*"
    body['userServer'] = userServer
    console.log("=================")
    console.log(url + api)
    console.log(body)
    console.log("=================")
    try {      
        const data = await fetch(url + api, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token ? token : '',
                'Cache-Control': 'no-cache',
                'Accept-Encoding':'gzip;q=1.0, compress;q=0.5',
            },
            body: JSON.stringify(body)
        }).catch((error) => {
            if (error.name == 'AbortError') {
                console.log('request was cancelled');
            }
            let res = {}
            res['status']  = "ERROR"
            res['message'] = "Terjadi kesalahan pada system."
            return res;
        })
        .then((res) => res.json())
        .then((res) => res)
        .then((err) => err)
    
        return data
    } catch (error) {
        console.log("FETCH API", error)
    }
}

export default FETCH_API