import React, { useEffect ,useState} from 'react'
import './ModalBayar.css'
import { AiOutlinePercentage } from "react-icons/ai";
import { BiPlus , BiMinus, BiX} from "react-icons/bi";
import formatterMoney from '../../utils/formatMoney'
import { encrypt, decrypt } from "../../utils/enkripsi"
import { url } from "../../Constants"
import ImageNotFound from "../../Assets/default-img.jpg"
import { useDispatch, useSelector } from "react-redux"
import { setKeranjang } from "../../redux/actions/prepareActions"
import Gap from "../../components/Gap"
import Logo from "../../Assets/Logo"
import { useToasts } from 'react-toast-notifications';
import api from '../../request';
import moment from 'moment';

export default function ModalBuyXFreeYReward({showModalBuyXFreeY,setShowModalBuyXFreeY,showModalBuyXFreeYReward, setShowModalBuyXFreeYReward, onClickTambahPesanan, dataOrder ,setShowBayar,showBayar,dataProduct,setDataProduct,dataRequirementAll,dataRewardAll}) {
    const dispatch = useDispatch()
    const { addToast } = useToasts()
    const state = useSelector(state => state?.dataPrepare)
    const [quantity, setQuantity] = React.useState(1)
    const [quantity2, setQuantity2] = React.useState(0)
    const [note, setNote] = React.useState('')
    const [products, setProducts] = React.useState('')
    const [products2, setProducts2] = React.useState('')
    const [products3, setProducts3] = React.useState('')
    const [statusClick, setStatusClick] = React.useState(0)
    const [harga, setHarga] = React.useState(0)
    const [selectAll, setSelectAll] = useState(true);

    let pesanan = localStorage.getItem('pesanan')

  
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsAll, setSelectedProductsAll] = useState([]);

    const maxTotalQuantity = dataOrder.dapat; // Replace with your desired maximum allowed total quantity

    const calculateTotalQuantity = () => {
      let totalQuantity = 0;
      selectedProducts.forEach(product => {
        totalQuantity += product.quantity || 0;
      });
      return totalQuantity;
    };

    const isTotalQuantityValid = () => {
      const totalQuantity = calculateTotalQuantity();
      return totalQuantity <= maxTotalQuantity;
    };
// Returns the quantity of a selected product by product name
const getSelectedProductQuantity = (productName) => {
  const selectedProduct = selectedProducts.find(product => product.product_name === productName);
  return selectedProduct ? selectedProduct.quantity || '' : '';
};

// Updates the quantity of a selected product by product name
const handleQuantityChange = (productName, newQuantity) => {
  const parsedQuantity = parseInt(newQuantity, 10) || 0;

  const updatedSelectedProducts = selectedProducts.map(product => {
    if (product.product_name === productName) {
      return { ...product, quantity: parsedQuantity };
    }
    return product;
  });

  setSelectedProducts(updatedSelectedProducts);

  const newTotalQuantity = calculateTotalQuantity();
  const mergeQuantity = newTotalQuantity + parsedQuantity
  if (mergeQuantity > maxTotalQuantity) {
    setSelectedProducts(updatedSelectedProducts.map(product => {
      if (product.product_name === productName) {
        return { ...product, quantity: undefined };
      }
      return product;
    }));

    addToast(`Tidak bisa lebih dari ${dataOrder.beli} produk`, {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: 1500,
    });
  }
};



const handleCheckboxChange = async (items) => {
  if (selectedProducts.some(product => product.product_name === items.product_name)) {
    setSelectedProducts(prevProducts => prevProducts.filter(product => product.product_name !== items.product_name));
  } else {
    if (selectedProducts.length < dataOrder.beli) {
      setSelectedProducts(prevProducts => [...prevProducts, items]);
      const response = await api.order.soldOut({
        "product_id": items.product_id,
        "today": moment().format('YYYY-MM-DD')
      });
      const res = response.data;
      if (res.sold_out) {
        addToast("Barang sudah soldout", {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 1500,
        });
      }
    } else {
      // Limit reached, show a message or perform an action
      addToast(`Tidak bisa lebih dari ${dataOrder.beli} produk.`, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    }
  }
};
    
    
    
    
    
    
    let datapaket = []
    

 

      useEffect(() => {
        if (dataOrder?.dataMaster?.[0]?.tipe_reward === 'and') {
          setSelectAll(true)
        } else {
          setSelectAll(false)
        }
        }, [dataOrder]);
      useEffect(() => {
        // Initialize selectedProductIds with the first product ID from each category
        let a = []
        let b = 0
        if(selectAll === true ){
          for(let i=0;i<dataRewardAll.length;i++){
              if(dataRewardAll[i].quantity){
                b+=(dataRewardAll[i].price - (dataRewardAll[i].price * dataRewardAll[i].discount/100)) * dataRewardAll[i].quantity
              }
              else{
                b+=(dataRewardAll[i].price - (dataRewardAll[i].price * dataRewardAll[i].discount/100) )* dataRewardAll[i].qty
              }
            a.push(dataRewardAll[i])
          }
          setHarga(b)
          setSelectedProductsAll(a)
          // handleCheckboxChange(dataRewardAll[0])
        }
        else{
          if(selectedProducts){
            for(let i=0;i<selectedProducts.length;i++){
              if(selectedProducts[i].quantity){
                b+=(selectedProducts[i].price - (selectedProducts[i].price * selectedProducts[i].discount /100)) * selectedProducts[i].quantity
              }
              else{
                // b+=selectedProducts[i].price - (selectedProducts[i].price * selectedProducts[i].discount /100)
              }
            }
            setHarga(b)
          }
        }
       
      }, [selectedProducts,selectAll,dataRewardAll]);
      // useEffect(() => {
      //   // Initialize selectedProductIds with the first product ID from each category

      //   let a = []
      //   let b = 0
      //   console.log(selectedProducts)

      //   if(selectedProducts){
      //     for(let i=0;i<selectedProducts.length;i++){
      //       b+=(selectedProducts[i].price - (selectedProducts[i].price * selectedProducts[i].discount /100)) * selectedProducts[i].qty
      //       console.log(selectedProducts[i].qty)
      //     }
      //     console.log(b)

      //     setHarga(b)
      //   }
      // }, [selectedProducts]);
      const [requirement,setRequirement] = React.useState([])
      const [reward,setReward] = React.useState([])


    const [selectedValues, setSelectedValues] = React.useState({});

    pesanan = pesanan == null ? [] : decrypt(JSON.parse(pesanan))
    useEffect(() => {
        if (showModalBuyXFreeYReward) {            
            let pesanan1 = decrypt(JSON.parse(localStorage.getItem('pesanan')))
            setNote('')
            if (pesanan1) {                
                if (pesanan1.length !== 0) { 
                    let number = 1        
                    let price =  0 
                    for(const x of pesanan1){
                        if (dataOrder['name'] == x['name']) {
                            number = x['qty']
                            price = x['item']
                            setNote(x['note'])
                        }
                    }
                    setQuantity(number) 
                    setQuantity2(price)             
                }
            }
        }
    },[showModalBuyXFreeYReward])

    function imageExists(url, callback) {
        var img = new Image();
        img.onload = function() { callback(true); };
        img.onerror = function() { callback(false); };
        img.src = url;
    }

    var imageUrl = url+dataOrder['picpath1'];
    imageExists(imageUrl, function(exists) {
        if (exists == true) {
            dataOrder["show_img"] = true
        }else{
            dataOrder["show_img"] = false
        }
    });



let category = []


for(let data in dataOrder.category) {
    category.push(dataOrder.category[data].category_name)
}
    


    return (
        <div className={showModalBuyXFreeYReward ? 'modal-menu active' : 'modal-menu' }>
            {/* <div onClick={() => {setShowModalBuyXFreeYReward(false);setShowBayar(false);setSelectedProducts([])}} 
                style={{  
                    margin: '10px 0px 0 10px',
                    position: 'fixed',
                    borderRadius: 40,
                    height: 40, 
                    width: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)'
                }} >
                <BiX size={30} color="#fff"/>
            </div> */}
            
            {
                dataOrder['show_img'] && (
                    
                    <>
                    {
                        dataOrder['picpath1_thumb'] !== '-' && dataOrder['picpath1_thumb'] ?
                        <img style={{ 
                                // height: '40%', 
                                width: '100%', 
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20, 
                                marginBottom: 10,
                                }} src={url+dataOrder['picpath1_thumb']}/>
                        :
                        
                        <img style={{ 
                             // height: '40%', 
                             width: '100%', 
                             borderTopLeftRadius: 20,
                             borderTopRightRadius: 20, 
                             marginBottom: 10,
                             }} src={ImageNotFound}/>
                    }
                    </>
                )   
            }

            {
                !dataOrder['show_img'] && <div style={{
                    width: '100%', 
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20, 
                    marginBottom: 10,
                    height: "40%",
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    
                    <Logo height='50' width='200'/>
                </div>
            }
            
            <div style={{ width: '100%' }}>
                <div style={{ margin: 20}}>
            <div className="fonts700" style={{ fontSize: 16, paddingTop: 5}}>{dataOrder?.['id']}</div>
            <div style={{ fontSize: 14, paddingTop: 5, color: "#BFBFBF"}}>{dataOrder?.['description']}</div>
            {/* {
              dataRewardAll?.map((items, index) => (
                <div style={{ fontSize: 16, paddingTop: 20 }} key={index}>
                  <div className="row" style={{ paddingTop: 5, fontSize: 14 }} key={items.product_id}>
                    <div className="col-sm-12">
                      <form>
                      <div className="checkbox" style={{display:'flex'}}>
                        <div style={{flex:1}}>
                        <label>
                          <input
                            type="checkbox"
                            value={items.product_name}
                            onChange={() => handleCheckboxChange(items)}
                            checked={selectedProducts.some(product => product.product_name === items.product_name)}
                          />
                          <span style={{ paddingLeft: 10 }}>{items.product_name}</span>
                        </label>
                        </div>
                        <div className="fonts700" style={{flex:1,color: "#162A48", paddingTop: 2}}>{'Rp ' +formatterMoney(items['usePrice']-(items['usePrice']*items.discount/100))}</div>
                      </div>
                      </form>
                    </div>
                  </div>
                </div>
              ))
            } */}
            {dataRewardAll?.map((items, index) => {
              return(
            <div style={{ fontSize: 16, paddingTop: 20 }} key={index}>
              <div className="row" style={{ paddingTop: 5, fontSize: 14 }} key={items.product_id}>
                <div className="col-sm-12">
                  <form>
                    <div className="checkbox" style={{ display: 'flex' }}>
                      <div style={{ flex: 1 }}>
                        <label>
                          <input
                            type="checkbox"
                            value={items.product_name}
                            onChange={() => handleCheckboxChange(items)}
                            checked={selectAll || selectedProducts.some(product => product.product_name === items.product_name)}
                          />
                          <span style={{ paddingLeft: 10 }}>{items.product_name}</span>
                        </label>
                      </div>
                      
                      <div className="fonts700" style={{flex:1,color: "#162A48", paddingTop: 2}}>{'Rp ' +formatterMoney(items['usePrice']-(items['usePrice']*items.discount/100))}</div>
                      {
                        dataOrder?.dataMaster?.[0]?.tipe_reward === 'or'&&
                        <div style={{ flex: 1 }}>
                        <label>
                          Qty:
                          <input
                            type="number"
                            value={getSelectedProductQuantity(items.product_name)}
                            onChange={event => handleQuantityChange(items.product_name, event.target.value,items)}
                            disabled={!selectedProducts.some(product => product.product_name === items.product_name)}
                          />
                        </label>
                      </div>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )})}



                    <div style={{ display: 'flex', paddingTop: 100 }}>
                        <div style={{ display: 'flex', width: '40%', backgroundColor: '#fff', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="fonts700" style={{color: "#162A48", paddingTop: 2}}>{'Rp ' +formatterMoney(harga)}</div>
                        </div>
                        <div style={{ width: '140%', display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={async() => {
                          if(dataOrder?.dataMaster?.[0]?.tipe_requirement === 'and'){
                            var a = 0
                            for(let i=0;i<selectedProductsAll.length;i++){
                              a += selectedProductsAll[i]?.qty !== undefined ? selectedProductsAll[i]?.qty : 0
                            }
                          }
                          else{
                            var a = 0
                            var b = 0
                            for(let i=0;i<selectedProducts.length;i++){
                              a += selectedProducts[i]?.quantity !== undefined ? selectedProducts[i]?.quantity : 0
                            }
                            // console.log(b)
                            // for(let j=0;j<b;j++){
                            //   a += selectedProducts[j]?.quantity !== undefined ? selectedProducts[j]?.quantity : 0
                            // }
                          }
                          if (a === dataOrder.dapat && a !== 0) {
                          let name = []
                          for(const xi of pesanan){
                            for(const item of selectedProducts){
                              if (xi['product_id'] == item['product_id']) {
                                  name.push(xi['product_id'])
                              }
                            }
                          }
                          if(selectAll === true){
                              for(const item of selectedProductsAll){
                                pesanan.push(Object.assign(item, {
                                  item: item.qty ? item.qty : 1,
                                  total: item.qty ?(item['usePrice'] - (item['usePrice']*item.discount/100))* item.qty : (item['usePrice'] - (item['usePrice']*item.discount/100))* quantity,
                                  promo_name: dataOrder.id,
                                  note: note
                              }))
                            }
                            }
                            else{
                              for(const item of selectedProducts){
                                if(item.quantity){
                                  pesanan.push(Object.assign(item, {
                                    item: item.quantity ? item.quantity : 1,
                                    total: item.quantity ?(item['usePrice'] - (item['usePrice']*item.discount/100))* item.quantity : (item['usePrice'] - (item['usePrice']*item.discount/100))* quantity,
                                    promo_name: dataOrder.id,
                                    note: note
                                }))
                                }
                            }
                          }
                       
                        const resultPesanan = pesanan.filter(items => items['item'].toString() !== '0')
                          localStorage.setItem('pesanan', JSON.stringify(encrypt(resultPesanan)))
                          dispatch(setKeranjang())
                              addToast("Pesanan sudah ditambah", {
                                  appearance: 'success',
                                  autoDismiss: true,
                                  autoDismissTimeout: 1500,
                              })
                          setShowModalBuyXFreeYReward(false)
                          setShowModalBuyXFreeY(false)
                          }
                          else{
                            addToast(`Silahkan pilih produk`, {
                              appearance: 'error',
                              autoDismiss: true,
                              autoDismissTimeout: 1500,
                            });
                          }

                          // if (name.includes(dataOrder['product_id'])) {
                          //     for(const resultOrder of pesanan){
                          //         if (resultOrder['product_id'] == dataOrder['product_id']) {
                          //             resultOrder['item'] = quantity 
                          //             resultOrder['total'] = dataOrder['usePrice'] * quantity
                          //         }
                          //     }
                          // }
                          // else {
                          //     pesanan.push(Object.assign(dataOrder, {
                          //         item: quantity,
                          //         total: dataOrder['usePrice'] * quantity,
                          //         note: note
                          //     }))
                          // }
                          // const resultPesanan = pesanan.filter(items => items['item'].toString() !== '0')
                          // localStorage.setItem('pesanan', JSON.stringify(encrypt(resultPesanan)))
                          // dispatch(setKeranjang())
                          // if (quantity > quantity2) {
                          //     addToast("Pesanan sudah ditambah", {
                          //         appearance: 'success',
                          //         autoDismiss: true,
                          //         autoDismissTimeout: 1500,
                          //     })
                          // }
                          // setIsLoading(false);
                          // setShowBayar(false)
                          }} 
                            
                            style={{ padding: '7px 0 7px 0',  borderRadius: 5, backgroundColor: '#552CB7', width: '100%',border: 'none', marginLeft: '30px'}}>
                                <span style={{ color: '#fff'}} className="fonts500">Tambah Pesanan</span>
                            </button>
                         </div>
                        </div>
                        
                     </div>
                    </div>
                
            </div>
        
    )
}