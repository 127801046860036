import { Container, Row, Col } from 'react-bootstrap';
import React from "react";
import { Link, useNavigate } from 'react-router-dom'
import { router } from "../Constants"
import { BiBasket, BiHome, BiFoodMenu } from "react-icons/bi"
import { IoCartOutline, IoCartSharp, IoPersonCircle, IoPersonCircleOutline } from "react-icons/io5"
import { FaUser, FaRegUser } from "react-icons/fa"
import { RiHome3Fill, RiHome3Line, RiFileList2Fill, RiFileList2Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import Gap from "../components/Gap"
import Button from "../components/Button"
import { getAuth, signOut } from "firebase/auth";
import CopyRight from '../Assets/CopyRight'
import { AiOutlineCloseCircle } from "react-icons/ai"


export default function TabBottom({ children,sidebar,setSidebar,style = {},}) {
    let navigate = useNavigate()
    const [loading, setLoading] = React.useState(false)

    const state = useSelector(state => state?.dataPrepare?.order)   
    const [url, setUrl] = React.useState()
    const state1 = useSelector(state => state?.dataPrepare?.keranjang)   
    // React.useEffect(() => {
    //     navigate.listen( location =>  {
    //         setUrl(location?.pathname)
    //    });
    //    if (!url) {
    //        setUrl(window.location.pathname)
    //    }

    // },[])
    const dataTab = [
        {
            label: "Menu",
            icons:  <BiFoodMenu size={20} color="#808080"/>,
            icons_active: <BiFoodMenu  size={20} color="#04295E"/>,
            url: `/${router}home`
        },
        {
            label: "Order",
            icons:  <IoCartOutline size={20} color="#808080"/>,
            icons_active: state1 === 0 ? <IoCartOutline size={20} color="#808080"/>:<IoCartOutline  size={20} color="#04295E"/>,
            url: state1 === 0 ? `/${router}home`: `/${router}detail-order`
        },
        {
            label: "Transaksi",
            icons:  <RiFileList2Line size={20} color="#808080"/>,
            icons_active: <RiFileList2Fill  size={20} color="#04295E"/>,
            url: `/${router}home/lacak-pesanan`
        },
    ]
    function logOut(){
        setLoading(true)
        const auth = getAuth();
        signOut(auth).then(() => {
            navigate( "/" + router + 'home')
            localStorage.removeItem("login");
            setLoading(false)
            setSidebar(false)
        }).catch((error) => {
            console.log(error)
        });
    }
    return (
        <div>
            {children}
            <div className="jumlah-order">
                <div style={{ margin: 10, width: '100%', borderRadius: 5, display: 'flex', justifyContent: 'space-around'}}>
                    {
                        dataTab?.map((items, index) => {
                            return(
                            <Link key={index} to={items.url} style={{ textDecoration: 'none', flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                {
                                    url !== items.url ? (<>
                                        {items.icons}
                                    </>
                                    )
                                    : (
                                    <>
                                        {items.icons_active}
                                    </>
                                    )
                                }
                                <div style={{ fontSize: 10, color: url !== items.url ? '#808080' : '#04295E'}}>{items.label}</div>
                                {
                                    items.label == "Order" && <>
                                        {
                                            state1 !== 0 &&
                                            
                                            <div style={{ width: '20%', height: '100%', position: 'absolute', display: 'flex', justifyContent: 'flex-end'}}>
                                                <div style={{ height: 17, width: 17, backgroundColor: 'red', borderRadius: 19, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <span style={{ color: '#fff', fontSize: state1 < 10 ? 10 : 9, fontWeight: 'bold' }}>{ state1 < 99 ? state1 : '+99'}</span>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                                {
                                    items.label == "Transaksi" && <>
                                        {
                                            state.length !== 0 &&
                                            <div style={{ width: '20%', height: '100%', position: 'absolute', display: 'flex', justifyContent: 'flex-end'}}>
                                                <div style={{ height: 17, width: 17, backgroundColor: 'red', borderRadius: 19, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <span style={{ color: '#fff', fontSize: state.length < 10 ? 10 : 9, fontWeight: 'bold' }}>{ state.length < 99 ? state.length : '+99'}</span>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                                
                            </Link>
                        )
                    })
                    
                    }
<div  onClick={()=>{setSidebar(true)}} style={{ textDecoration: 'none', flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                
                                  <>{<IoPersonCircleOutline size={20} color="#808080"/>}</>
    
                                <div style={{ fontSize: 10, color: sidebar === false ? '#808080' : '#5451D6'}}>{'Akun'}</div>
                              

                                
                            </div>                
                            </div>
            </div>
            {/* <div className="jumlah-order">
                <div style={{ margin: 10, width: '100%', borderRadius: 5, display: 'flex', justifyContent: 'space-around'}}>
                    {
                        dataTab?.map((items, index) => (
                            <Link key={index} to={items.url} style={{ textDecoration: 'none', flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                                {
                                    url !== items.url ? (<>
                                        {items.icons}
                                    </>
                                    )
                                    : (
                                    <>
                                        {items.icons_active}
                                    </>
                                    )
                                }

                                <div style={{ fontSize: 10, color: url !== items.url ? '#808080' : '#5451D6'}}>{items.label}</div>
                                
                                {
                                    items.label == "Transaksi" && <>
                                        {
                                            state.length !== 0 &&
                                            <div style={{ width: '20%', height: '100%', position: 'absolute', display: 'flex', justifyContent:'flex-end'}}>
                                                <div style={{ height: 18, width: 25, backgroundColor: 'red', borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <span style={{ color: '#fff', fontSize: state.length < 10 ? 10 : 8, fontWeight: 'bold' }}>{ state.length < 99 ? state.length : '+99'}</span>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </Link>
                        ))
                    }
                </div>
            </div> */}
             {/* <Container fluid className='BottomBarThreeMenu' style={{backgroundColor:'white'}}>
                <Row xl={3} className='Menu-item'>
                    {
                    dataTab?.map((items, index) => (
                        <Col className="icon">
                        <Link key={index} to={items.url}>
                            {
                                url !== items.url ? (<>
                                    {items.icons}
                                </>
                                )
                                : (
                                <>
                                    {items.icons_active}
                                </>
                                )
                            }
                            
                            <div style={{ fontSize: 15, color: url !== items.url ? '#808080' : '#5451D6'}}>{items.label}</div>
                                
                            {
                            items.label == "Transaksi" && <>
                                {
                                state.length !== 0 &&
                                <div style={{ width: '20%', height: '100%', position: 'absolute', display: 'flex', justifyContent:'flex-end'}}>
                                    <div style={{ height: 18, width: 25, backgroundColor: 'red', borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <span style={{ color: '#fff', fontSize: state.length < 10 ? 10 : 8, fontWeight: 'bold' }}>{ state.length < 99 ? state.length : '+99'}</span>
                                    </div>
                                </div>
                                }
                            </>
                            }
                        </Link>
                        </Col>
                    ))
                    }
                </Row>
            </Container> */}

<div onClick={() => setSidebar(false)}  className={sidebar ? 'nav-menu active' : 'nav-menu'} >
                <div style={Object.assign({ backgroundColor: '#fff', boxShadow: '0px 0px 10px -5px black'}, style)}>
                    {
                        !style['position'] &&
                            <div onClick={() => setSidebar(false)} style={{  textAlign: !style['position']? 'start' : 'end', width: 50, display: 'grid', justifyContent: 'center', paddingTop: 20 }}>
                                <AiOutlineCloseCircle size={30} color="#552CB7"/>
                            </div>
                    }
                    <div className="navBar-header">
                        <span className="fonts700" style={{ marginLeft: 10, fontSize: 15, marginTop: 20 }}>
                            {/* {
                                localStorage.getItem('dataBranch').picpath !== '-' &&
                                <img style={{ height: 40}} src={url + localStorage.getItem('dataBranch').picpath} />
                            } */}
                        </span>
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'center'}}>
                        <CopyRight />
                    </div>
                    <div className="nav-menu-items">
                        {
                            !localStorage.getItem('login') &&
                            <div className="navBar-login">
                                <Button onClick={() => navigate(`/${router}login`)}  backgroundColor='#FFF59D' >Masuk</Button>
                                <Gap height={10} />
                                <Button onClick={() => navigate(`/${router}register`)}  backgroundColor='#552cb7' color="#fff">Daftar</Button>
                                <Gap height={10} />


                                {/* <Button onClick={() => navigate(`/${router}lacak-pesanan`)}  backgroundColor='#552cb7' color="#fff">Lacak Pesanan</Button>
                                <Gap height={20} /> */}

                                <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }} onClick={() => navigate(`/${router}register`)}>
                                   Why sign up?
                                </div>
                            </div>
                        }
                            {
                            localStorage.getItem('login') &&
                            <div className="navBar-login">
                                <Button onClick={() => logOut()} loading={loading} backgroundColor="#FFF59D">Sign Out</Button>
                                <Gap height={10} />
                                {/* <Button onClick={() => navigate(`/${router}edit-profile`)} backgroundColor="#552cb7" color="#fff">Edit Profile</Button> */}


                                <Gap height={10} />
                                <Button onClick={() => navigate(`/${router}home/lacak-pesanan`)} backgroundColor="#552cb7" color="#fff">Lacak Pesanan</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}