import React from 'react'
import { AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import './style.css'
import Navbar from '../../components/Navbar';
import { Link, useNavigate ,useLocation} from 'react-router-dom'
import FETCH_API from '../../config/fetch_api'
// import Navbar from '../../components/Navbar';
import Loading from '../../components/Loading';
import { router } from "../../Constants"
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalHeader, Container, Row, Col, Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import UruzB from '../../Assets/UruzBlue.png';
import Merch1 from '../../Assets/Merchant/M1.png';

// API
import api from "../../request"
import { useEffect } from 'react';
import { Footer } from '../../components/BottomBar/BottomBar';
import { AppBarThreeItem, Outlet } from '../../components/AppBar/AppBar';



export default function PickResto() {
    const location = useLocation()
    console.log("location", location?.state)
    // const { items } = params.navigate.location.state
    let navigate = useNavigate()
    const { addToast } = useToasts()
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [cekLokasi, setCekLokasi] = React.useState(false)
    const [sidebar, setSidebar] = React.useState(false)

    function selectedBranch(items){
        let getCurrentLoginData = {}
        getCurrentLoginData.branchID = items.id
        getCurrentLoginData.branchName = items.name
        getCurrentLoginData.branchAddress = items.address
        getCurrentLoginData.branchTel = items.mobile
        getCurrentLoginData.picpath = items.picpath
        localStorage.setItem('dataBranch', JSON.stringify( Object.assign(getCurrentLoginData, items)))
    }

    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };
      
     async function success(pos) { 
        let branch = []
        var crd = pos.coords;
        for(const dataBranch of location?.state.cabang){
            branch.push(dataBranch.branch_id)
        }
        const resultData = await api.branches.getPos({ "branches"   : branch })
        const res = resultData?.data
        if (res) {          
            for(const item of res['data']){
                var from = new window.google.maps.LatLng(crd.latitude, crd.longitude);
                var dest = new window.google.maps.LatLng(item.latitude, item.longitude);
                var service = new window.google.maps.DistanceMatrixService();
                const xi = await service.getDistanceMatrix(
                    {
                        origins: [from],
                        destinations: [dest],
                        travelMode: 'DRIVING'
                    }, (response, status) => {
                        if (status == 'OK') {  
                            return response
                        }
                    });
                    item['locations'] = xi.rows[0].elements[0]?.['distance']?.['text']

                }
            localStorage.setItem("local_branch", JSON.stringify(res.data))
            setData(res['data'])
        }
        setLoading(false)
    }
    
    async function error(err) {
        let branch = []
        for(const dataBranch of location?.state.cabang){
            branch.push(dataBranch.branch_id)
        }

        const resultData = await api.branches.getPos({ "branches"   : branch })
        const res = resultData?.data
        

        localStorage.setItem("local_branch", JSON.stringify(res.data))
        if (res) {                
            setData(res['data'])
        }
        setLoading(false)
    }
    
    useEffect(async () => {
        if (location?.state) {            
            setLoading(true)
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error, [options])
            }
            else {
                addToast("Geolocation is not supported by this browser.", {
                    appearance: 'error',
                    autoDismiss: false,
                })
            }
        }
        else {
            navigate("/" + router)
        }
    },[])

    return (
        <div className='Main'>
            <div className='Appbar'>
            <Navbar type="pickresto" urlBack='pickresto' sidebar={sidebar} setSidebar={(val) => {
                          
                          setSidebar(val)
                      }} />  
                <div className='AppBarResto'>
                    <div className='Background'>
                        <h2 className='app-title'>Pilih Cabang Restoran</h2>

                        <div className='Resto'>
                            <Container className='Merchant' xl={2} xs={2} fluid>
                                <Row xs={2} style={{display:'flex', alignItems:'center'}}>
                                    <Col style={{height:'100px'}} className='Merchant-Logo' xl={3} xs={5}>
                                        <img style={{height:'100%',width:'100%'}} src={UruzB}></img>
                                    </Col>
                                    <Col className='Merchant-Address' xl={9} xs={7}>
                                        <div className='font700s' style={{fontSize:'24px',fontWeight:600,textAlign:'start'}}>
                                        {location?.state?.namaRestoran}
                                        </div>
                                        <div className='font700s' style={{fontSize:'18px',fontWeight:400,textAlign:'start'}}>
                                        {location?.state?.cabang?.length + ' Outlet'}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
           
     
            <Container className='Body-2'>
                {
                    data.map((items, index) => {
                        return (
                            <Row className='Body-Content' style={{paddingLeft:'3%'}}>
                                <a className='Link'
                                    onClick={() => {
                                        selectedBranch(items)
                                        navigate(`/${router}home`)
                                    }} key={index}
                                >
                                    <div className='Menu-title'>{items['name']}</div>
                                    <div className='Link-desc'>{items['locations']}</div>
                                </a>
                            </Row>
                        )
                    })
                }
                <div className='horizontal-divider-thick'></div>
                {
                    loading && <Loading />
                }
            </Container>

            <Footer />
        </div>
    )
}