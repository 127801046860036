import baseRequest  from "../utils/request"

export default class Pay extends baseRequest {
    constructor() {
        super();
        this.urls = {
            save: "trans-s-so-web-v2/save",
            edc:"edc-v3/getAllPayment"
        }
    }
    save = params => this.post(this.urls.save, params)
    edc = params => this.post(this.urls.edc, params)

}