import baseRequest  from "../utils/request"

export default class Xendit extends baseRequest {
    constructor() {
        super();
        this.urls = {
            // getCoaXendit: "products-web/get-coa-xendit",
            // check: 'payment-gateway/checkSuccessWalletXendit',
            // payment: 'payment-gateway/getXenditWallet',
            // getQR: "payment-gateway/getQRCodeXendit",
            // getQRNobu:"trans-nobu-v2",
            // paymentTrans :'trans-nobu/save',
            // paymentTransStatus :'trans-nobu-v2/status',
            // paymentTransCancel :'trans-nobu-v2/cancel'
            
            getCoaXendit: "products-web/get-coa-xendit",
            check: 'payment-gateway/checkSuccessWalletXendit',
            payment: 'payment-gateway/getXenditWallet',
            getQR: "payment-gateway/getQRCodeXendit",
            getQRNobu:"trans-nobu",
            paymentTrans :'trans-nobu/save',
            paymentTransStatus :'trans-nobu/status',
            paymentTransCancel :'trans-nobu/cancel'
            // prod
            // getQRCodeXendit: 'payment-gateway/getQRCodeXendit',
            // check: 'payment-gateway/checkSuccessXendit',
            // getQRNobu:"trans-nobu",
            // paymentTrans :'trans-nobu/save',
            // encrypt :'encryption/encrypt',
            // paymentTransStatus :'trans-nobu/status',
            // paymentTransPending :'trans-nobu/status-pending',
            // paymentTransUpdatePending :'trans-nobu/update-pending',
            // paymentTransCancel :'trans-nobu/cancel'
        }
    }
    getCoaXendit = params => this.post(this.urls.getCoaXendit, params)
    check = params => this.post(this.urls.check, params)
    payment = params => this.post(this.urls.payment, params)
    getQR = params => this.post(this.urls.getQR, params)
    getQRNobu = params => this.post(this.urls.getQRNobu, params)
    paymentTrans = params => this.post(this.urls.paymentTrans, params)
    paymentTransStatus = params => this.post(this.urls.paymentTransStatus, params)
    paymentTransCancel = params => this.post(this.urls.paymentTransCancel, params)

}