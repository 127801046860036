import React from "react";
import Input from '../../components/Input'
import OtpInput from 'react-otp-input';
import Button from '../../components/Button';
import Gap from '../../components/Gap';
import CopyRight from '../../Assets/CopyRight'
import {BiArrowBack} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { router } from "../../Constants"

import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged} from "firebase/auth";
import { getFirestore, doc, getDoc} from "firebase/firestore";

export default function ScreenOTP({ setInput, onSubmitOTP, loading, ResendOTP, input, onClickBack}) {
    const [timeOut, setTimeOut] = React.useState(0)
    const [captchaCalled, setCaptchaCalled] = React.useState(false)
    const [loadingOtp, setLoadingOTP] = React.useState(false)


    React.useEffect(() => {
		// startTimer(15)
	},[])

    function configureCaptcha(){
		const auth = getAuth();
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response) => {
			// reCAPTCHA solved, allow signInWithPhoneNumber.
			setCaptchaCalled(true)
			sendOTP();
		}
	}, auth)}


	function startTimer(duration) {
		let timer = duration, minutes, seconds;
		let myVar = setInterval(function () {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);
	
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
	
			let date = seconds;
			if (date == '00') {
				clearInterval(myVar);
				setTimeOut(0)
                setLoadingOTP(false)
				localStorage.removeItem("timeout");
			}
			else {
				setTimeOut(date)
				localStorage.setItem('timeout', parseInt(timer))
			}
			if (--timer < 0) {
				timer = duration;
			}
		}, 1000);
	}

    async function sendOTP(params) {
        setLoadingOTP(true)
        if(captchaCalled === false) {
            configureCaptcha()
        }
        
        const phoneNumber = localStorage.getItem('phoneNumberLogin');
        console.log(phoneNumber)
        const appVerifier = window.recaptchaVerifier;
        const auth = getAuth();

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            startTimer(15)
        }).catch((error) => {
            setLoadingOTP(false)
           console.log(error)
        });
    }

    return (
        <div>
            <div>
				<div style={{marginTop:10, marginLeft: 5, padding:10 }} onClick={onClickBack}>
					<BiArrowBack size={20}/>
				</div>
			</div>
              <div style={{ margin: '20% 20px 10px 20px' }}>
                  <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', padding: '30px 0px' }}>
                      <div style={{ fontSize: 20}} className="fonts700">Masukan Kode OTP</div>
                  </div>
                  
                  <div style={{  display: 'grid', justifyContent: 'center', alignItems: 'center'}}>
                      <OtpInput
                          inputStyle={{
                              height: 30,
                              width: 30,
                              border: '1px solid #5451D6',
                              color: '#000',
                          }}
                          value={input.otp}
                          onChange={(val) => {
                              setInput((prevState) => ({
                                  ...prevState,
                                  otp : val
                              }));
                          }}
                          numInputs={6}
                          separator={<span style={{marginRight: 10}}>  </span>}
                        />
                  </div>
                  <Gap height={20} />
                  <div style={{ display: 'flex'}}>
                      <span className="fonts700" style={{ color: '#b8b8b8'}}>Tidak Menerima Kode OTP?</span>
                      <Gap width={10} />
                    {
                        timeOut !== 0 ?
                        <span style={{ color: '#5451D6' }} className="fonts700" >{timeOut}</span>
                        :
                        <div onClick={() => {
                            if (!loadingOtp) {
                              sendOTP()
                            }
                        }} style={{ color: loadingOtp ? '#b8b8b8' : '#5451D6'}} className="fonts700">KIRIM OTP</div>
                    }
                  </div>
                  <Gap height={50} />
                  <Button onClick={(e) => {
                      if (!loading) {
                          onSubmitOTP(e)
                      }
                  }} loading={loading} color="#fff" backgroundColor="#5451D6">Submit OTP</Button>
                  {/* <Gap height={20} />
                  <Button onClick={ResendOTP} color="#fff" backgroundColor="#5451D6">Resend OTP / Change Phone Number</Button> */}
              </div>	
                
              <div style={{ marginTop: '70%', textAlign: 'center'}}>
                  <CopyRight />
              </div>
              <div id="sign-in-button"></div>
        </div>
    )
}