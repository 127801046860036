import React, { useState, useEffect } from "react";
import Navbar from '../../components/Navbar'
import QRCode from 'qrcode';
import FETCH_API from "../../config/fetch_api";
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import { formatDate } from "../../utils/formatDate";
import { useToasts } from 'react-toast-notifications'
import { send_email, proses_data } from "../../config/config"
import Gap from "../../components/Gap";
import { router } from "../../Constants"
import { encrypt, decrypt } from "../../utils/enkripsi"
import api from "../../request"
import { useSelector } from "react-redux"
import { setOrder } from "../../redux/actions/prepareActions"
import { AiFillCheckCircle, AiOutlineFieldTime, AiOutlineCloseCircle } from "react-icons/ai"
import { SocketOnline } from "../../utils/socket";

function Nobu(params) {
    const location = useLocation()
    const { state } = location

    const { addToast } = useToasts()
    // console.log("data payment", state)
    let navigate = useNavigate()
    const [imageUrl, setImageUrl] = useState('');
    const [checkPembayaran, setCheckPembayaran] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const userServer = JSON.parse(localStorage.getItem("userServer"))
    // const qrisNobu = localStorage.getItem("qrisNobu")
    const [dataStatus, setDataStatus] = React.useState();
    const id = JSON.parse(localStorage.getItem("idpesanan"))
    const [timeoutId, setTimeoutId] = useState(null);
    // async function CekStatus() {
    //     let typeDelivery = JSON.parse(localStorage.getItem("typePembayaran"))
    //     const dataPeyment = state.params.hasil
    //     const user = JSON.parse(localStorage.getItem('login'))
    //     const orderDate = JSON.parse(localStorage.getItem("orderDate"))
    //     console.log("orderdate > "+JSON.stringify(orderDate))
    //     const id = JSON.parse(localStorage.getItem("idpesanan"))
    //     // const checkPayment = await FETCH_API("payment-gateway/checkSuccessXendit", {
    //     //     id: state.params['hasil']['external_id'],
    //     //     userServer: userServer
    //     // })
    //     //  const checkPayment = useSelector(state => state?.dataPrepare?.order)
    //      const res = await api.apiPrepare.getOrder({
    //         "noTelp": orderDate.nomerHp,
    //         "id": ""
    //     })
    // console.log("res  "+JSON.stringify(res.data.data))
    //     const detailData = []

    //     for (const items of state.detailTrans) {
    //         detailData.push({
    //             "namaProduct": items['name'],
    //             "qty": items['qty'],
    //             "price": items['price'],
    //             "subtotal": items['total']
    //         })
    //     }
    //     if(res.data.data[0].trans_status == 1){
    //         addToast("Pembayaran Berhasil", {
    //                 appearance: 'success',
    //                 autoDismiss: true,
    //             })
    //     }
    //     else{
    //         addToast("Pembayaran belum selesai", {
    //                     appearance: 'info',
    //                     autoDismiss: true
    //                 })
    //     }
    //     // if (checkPayment['hasil']['status'] == "ACTIVE") {
    //     // if (checkPayment['hasil']['status'] == "ACTIVE") {

    //     //     setLoading(false)
    //     //     addToast("Pembayaran belum selesai", {
    //     //         appearance: 'info',
    //     //         autoDismiss: true
    //     //     })
    //     // }
    //     // else {
    //     //     const result = await proses_data()
    //     //     if (result.status == 'OK') {
    //     //         send_email({
    //     //             "subject": 'Pembayaran Uruz',
    //     //             "carabayar": 'QR Code',
    //     //             "idTransaksi": dataPeyment.id,
    //     //             "subTotal": dataPeyment.amount,
    //     //             "grandTotal": dataPeyment.amount,
    //     //             "linkLihatDetail": "https://google.com",
    //     //             "detailData": detailData

    //     //         })
    //             // addToast("Pembayaran Berhasil", {
    //             //     appearance: 'success',
    //             //     autoDismiss: true,
    //             // })
    //             // navigate(`/${router}home/lacak-pesanan`)
    //     //         if (typeDelivery?.label == "gojek") {
    //     //             navigate(`/${router}status-gojek`)
    //     //         }
    //     //         else {
    //     //             navigate(`/${router}home/lacak-pesanan`)
    //     //         }
    //     //     }
    //     // }
    // }
    async function Cancel() {
        // const amount = state.detailTrans[0]['total']
    //     const id = state.detailData
    // console.log('id >> '+JSON.stringify(id))
        const transcancel = await api.xendit.paymentTransCancel({
            "transactionNo": state.params['data']['transactionNo'],
            "referenceNo": state.params['data']['referenceNo'],
            "amount": state.detailData['grand_total_nett'],
            "qrisData": state.params['data']['qrisData']
        })
        // console.log('transcancel' + JSON.stringify(transcancel))
        localStorage.removeItem("idpesanan")

        // navigate(`/${router}home`)
        await Void()
        navigate(`/${router}home/lacak-pesanan`, {state:{
            params: transcancel.data,
        }})    }
        async function socket (){
            SocketOnline.emit('delivery', {
                branch_id   : JSON.parse(localStorage.getItem('dataBranch')).id,
                userServer  : userServer,
                id          : id,
                type        : localStorage.getItem('type_delivery') 
            });
        }
    async function Void() {
        const result = await FETCH_API('void/v2WithoutToken', {
            "from_origin": "*",
            "userServer": userServer,
            "userId": "Admin",
            "transCode": "so",
            "void_note": "",
            "so_id": "",
            "transId": state.detailData['id']
        })
        // console.log('result : ' + JSON.stringify(result))
    }

    const downloadImage = (base64String, filename) => {
        // Convert the base64 string to a Blob
        const byteCharacters = atob(base64String);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
      
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, { type: 'image/png' }); // Change the type as needed
      
        // Create a download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
      
        // Trigger a click event to initiate the download
        a.click();
      
        // Clean up resources
        window.URL.revokeObjectURL(url);
      };

      const base64Image = imageUrl; // Replace with your base64 image data
  const filename = "image.png"; // Replace with the desired filename

//   async function sendemail(){
//     send_email({
//         "subject": 'Pembayaran Uruz',
//         "carabayar": 'QR Code',
//         "idTransaksi": dataPeyment.id,
//         "subTotal": dataPeyment.amount,
//         "grandTotal": dataPeyment.amount,
//         "linkLihatDetail": "https://google.com",
//         "detailData": detailData

//     })
//   }
  const handleDownload = () => {
    downloadImage(base64Image, filename);
  };
    async function CekStatus() {
        let typeDelivery = JSON.parse(localStorage.getItem("typePembayaran"))
        const dataPeyment = state.params
        console.log(state)
        const user = JSON.parse(localStorage.getItem('login'))
        const orderDate = JSON.parse(localStorage.getItem("orderDate"))

        const id = JSON.parse(localStorage.getItem("idpesanan"))

        const checkPayment = state.params['responseStatus']
        const detailData = []
    
            for (const items of state.detailTrans) {
                detailData.push({
                    "namaProduct": items['name'],
                    "qty": items['qty'],
                    "price": items['price'],
                    "subtotal": items['total']
                })
            }
        // const checkPayment = await FETCH_API("payment-gateway/checkSuccessXendit", {
        //     id: state.params['hasil']['external_id'],
        //     userServer: userServer
        // })
        try {
            let typeDelivery = JSON.parse(localStorage.getItem("typePembayaran"))

            if (checkPayment == 'Success') {
                try {
                    const transstatus = await api.xendit.paymentTransStatus(
                        {
                            "transactionNo": state.params['data']['transactionNo'],
                        })
                    console.log(transstatus)
                    // if(transstatus['data']['paymentStatus']== 'Paid')
                    if (transstatus.data['responseStatus'] == 'Success') {
                        addToast("Pembayaran Berhasil", {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                        const result = await proses_data()
                        SocketOnline.emit('delivery', {
                            branch_id   : JSON.parse(localStorage.getItem('dataBranch')).id,
                            userServer  : userServer,
                            id          : id,
                            type        : localStorage.getItem('type_delivery') 
                        });
                        send_email({
                            "subject": 'Pembayaran Uruz',
                            "carabayar": 'QR Code',
                            "idTransaksi": state.detailData.id,
                            "subTotal": state.detailData.sub_total,
                            "grandTotal": state.detailData.grand_total_nett,
                            "linkLihatDetail": "https://google.com",
                            "detailData": detailData
                        })
                        navigate(`/${router}home/lacak-pesanan`)
                        // if (typeDelivery?.label == "gojek") {
                        //     navigate(`/${router}status-gojek`)
                        // }
                        // else {
                        //     navigate(`/${router}home/lacak-pesanan`)
                        // }
                    }
                    else{
                        addToast("Pembayaran belum selesai", {
                            appearance: 'info',
                            autoDismiss: true
                        })
                    }
                } catch (error) {
                    console.log(error)
                    addToast("Pembayaran belum selesai", {
                        appearance: 'info',
                        autoDismiss: true
                    })
                }
                
                 
                
            }


        }
        catch (error) {
            setLoading(false)
            
            // addToast(error.response.data.message, {
            //     appearance: 'error',
            // })

        }
        //      const res = await api.apiPrepare.getOrder({
        //         "noTelp": orderDate.nomerHp,
        //         "id": ""
        //     })
        // console.log("res  "+JSON.stringify(res.data.data))
        //     const detailData = []

        //     for (const items of state.detailTrans) {
        //         detailData.push({
        //             "namaProduct": items['name'],
        //             "qty": items['qty'],
        //             "price": items['price'],
        //             "subtotal": items['total']
        //         })
        //     }
        //     if(res.data.data[0].trans_status == 1){
        //         addToast("Pembayaran Berhasil", {
        //                 appearance: 'success',
        //                 autoDismiss: true,
        //             })
        //     }
        //     else{
        //         addToast("Pembayaran belum selesai", {
        //                     appearance: 'info',
        //                     autoDismiss: true
        //                 })
        //     }
        // if (checkPayment['hasil']['status'] == "ACTIVE") {
        //     if (checkPayment['hasil']['status'] == "ACTIVE") {

        //         setLoading(false)
        //         addToast("Pembayaran belum selesai", {
        //             appearance: 'info',
        //             autoDismiss: true
        //         })
        //     }
        //     else {
        //         const result = await proses_data()
        //         if (result.status == 'OK') {
        //             send_email({
        //                 "subject": 'Pembayaran Uruz',
        //                 "carabayar": 'QR Code',
        //                 "idTransaksi": dataPeyment.id,
        //                 "subTotal": dataPeyment.amount,
        //                 "grandTotal": dataPeyment.amount,
        //                 "linkLihatDetail": "https://google.com",
        //                 "detailData": detailData

        //             })
        //             addToast("Pembayaran Berhasil", {
        //                 appearance: 'success',
        //                 autoDismiss: true,
        //             })
        //             navigate(`/${router}home/lacak-pesanan`)
        //             if (typeDelivery?.label == "gojek") {
        //                 navigate(`/${router}status-gojek`)
        //             }
        //             else {
        //                 navigate(`/${router}home/lacak-pesanan`)
        //             }
        //         }
        //     }
        // }

        // async function CekStatus() {
        //     let typeDelivery = JSON.parse(localStorage.getItem("typePembayaran"))
        //     navigate(`/${router}home/lacak-pesanan`)
        //     if (typeDelivery?.label == "gojek") {
        //         navigate(`/${router}status-gojek`)
        //     }
        //     else {
        //         navigate(`/${router}home/lacak-pesanan`)
        //     }


    }
    
    useEffect(async () => {
        // payment();
        console.log('QR -> ' , (state.params['data']['qrisData']))
        addToast("Pembayaran QR hanya berlaku selama 15 menit", {
            appearance: 'warning',
            autoDismiss: true,
        })
        // console.log('===>'+JSON.stringify(state.detailTrans[0]['total']))
        const response = await QRCode.toDataURL(state.params['data']['qrisData']);
        // const response = await QRCode.toDataURL(qrisNobu)

        // const response = await QRCode.toDataURL(state.detailTrans[0]['picpath1_thumb']);
        setImageUrl(response);
        
        window.addEventListener('popstate', (event) => {
            navigate(`/${router}home`)
        });
        setTimeout(() => {
            setCheckPembayaran(true)
        }, 10000)

    }, [])
    useEffect(() => {
        // GojekStatus()
        // GojekUpdateStatus()
    
        console.log('mengapa ini?', SocketOnline)
    
      SocketOnline.on('connect', () => {
  console.log('Socket connected');
  // Additional logic after the connection is established
});

        SocketOnline.on("connect_error", (err) => {
          console.log(`aku error guys ${err}`);
        });
   
        SocketOnline.on("payment-success", (data) => {
            console.log(data.id)
            if(data.id === id){
            CekStatus()
            }
        });
    }, []);

    return (
        <div>
            <Navbar urlBack='home/lacak-pesanan'   type="PaymentNobu" label="Pembayaran Nobu" />
            {/* <div onClick={() => Cancel()} style={{ margin: "5px 5px 0", textAlign: 'right' }}>
                <AiOutlineCloseCircle size={30} color="#8B78FF" />
            </div> */}
            <div style={{ paddingTop: '20%' }}>
                <div style={{ margin: '0 20px' }}>
                    <div style={{ fontSize: 17, textAlign: 'center' }} className="fonts600">Buka aplikasi E-Wallet Anda dan Scan QR Code untuk membayar pesanan Anda</div>
                    <Gap height={30} />
                    <div style={{ color: '#5451D6', fontSize: 17, textAlign: 'center' }} className="fonts700">Pembayaran dapat dilakukan melalui Nobu</div>
                </div>
                <div style={{ justifyContent: 'center', display: 'grid', padding: '24px 0' }}>
                    {imageUrl ? (
                        <a href={imageUrl} download>
                            <img style={{ width: 250, height: 250 }} src={imageUrl} alt="img" />
                        </a>) : <Loading />}
                </div>
                {/* <div style={{ margin: '0 10%' }}>
                    {
                        // checkPembayaran &&
                        <Button onClick={() => {
                            sendemail()
                        }} color="#fff" backgroundColor="#04295E">
                            Cek Pembayaran
                        </Button>
                    }

                </div> */}
                <div style={{ margin: '0 10%' }}>
                    {
                        // checkPembayaran &&
                        <Button onClick={() => {
                            CekStatus()
                            setLoading(true)
                        }} color="#fff" backgroundColor="#04295E">
                            Cek Pembayaran
                        </Button>
                    }

                </div>
                <div style={{ margin: '2% 10%' }}>
                {
                        <Button onClick={() => {
                            Cancel()
                            setLoading(false)
                        }}  color="#fff" backgroundColor="red">
                            Cancel Transaksi
                        </Button>
                    }
                   
                </div>

                <div style={{ margin: '0 10%' }}>
                    {
                        // checkPembayaran &&
                        <a href={imageUrl} download>
                        <Button color="#fff" backgroundColor="#04295E">
                            Save QR
                        </Button>
                        </a>
                    }

                </div>
            </div>
        </div>
    )
}


export default Nobu