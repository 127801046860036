import React from "react";
import "./Loading.css"


export default function Button({onClick, children, backgroundColor, color, loading = false}) {
    return (
        <button onClick={onClick} style={{width: '100%', height: 40, borderRadius: 5, border: 'none', backgroundColor: loading ? "#b5b5b5" : backgroundColor, alignItems: 'center', justifyContent: 'center', display: 'grid'}}>
            {
                loading ? <div className="half-spinner-button"></div> :  <span className="fonts500" style={{ color: color }}>{children}</span>
            }
        </button> 
    )
}