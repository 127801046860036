import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import './Tenant.css'
import { Link, useNavigate } from 'react-router-dom'
import haversine from 'haversine'
import FETCH_API from '../../config/fetch_api'
import Navbar from '../../components/Navbar';
import { userServer } from '../../Constants.js'
import Loading from '../../components/Loading';
import { getFirestore, doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
import { useEffect } from 'react';
import { router, mode } from "../../Constants"
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalHeader, Button, Nav} from 'reactstrap';
import Merch1 from '../../Assets/Merchant/M1.png';
import UruzB from '../../Assets/UruzBlue.png';
import UruzW from '../../Assets/UruzWhite.png';
import {Footer} from '../../components/BottomBar/BottomBar';
import { AppBarTwoItem, SearchBar } from '../../components/AppBar/AppBar';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setKeranjang, setOrder } from "../../redux/actions/prepareActions"
import api from "../../request"

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}

export default function Tenant( props ) {
    const dispatch = useDispatch()

    let navigate = useNavigate()
    const { addToast } = useToasts()
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [state, setState] = React.useState()
    const [open, setOpen] = React.useState('')
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    const [searchInput, setSearchInput] = useState("");
    const [sidebar, setSidebar] = React.useState(false)

    function selectedBranch(items) {
        console.log('item>>>> ' + JSON.stringify(items))
        let getCurrentLoginData = {}
        getCurrentLoginData.branchID = items.id
        getCurrentLoginData.branchName = items.name
        getCurrentLoginData.branchAddress = items.address
        getCurrentLoginData.branchTel = items.mobile
        getCurrentLoginData.picpath = items.picpath
        localStorage.setItem('dataBranch1', JSON.stringify(Object.assign(getCurrentLoginData, items)))
        localStorage.setItem('userServer', JSON.stringify(items.connection))
        navigate(`/${router}pickresto`, {
            state:items
        })
        
    }

    function success(pos) {
        var crd = pos.coords;

        console.log("Your current position is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);
    }

    function handlePermission() {
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    if (result.state === "granted") {
                        console.log(result);
                        setOpen(false)
                        navigator.geolocation.getCurrentPosition(success);

                        //If granted then you can directly call your function here
                    } else if (result.state === "prompt") {
                        console.log(result.state);
                    } else if (result.state === "denied") {
                        setOpen(true)
                        console.log(result.state);

                        //If denied then you have to show instructions to enable location
                    }
                    result.onchange = function () {
                        console.log(result.state);
                    };
                });
        } else {
            alert("Sorry Not available!");
        }
    }

    React.useEffect( async () => {
        const server = localStorage.getItem('userServer')
        if (server) {        
            const local_charges = await api.apiPrepare.local_charges()
            localStorage.setItem("local_charges", JSON.stringify(local_charges.data?.data))
            dispatch(setKeranjang())
            dispatch(setOrder())
        }
    },[])
    useEffect(async () => {
        // handlePermission()
        setLoading(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (pos) => {
                var crd = pos.coords;
                const db = getFirestore();
                const querySnapshot = await getDocs(collection(db, "clients"));

                try {
                    querySnapshot.forEach(async (doc) => {
                        // console.log(JSON.stringify(doc.data()))
                        // if(process.env.NODE_ENV != doc.data().type){
                        var from = new window.google.maps.LatLng(crd.latitude, crd.longitude);
                        var dest = new window.google.maps.LatLng(doc.data().long, doc.data().lat);
                        var service = new window.google.maps.DistanceMatrixService();
                        // console.log(doc.data().branches)

                        const xi = await service.getDistanceMatrix(
                            {
                                origins: [from],
                                destinations: [dest],
                                travelMode: 'DRIVING'
                            }, (response, status) => {
                                if (status == 'OK') {
                                    return response
                                }
                            }
                        );
                        const restoran = {
                            namaRestoran: doc.id,
                            cabang: doc.data().branches,
                            connection: doc.data().connection,
                            location: xi.rows[0].elements[0]?.['distance']?.['text'],
                            mode: doc.data().type
                        }
                        setData(val => [...val, restoran])
                        setLoading(false)
                    });

                } catch (error) {
                    console.log("error", error)
                }
            }, async () => {
                const res = getFirestore();
                const querySnapshot = await getDocs(collection(res, "clients"));
                querySnapshot.forEach(async (doc) => {
                    const restoran = {
                        namaRestoran: doc.id,
                        cabang: doc.data().branches,
                        connection: doc.data().connection,
                        location: ""
                    }
                    setData(val => [...val, restoran])
                    setLoading(false)
                });
                addToast("Izinkan permission lokasi untuk mendapatkan informasi jarak", {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 4000
                })
            })
        }
        // var a='URUZ'
        // if(a.indexOf(searchInput)>-1){
        //     console.log('masuk use')
        // }
    }, [])

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        // console.log(e.target.value);
    };
    
    return (
        <div className='Main'>
            <div className='Appbar'>
                <div className='AppBar'>
                <Navbar type="Home" urlBack='pickresto' sidebar={sidebar} setSidebar={(val) => {
                          
                            setSidebar(val)
                        }} />                
                        
                </div>

                <div className='AppBarSearch'>
                    <div className='Background'>
                        <h2 className='app-title'>Pilih Restoran</h2>

                        <div className='Search'>
                            <Form>
                                <input className='Input-Form' type="text" placeholder="Search..." onChange={handleChange}/>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <Container className='Body-1' fluid>
                <Row xl={6} >
                    {
                        data.map((items, index) => {
                            // console.log(items)
                            // console.log(searchInput)
                            if(items.namaRestoran.toLowerCase().indexOf(searchInput) >
                            -1 ||(items.namaRestoran.indexOf(searchInput))>-1){
                                if (items.mode === mode) {
                                    return (
                                        <Col className='Item' onClick={() => { selectedBranch(items) }} key={index}>
                                            <a className='Link'>
                                                <div className='Merchant-Logo'>
                                                    <img src={UruzB}></img>
                                                </div>
                                                <div className='title'>
                                                    {items['namaRestoran']}
                                                </div>
                                                <div className='desc'>
                                                    {items['location']}
                                                </div>
                                            </a>
                                        </Col>
                                    )
                                }
                            }
                            else if(searchInput==''){
                                if (items.mode === mode) {
                                    return (
                                        <Col className='Item' onClick={() => { selectedBranch(items) }} key={index}>
                                            <a className='Link'>
                                                <div className='Merchant-Logo'>
                                                    <img src={Merch1}></img>
                                                </div>
                                                <div className='title'>
                                                    {items['namaRestoran']}
                                                </div>
                                                <div className='Merchant-Loc'>
                                                    {items['location']}
                                                </div>
                                            </a>
                                        </Col>
                                    )
                                }
                            }
                        })
                    }
                </Row>

                {
                    loading && <Loading />
                }

                <Modal style={{ color: 'red' }} isOpen={open} toggle={() => setOpen(false)}>
                    <ModalBody>
                        <div style={{width:windowDimensions.width>500?'30%':'70%',height:300,justifyContent:'center',boxShadow: '1px 2px 9px rgba(4, 41, 94, 0.3)', alignItems:'center',marginLeft:windowDimensions.width>500?'35%':'15%',marginTop:'20%'}}>
                        <div style={{ color: 'black',textAlign:'center',fontSize:20 }}>Izinkan Location Permission</div>
                        <div style={{ color: 'black',marginTop:'5%' }}>1. Klik paling kanan dari browser</div>
                        <div style={{ color: 'black' }}>2. Klik Settings</div>
                        <div style={{ color: 'black' }}>3. Pilih Cookies & Site Permissions</div>
                        <div style={{ color: 'black' }}>4. Scroll cari "All Permissions"</div>
                        <div style={{ color: 'black' }}>5. Klik Allow Permissions</div>
                        <Button onClick={()=>{setOpen(false)}} style={{ textAlign: 'center', border: '1px solid #3582B9', backgroundColor: '#552CB7', borderRadius: '16px', width: '30%',height:'20%',justifyContent: 'center', alignItems: 'center', marginLeft:  '35%',marginTop:windowDimensions.width>500?'2%':'10%' }}
                        ><p style={{ color: 'white', fontWeight: "bold", textAlign: 'center', marginTop: 7 }}>{"OK"}</p>
                        </Button>
                        </div>
                    </ModalBody>
                </Modal>  
                
            </Container>

            <Footer />
        </div>
    )
}