import { SET_GET_COA_XENDIT } from "../constants/xenditConstants"

let initialState = { 
    getCoaXendit : {}
};


export const xenditReducer = (state = initialState , action) => {
    if (action.type ===  SET_GET_COA_XENDIT ) {
        return {
            ...state,
            getCoaXendit: action.value
        }
    }
    return state
}