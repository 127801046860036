import React from 'react'
import { Form, FormGroup, InputGroup } from 'react-bootstrap'

export default function Input({ value, label, style, backgroundColor, placeholder, onChange, type, disabled, onBlur }) {
    return (
        <div style={style}>
            {/* {
                label &&
                <div className="fonts500" style={{ paddingBottom: 5, fontSize: 15}}>{label}</div>
            }
            <div style={{ backgroundColor: backgroundColor , display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #E2E4E8',}}>
                <input onBlur={onBlur} disabled={disabled} value={value} type={type} onChange={onChange} required placeholder={placeholder} className="fonts400" style={{ backgroundColor: backgroundColor, padding: '0px 12px', height: 35, borderRadius: 5, border: 'none', display: 'inline-block', width: '-webkit-fill-available',  fontSize: 14, width: '100%'}}/>
            </div> */}

            <Form.Group className="Form" >
                {
                    label &&
                    <Form.Label className='title'>{label}</Form.Label> 
                }

                <InputGroup hasValidation>
                    <Form.Control onBlur={onBlur} disabled={disabled} value={value} type={type} onChange={onChange} required placeholder={placeholder} />
                </InputGroup>
            </Form.Group>
        </div>
    )
}