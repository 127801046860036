import baseRequest  from "../utils/request"


export default class Branches extends baseRequest {
    constructor() {
        super();
        this.urls = {
            getPos: "branches-web-v2/get-pos"
        }
    }

    getPos = params => this.post(this.urls.getPos, params)
}