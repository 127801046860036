import React, { Component } from 'react'
import { HashRouter as Router, Routes, Route,Redirect ,Outlet} from "react-router-dom";
import { connect } from  'react-redux'
import Home from '../Pages/Home/Home'
import PickResto from '../Pages/PickResto/PickResto'
import Order from '../Pages/Order/Order'
import Login from '../Pages/Auth/Login'
import KodeOTP from '../Pages/Auth/Otp'
import KodeOTPRegister from '../Pages/Auth/OtpRegister'
import Register from '../Pages/Auth/Register'
import { initializeApp } from 'firebase/app';
import detailorder from '../Pages/DetailOrder/detailorder'
import AlamatPengiriman from '../Pages/AlamatPengiriman/AlamatPengiriman'
import AddAlamatPengirim from '../Pages/AddAlamatPengirim/AddAlamatPengirim'
import SearchAlamat from '../Pages/SearchAlamat/SearchAlamat'
import MetodePembayaran from '../Pages/MetodePembayaran/MetodePembayaran'
import LacakPesanan from '../Pages/LacakPesanan/LacakPesanan'
import ListLacakPesanan from '../Pages/LacakPesanan/ListLacakPesanan'
import editProfile from '../Pages/Auth/editProfile'
import Barcode from '../Pages/QRIS/Barcode'
import Tenant from '../Pages/Tenant/Tenant'
import TransactionProcessed from '../Pages/TransactionProcessed/TransactionProcessed'
import StatusGojek from '../Pages/StatusGojek/index'
import 'antd/dist/antd.css'
import { TabBottom } from "../Layout"
import { useDispatch } from "react-redux"
import { setKeranjang, setOrder } from "../redux/actions/prepareActions"
import api from "../request"
import Nobu from '../Pages/QRIS/Nobu'
import DetailProduct from '../Pages/Order/DetailProduct'
import DetailOrder from '../Pages/DetailOrder/detailorder';

function TabBottoms(params) {
    const dispatch = useDispatch()
    const { match } = params

    React.useEffect( async () => {
        const server = localStorage.getItem('userServer')
        if (server) {        
            const local_charges = await api.apiPrepare.local_charges()
            localStorage.setItem("local_charges", JSON.stringify(local_charges.data?.data))
            dispatch(setKeranjang())
            dispatch(setOrder())
        }
    },[])

    return ( 
        <TabBottom>
            <Route exact path={match.url} element={<Order/>}/>
            <Route path={match.url  + '/lacak-pesanan'} element={<LacakPesanan/>}/>
        </TabBottom>
    )
}


function Delivery(params) {
    const {} = params
    return (
        <Router>
        <Routes>   
                <Route path='/home' element={<TabBottoms/>}/>
                <Route path='/detail-order' element={<DetailOrder/>}/>
                <Route path='/detailproduct' element={<DetailProduct/>}/>

                <Route path='' element={<Tenant/>}/>
                <Route path='/pickresto' element={<PickResto/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/kode-otp' element={<KodeOTP/>}/>
                <Route path='/kode-otp-register' element={<KodeOTPRegister/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/edit-profile' element={<editProfile/>}/>
                <Route path='/alamat-pengiriman' element={<AlamatPengiriman/>}/>
                <Route path='/add-alamat-pengiriman' element={<AddAlamatPengirim/>}/>
                <Route path='/search-alamat' element={<SearchAlamat/>}/>
                <Route path='/metode-pembayaran/:id' element={<MetodePembayaran/>}/>
                <Route path='/list-lacak-pesanan' element={<ListLacakPesanan/>}/>
                <Route path='/metode-pembayaran-qris/:id' element={<Barcode/>}/>
                <Route path='/metode-pembayaran-nobu/:id' element={<Nobu/>}/>

                <Route path='/transaksi-proses' element={<TransactionProcessed/>}/>
                <Route path='/status-gojek' element={<StatusGojek/>}/>

                </Routes>
            </Router>
    )
}



function TableQR(params) {
    const {} = params
    // console.log("parms", params)

    return (
        <Router>
        <Routes>   
                <Route path='/tableqr' exact component={Tenant}/>
                <Route path='/tableqr/pickresto' component={PickResto}/>
                <Route path='/tableqr/order' component={Order}/>
                <Route path='/tableqr/login' component={Login}/>
                <Route path='/tableqr/kode-otp' component={KodeOTP}/>
                <Route path='/tableqr/kode-otp-register' component={KodeOTPRegister}/>
                <Route path='/tableqr/register' component={Register}/>
                <Route path='/tableqr/edit-profile' component={editProfile}/>
                <Route path='/tableqr/detail-order' component={detailorder}/>
                <Route path='/tableqr/alamat-pengiriman' component={AlamatPengiriman}/>
                <Route path='/tableqr/add-alamat-pengiriman' component={AddAlamatPengirim}/>
                <Route path='/tableqr/search-alamat' component={SearchAlamat}/>
                <Route path='/tableqr/metode-pembayaran/:id' component={MetodePembayaran}/>
                <Route path='/tableqr/lacak-pesanan' component={LacakPesanan}/>
                <Route path='/tableqr/list-lacak-pesanan' component={ListLacakPesanan}/>
                <Route path='/tableqr/metode-pembayaran-qris/:id' component={Barcode}/>
                <Route path='/tableqr/transaksi-proses' component={TransactionProcessed}/>
                <Route path='/tableqr/status-gojek' component={StatusGojek}/>
                {/* <Redirect from="/"  to="/delivery"/> */}
                </Routes>
            </Router>
    )
}





function Apps() {
    React.useEffect(() => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
    },[])
    return (
        <Router>
        <Routes> 
        <Route path='/home' element={<Order/>}/>
                <Route path='/detail-order' element={<DetailOrder/>}/>
                <Route path='/detailproduct' element={<DetailProduct/>}/>
                <Route path={'home/lacak-pesanan'} element={<LacakPesanan/>}/>

                <Route path='/' element={<Tenant/>}/>
                <Route path='/pickresto' element={<PickResto/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/kode-otp' element={<KodeOTP/>}/>
                <Route path='/kode-otp-register' element={<KodeOTPRegister/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/edit-profile' element={<editProfile/>}/>
                <Route path='/alamat-pengiriman' element={<AlamatPengiriman/>}/>
                <Route path='/add-alamat-pengiriman' element={<AddAlamatPengirim/>}/>
                <Route path='/search-alamat' element={<SearchAlamat/>}/>
                <Route path='/metode-pembayaran/:id' element={<MetodePembayaran/>}/>
                <Route path='/list-lacak-pesanan' element={<ListLacakPesanan/>}/>
                <Route path='/metode-pembayaran-qris/:id' element={<Barcode/>}/>
                <Route path='/metode-pembayaran-nobu/:id' element={<Nobu/>}/>

                <Route path='/transaksi-proses' element={<TransactionProcessed/>}/>
                <Route path='/status-gojek' element={<StatusGojek/>}/>                {/* <Route path='/tableqr' component={TableQR}/> */}
                </Routes>
            </Router>
    )
}

export default Apps