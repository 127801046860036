import Axios from 'axios'
import { url } from "../Constants"

const headers = {
    'Accept': "*",
    "Content-type": "application/json",
    'Access-Control-Allow-Origin': '*'
};

export const Request = Axios.create({
    headers: headers
});

export default class baseRequest {
    constructor() {
        this.urls = {};
    }

    async post (api, params = {}) {
        const userServer = JSON.parse(localStorage.getItem('userServer'))
        let dataBranch = JSON.parse(localStorage.getItem('dataBranch'))
        console.log(dataBranch)
        params['from_origin']= "*"
        if (userServer) {
            params["userServer"] = userServer
        }
        if (dataBranch) {
            params["branch_id"] = dataBranch?.id
        }
        console.log("api", url + api)
        console.log("params", params)
        return Request.post(url + api, params)
    }

}