import React from 'react'
import Navbar from '../../components/Navbar'
import Input from '../../components/Input';
import { GoogleMap, useJsApiLoader, Marker, LoadScript } from '@react-google-maps/api';
import { useLocation, useNavigate } from 'react-router-dom'
import Geocode from "react-geocode";
import { googleMapApiKey } from "../../Constants"
import { router } from "../../Constants"
import { useToasts } from 'react-toast-notifications'


const containerStyle = {
    width: '100%',
    height: '300px'
  };
  
Geocode.setApiKey(googleMapApiKey);


export default function SearchAlamat() {
    const { addToast } = useToasts()
    const navigate = useNavigate()
    const location = useLocation()
    const [position, setPosition] = React.useState({
        lat: 0, lng: 0
    })
    const [alamat, setAlamat] = React.useState()
    const [searchTerm, setSearchTerm] = React.useState('')
    const [addressUsers, setAddressUsers] = React.useState()
    const [latLang, setLang] = React.useState('')
    const [cekLokasi, setCekLokasi] = React.useState(false)


    function locations(latitude, longitude) {
        Geocode.fromLatLng(latitude, longitude).then(
            response => {
             const alamatLengkap = response.results[0].formatted_address;
             setAddressUsers(alamatLengkap)
            },
            error => {
                console.log('address', error);
            }
        );
    }


    React.useEffect( async () => {
        setPosition(location.state.position)
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition((position) =>  {
                let pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                  }

            }, (error) => {
                if (!localStorage.getItem('validasi')) {         
                    // addToast("Permission lokasi belum diaktifkan, mohon ke pengaturan browser untuk mengatifkan lokasi", {
                    //     appearance: 'error',
                    //     autoDismiss: false,
                    // })
                }
            })
        }
    },[location])


    React.useEffect(() => {
        if (searchTerm !== '') {        
            const delayDebounceFn = setTimeout(async () => {
              const result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${searchTerm}&key=${googleMapApiKey}`)
              .then((res) => res.json())
              .then((res) => res)              
              if (result['results'].length !== 0) {       
                  const address =  result['results'][0]
                  setAlamat({
                      alamat: address['formatted_address'],
                      longitude: address['geometry']['location']
                  })
                  setPosition({
                    lat: address['geometry']['location']['lat'], lng: address['geometry']['location']['lng']
                  })
              }
              console.log("searchTerm", searchTerm)
            }, 1000)
        
            return () => clearTimeout(delayDebounceFn)
        }
      }, [searchTerm])


    React.useEffect(() => {
        if (latLang !== '') {
            const delayDebounceFn = setTimeout(() => {
                locations(latLang['lat'], latLang['lng'])
                setPosition({
                    lat: latLang['lat'],
                    lng: latLang['lng']
                })
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    },[latLang])



      function onClickAddress() {
        setAlamat()
        locations(position['lat'], position['lng'])
      }


    
    React.useEffect( async () => {
        if (location.state.type == "edit") {
            setPosition(location.state.position)
            locations(location.state.position.lat, location.state.position.lng)
        }
        else {
            if (navigator.geolocation) {
                await navigator.geolocation.getCurrentPosition((position) =>  {
                    let pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
    
                    setPosition(pos)
                    locations(position.coords.latitude, position.coords.longitude)
                })
            }
        }
        window.addEventListener('popstate', (event) => {
            navigate(`/${router}add-alamat-pengiriman`, {
                addressUsers:  location.state.addressUsers,
                type: location.state.type,
                position: location.state.position
            })
        });
    },[])

    const onLoad = marker => {
        console.log('marker: ', marker)
    }
    return (
        <div>
            <Navbar setCekLokasi={setCekLokasi} cekLokasi={cekLokasi} urlBack="add-alamat-pengiriman" paramsUrlBack={{
                state:location?.state
            }} type="Add-Address" label="Cari Alamat"/>
            <div style={{ paddingTop: '1vh', margin: '0 10px 0 10px'}}>
                <Input onChange={(val) => setSearchTerm(val.target.value)} placeholder="Cari Alamat"/>
                {
                    alamat &&
                <div onClick={onClickAddress} style={{ position: 'absolute', zIndex: 99, backgroundColor: '#fff', left: 10, right: 10}}>
                    <div style={{ margin: '5px 10px'}}>
                      {alamat['alamat']}
                    </div>
                </div>
                }
                <div style={{ marginTop: 20}}>
                    <LoadScript googleMapsApiKey={googleMapApiKey} >             
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={position}
                          zoom={15}
                        >
                            <Marker
                              onLoad={onLoad}
                              position={position}
                              draggable={true}
                              onDrag={(position) => {
                                  setLang({
                                    lat : position['latLng'].lat(),
                                    lng : position['latLng'].lng()
                                })
                              }}
                            
                            />
                          <></>
                        </GoogleMap>
                    </LoadScript>
                </div>

                <div className="fonts400" style={{ margin: '20px 0 0 0', fontSize: 15, color: '#161719'}}>
                   {addressUsers}
                </div>
                <div style={{ margin: '20px 10px 10px 10px' }}>
                    <button onClick={() => {
                        if (addressUsers) {                   
                            let xi = location.state.addressUsers
                            xi['alamat'] = addressUsers
                            xi['lat'] = position.lat
                            xi['lng'] = position.lng
                            navigate(`/${router}add-alamat-pengiriman`, {state:{
                                position: position,
                                addressUsers:  xi,
                                type: location.state.type 
                            }})
                        }
                        else {
                            addToast("Alamat harus diisi", {
                                appearance: 'error',
                                autoDismiss: true,
                            })
                        }
                    }} style={{ width: '100%', padding: '10px 0 10px 0', border: 'none', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#8B78FF'}}>
                        <span style={{ fontSize: 13, color: '#fff', marginLeft: 5}} className="fonts500">OK</span>
                    </button>
                </div>
            </div>
        </div>
    )
}