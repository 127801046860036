import React from 'react'



export default function Text({ children, className, style= {} }) {
    const styles = Object.assign(style, {
        marginTop: 10
    })
    return (
        <div className={className} style={styles}>{children}</div>
    )
}