import { SET_GOJEK_GET_DISTANCE_AND_PRICE, SET_GOJEK_ORDER, SET_GOJEK_STATUS} from "../constants/gojekConstants"

let initialState = { 
    order : {},
    getDistanceAndPrice: {},
    status_gojek: {}
};


export const gojekReducer = (state = initialState , action) => {
    if (action.type ===  SET_GOJEK_ORDER ) {
        return {
            ...state,
            order: action.value
        }
    }
    if (action.type ===  SET_GOJEK_GET_DISTANCE_AND_PRICE ) {
        return {
            ...state,
            getDistanceAndPrice: action.value
        }
    }
    if (action.type ===  SET_GOJEK_STATUS ) {
        return {
            ...state,
            status_gojek: action.value
        }
    }
    return state
}