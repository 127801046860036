import CryptoJS from "crypto-js"


export const encrypt = (data) => {
    if (data) {     
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'KBa2HxRmxi7i4v6dUa0bFAmRGbN0ACAi9t3ExsNY4svgoz7YMbBXiCHJ6QiW1MFE').toString();
        return ciphertext
    }
    else {
        return data
    }
}

export const decrypt = (data) => {
    if (data) {     
        var bytes  = CryptoJS.AES.decrypt(data, 'KBa2HxRmxi7i4v6dUa0bFAmRGbN0ACAi9t3ExsNY4svgoz7YMbBXiCHJ6QiW1MFE');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData
    }
    else {
        return data
    }
}
//  const secretKey = "KBa2HxRmxi7i4v6dUa0bFAmRGbN0ACAi9t3ExsNY4svgoz7YMbBXiCHJ6QiW1MFE"
//  export var encrypt = function (string) {
//     const dirtyString = string;
//     // Encrypt
//     var ciphertext = CryptoJS.AES.encrypt(dirtyString, secretKey).toString();
//     return ciphertext
// }
// export var decrypt = function (string) {
//     const ciphertext = string;
//     // Descrypt
//     var plainText = CryptoJS.AES.decrypt(ciphertext, secretKey);
//     var cleanPlainText = plainText.toString(CryptoJS.enc.Utf8)
//     return cleanPlainText
// }