import React from 'react'
import './Navbar.css'
import UruzB from '../Assets/UruzBlue.png';
import UruzW from '../Assets/UruzWhite.png';
import CopyRight from '../Assets/CopyRight'
import { AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import { BiArrowBack, BiMenu } from "react-icons/bi";
import { AiOutlineLeft, AiOutlineExclamationCircle } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom'
import {FaSignOutAlt, FaUser} from "react-icons/fa"
import { getAuth, signOut } from "firebase/auth";
import { AiOutlineCloseCircle } from "react-icons/ai"
import { url, router } from "../Constants"
import Button from "../components/Button"
import Gap from "../components/Gap"
import { IoCartOutline, IoCartSharp } from "react-icons/io5"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { baseUrl } from "../Constants"
import { Container, Row, Col, Form } from 'react-bootstrap';
import { FiShare2 } from "react-icons/fi";
import { useToasts } from 'react-toast-notifications'
import { encrypt, decrypt } from "../utils/enkripsi"
import copy from 'copy-to-clipboard';

export default function Navbar({sidebar, setSidebar, type, label, style = {}, setStyle, urlBack = "back", paramsUrlBack, buttonOrder, cekLokasi, setCekLokasi, items}) {
    const [loading, setLoading] = React.useState(false)
    let navigate = useNavigate()
    const state = useSelector(state => state?.dataPrepare?.keranjang)   
    function logOut(){
        setLoading(true)
        const auth = getAuth();
        signOut(auth).then(() => {
            navigate( "/" + router + 'home')
            localStorage.removeItem("login");
            setLoading(false)
            setSidebar(false)
        }).catch((error) => {
            console.log(error)
        });
    }
    return (
        <div>
            <Container className="navBar" fluid>
                
                    {/* {
                        !type && (
                            <Link to="#" style={{ marginLeft: 10}} >
                                <BiMenu onClick={() => {
                                    setSidebar(true)
                                    if (style['position']) {
                                        setStyle({})
                                    }
                                }} size={30} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                            </Link>
                        ) 
                    } */}
                    
                    {
                        type === "prepare" && (
                            <Container fluid>
                                <Row xs={2} style={{paddingTop:'0.5%', paddingBottom:'1%'}}>
                                    <Col xs={6} style={{display:'flex', alignItems:'center', justifyContent:'start'}}>
                                        <img src={UruzW} style={{width:'150px'}} ></img>
                                    </Col>
                                    <Col xs={6} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <button style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                                            <BiMenu size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}} 
                                                onClick={() => {
                                                    setSidebar(true)
                                                    if (style['position']) {
                                                        setStyle({})
                                                    }
                                                }}/>
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }

                    {
                        type === "Menu" && (
                            <Container fluid>
                                <Row style={{paddingTop:'0.5%', paddingBottom:'1%'}}>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'start'}}>
                                        <button onClick={() => {
                                            navigate("/")
                                        }} style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}} >
                                            <AiOutlineLeft size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                                        </button>
                                    </Col>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <img src={UruzW} style={{width:'150px', margin:'auto'}} ></img>
                                    </Col>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <button style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                                            <BiMenu size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}} 
                                            onClick={() => {
                                                setSidebar(true)
                                                if (style['position']) {
                                                    setStyle({})
                                                }
                                            }}/>
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }

                    {
                        type === "pickresto" && (
                            <Container fluid>
                                <Row>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'start'}}>
                                        <button onClick={() => {
                                            navigate(`/${router}`)
                                        }} style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}} >
                                            <AiOutlineLeft size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                                        </button>
                                    </Col>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <img src={UruzW} style={{width:'150px', margin:'auto'}} ></img>
                                    </Col>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <button style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                                            <BiMenu size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}} 
                                            onClick={() => {
                                                setSidebar(true)
                                                if (style['position']) {
                                                    setStyle({})
                                                }
                                            }}/>
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
                     {
                        type === "Home" && (
                            <Container fluid>
                                <Row>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'start'}}>
                                        {
                                            urlBack === 'pickresto' &&
                                            <button onClick={() => {
                                                navigate(`/${router}`)
                                            }} style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}} >
                                                <AiOutlineLeft size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                                            </button>
                                        }
                                       
                                    </Col>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <img src={UruzW} style={{width:'150px', margin:'auto'}} ></img>
                                    </Col>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <button style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                                            <BiMenu size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}} 
                                            onClick={() => {
                                                setSidebar(true)
                                                if (style['position']) {
                                                    setStyle({})
                                                }
                                            }}/>
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
{
                        type === "list-lacak-pesanan" && (
                            <Container fluid>
                                <Row>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'start'}}>
                                        <button onClick={() => {
                                            navigate(`/${router}home/lacak-pesanan`)
                                        }} style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}} >
                                            <AiOutlineLeft size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                                        </button>
                                    </Col>
                                    <Col xs={4} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        <img src={UruzW} style={{width:'150px', margin:'auto'}} ></img>
                                    </Col>
               
                                </Row>
                            </Container>
                        )
                    }
                    {
                        type === "Payment" && (
                            <Container fluid>
                                <Row>
                                <div onClick={() => {if (urlBack == 'back') {
                                                navigate.goBack()
                                            }
                                            else {
                                                navigate("/" + router + urlBack, paramsUrlBack)
                                            }}} 
                        style={{  
                            margin: '0px 0px 0 10px',
                            borderRadius: 5,
                            height: 50, 
                            width: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'transparent'
                        }} >
                        <BiArrowBack size={30} color="#fff"/>
                    </div>
                                    {/* <Col xs={2}>
                                        <button onClick={() => {
                                            if (urlBack == 'back') {
                                                navigate.goBack()
                                            }
                                            else {
                                                navigate("/" + router + urlBack, paramsUrlBack)
                                            }
                                        }} style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}} >
                                            <AiOutlineLeft size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                                        </button>
                                    </Col> */}
                                    <Col xs={8} style={{fontSize:'16px', fontWeight:'600', color:'white', justifyContent:'center', display:'flex', alignItems:'center'}}>
                                        {label}
                                    </Col>
                                    <Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        {/* <button style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                                            <BiMenu size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}} 
                                            onClick={() => {
                                                setSidebar(true)
                                                if (style['position']) {
                                                    setStyle({})
                                                }
                                            }}/>
                                        </button> */}
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
                    {
                        type === "PaymentNobu" && (
                            <Container fluid>
                                <Row >
                                <div onClick={() => {if (urlBack == 'back') {
                                                navigate.goBack()
                                            }
                                            else {
                                                navigate("/" + router + urlBack, paramsUrlBack)
                                            }}} 
                        style={{  
                            margin: '0px 0px 0 10px',
                            borderRadius: 5,
                            height: 50, 
                            width: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'transparent'
                        }} >
                        <BiArrowBack size={30} color="#fff"/>
                    </div>
                                    <Col xs={8} style={{fontSize:'16px', fontWeight:'600', color:'white', justifyContent:'center', display:'flex', alignItems:'center'}}>
                                        {label}
                                    </Col>
                                    <Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        {/* <button style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                                            <BiMenu size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}} 
                                            onClick={() => {
                                                setSidebar(true)
                                                if (style['position']) {
                                                    setStyle({})
                                                }
                                            }}/>
                                        </button> */}
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
                    {
                        type === "Add-Address" && (
                            <Container fluid>
                                <Row >
                                <div onClick={() => {if (urlBack == 'back') {
                                                navigate(-1)
                                            }
                                            else {
                                                console.log("/" + router + urlBack, paramsUrlBack)

                                                navigate("/" + router + urlBack, paramsUrlBack)
                                            }}} 
                        style={{  
                            margin: '0px 0px 0 10px',
                            borderRadius: 5,
                            height: 50, 
                            width: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'transparent'
                        }} >
                        <BiArrowBack size={30} color="#fff"/>
                    </div>
                                    <Col xs={8} style={{fontSize:'16px', fontWeight:'600', color:'white', justifyContent:'center', display:'flex', alignItems:'center'}}>
                                        {label}
                                    </Col>
                                    <Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        {/* <button style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                                            <BiMenu size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}} 
                                            onClick={() => {
                                                setSidebar(true)
                                                if (style['position']) {
                                                    setStyle({})
                                                }
                                            }}/>
                                        </button> */}
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
                    {
                        type === "Lacak-Pesanan" && (
                            <Container fluid>
                                <Row >
                                {/* <div onClick={() => {
                                                navigate("/home")}}
                        style={{  
                            margin: '0px 0px 0 10px',
                            borderRadius: 5,
                            height: 50, 
                            width: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'transparent'
                        }} >
                        <BiArrowBack size={30} color="#fff"/>
                    </div> */}
                                    <Col xs={12} style={{fontSize:'16px', fontWeight:'600', color:'white', justifyContent:'center', display:'flex', alignItems:'center'}}>
                                        {label}
                                    </Col>
                                    <Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        {/* <button style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                                            <BiMenu size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}} 
                                            onClick={() => {
                                                setSidebar(true)
                                                if (style['position']) {
                                                    setStyle({})
                                                }
                                            }}/>
                                        </button> */}
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
                    {/* {
                        type && (
                            <span className="fonts500" style={{ marginLeft: 15, fontSize: 20 }}>
                                
                            </span>
                        )
                    } */}
                    
                    {/* {
                        !type && (
                            <>
                                {
                                    !localStorage.getItem('login') && (
                                        <span onClick={() => setSidebar(true)} className="fonts500" style={{ marginLeft: 10, fontSize: 15 }}>
                                            {label}
                                        </span>
                                    )
                                }
                                {
                                    localStorage.getItem('login') && (
                                        <span onClick={() => setSidebar(true)} className="fonts500" style={{ marginLeft: 10, fontSize: 15 }}>
                                            {
                                                JSON.parse(localStorage.getItem('login')).nama.indexOf(' ') > 0 ? 
                                                    <span className="fonts500" style={{ marginRight: 10}}>
                                                        Halo {JSON.parse(localStorage.getItem('login')).nama.substring(0, JSON.parse(localStorage.getItem('login')).nama.indexOf(' '))}
                                                    </span>
                                                :
                                                    <span className="fonts500" style={{ marginRight: 10}}>
                                                        Halo {JSON.parse(localStorage.getItem('login')).nama}
                                                    </span>
                                            }
                                        </span>
                                    )
                                }
                            </>
                        )
                    } */}

                    {/* {
                        type =="menu" && (
                            <div>
                                <span className="fonts500" style={{ marginLeft: 15, fontSize: 20 }}>
                                    {label}
                                </span>
                            </div>
                        )
                    } */}


                {/* {
                    !type &&
                    <Link to={ state !== 0 ? `/${router}detail-order` :  window.location.pathname } style={{ padding: '0px 5px', position: 'relative', display: 'flex', marginRight: 10}} >
                        <IoCartSharp size={25} color="#5451D6"/>
                        {
                            state !== 0 &&
                                <div style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'end' }}>
                                    <div style={{ height: 17, width: 17, backgroundColor: 'red', borderRadius: 19, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <span style={{ color: '#fff', fontSize: 10, fontWeight: 'bold' }}>{ state < 10 ? state : '+9'}</span>
                                    </div>
                                </div>
                        }
                    </Link>
                }
                {
                    type == "details" && (
                        <div onClick={() => {
                            //     addToast(dataOrder['product_name'] + "Berhasil disalin", {
                            //         appearance: 'success',
                            //         autoDismiss: true,
                            //         autoDismissTimeout: 1500,
                            //     })
                            //     copy(`${baseUrl}delivery/home?x2=${encrypt(
                            //     {
                            //         userServer: 'uruz2', 
                            //         cabang: brannch, 
                            //         produk: dataOrder
                            // })}`)
                            }} 
                            style={{  
                                margin: '10px 10px 0 0px',
                                borderRadius: 20,
                                height: 30, 
                                width: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                padding: 5
                            }} >
                            <FiShare2 size={30} color="#fff"/>
                        </div>
                    ) 
                } */}
                
            </Container>

            <div onClick={() => setSidebar(false)}  className={sidebar ? 'nav-menu active' : 'nav-menu'} >
                <div style={Object.assign({ backgroundColor: '#fff', boxShadow: '0px 0px 10px -5px black'}, style)}>
                    {
                        !style['position'] &&
                            <div onClick={() => setSidebar(false)} style={{  textAlign: !style['position']? 'start' : 'end', width: 50, display: 'grid', justifyContent: 'center', paddingTop: 20 }}>
                                <AiOutlineCloseCircle size={30} color="#552CB7"/>
                            </div>
                    }
                    <div className="navBar-header">
                        <span className="fonts700" style={{ marginLeft: 10, fontSize: 15, marginTop: 20 }}>
                            {/* {
                                localStorage.getItem('dataBranch').picpath !== '-' &&
                                <img style={{ height: 40}} src={url + localStorage.getItem('dataBranch').picpath} />
                            } */}
                        </span>
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'center'}}>
                        <CopyRight />
                    </div>
                    <div className="nav-menu-items">
                        {
                            !localStorage.getItem('login') &&
                            <div className="navBar-login">
                                <Button onClick={() => navigate(`/${router}login`)}  backgroundColor='#FFF59D' >Masuk</Button>
                                <Gap height={10} />
                                <Button onClick={() => navigate(`/${router}register`)}  backgroundColor='#552cb7' color="#fff">Daftar</Button>
                                <Gap height={10} />


                                {/* <Button onClick={() => navigate(`/${router}lacak-pesanan`)}  backgroundColor='#552cb7' color="#fff">Lacak Pesanan</Button>
                                <Gap height={20} /> */}

                                <div style={{ marginTop: 10, display: 'grid', justifyContent: 'center' }} onClick={() => navigate(`/${router}register`)}>
                                   Why sign up?
                                </div>
                            </div>
                        }
                            {
                            localStorage.getItem('login') &&
                            <div className="navBar-login">
                                <Button onClick={() => logOut()} loading={loading} backgroundColor="#FFF59D">Sign Out</Button>
                                <Gap height={10} />
                                {/* <Button onClick={() => navigate(`/${router}edit-profile`)} backgroundColor="#552cb7" color="#fff">Edit Profile</Button> */}


                                <Gap height={10} />
                                <Button onClick={() => navigate(`/${router}home/lacak-pesanan`)} backgroundColor="#552cb7" color="#fff">Lacak Pesanan</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}