import Gojek from "./apiGojek";
import Charges from "./apiCharges";
import Xendit from './apiXendit'
import Branches from "./apiBranches";
import Order from "./apiOrder";
import Prepare from "./apiPrepare";
import Pay from "./apiPay";
import Guest from "./apiGuest";
const api = {
    gojek : new Gojek(),
    charges: new Charges(),
    xendit: new Xendit(),
    branches: new Branches(),
    order: new Order(),
    apiPrepare: new Prepare(),
    apiPay: new Pay(),
    apiGuest: new Guest()
}
export default api