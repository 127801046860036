import React from 'react'
import Head from '../../Assets/Head'
import Responsif from '../../utils/Responsif'
import { Link } from 'react-router-dom'
import {DMSansBold, DMSansMedium, } from '../../fonts/index'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged} from "firebase/auth";
import { useToasts } from 'react-toast-notifications'
import { collection, addDoc, doc, setDoc, getFirestore, getDoc} from "firebase/firestore";
import Input from '../../components/Input'
import InputPhone from '../../components/InputPhone'
import CopyRight from '../../Assets/CopyRight'
import { useNavigate } from 'react-router-dom'
import {BiArrowBack} from 'react-icons/bi'
import Gap from '../../components/Gap'
import Button from '../../components/Button'
import { router } from "../../Constants"
import api from '../../request'

function Home() {
    const { addToast } = useToasts()
    let navigate = useNavigate()
    const { width } = Responsif()
    const [input, setInput] = React.useState({
        Nama   : localStorage.getItem('register-nama') ? localStorage.getItem('register-nama') : '',
        NoTelp : localStorage.getItem('register-telp') ? localStorage.getItem('register-telp') : '',
        Status : 'insert'
    }) 

    const [loading, setLoading] = React.useState(false)

    const [captchaCalled, setCaptchaCalled] = React.useState(false)
    function onSignInSubmit(e){
		e.preventDefault()
		onSignIn()
	}

	function configureCaptcha(){
		const auth = getAuth();
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response) => {
			// reCAPTCHA solved, allow signInWithPhoneNumber.
			setCaptchaCalled(true)
			onSignInSubmit();
		}
	}, auth)}
    
    // async function getAllGuest(){
    //     const data = await api.apiGuest.getAll({
    //         "from_origin": "*",
    //         "userServer": "cooluruz",
    //         "userId": "admin"
    //       });
    //       console.log(data.data.data)
    //       const dataGuest = data.data.data
    //       console.log(input.Nama, dataGuest[0].id)
    //       const filtered = dataGuest.filter(data =>
    //         data.id.toLowerCase().includes(input.Nama.toLowerCase())
    //       );
    //       console.log(filtered[0])
    //       if(filtered[0] === undefined){
    //         addGuest()
    //       }
    //       else if(filtered[0] !== undefined){
    //         get1Guest()
    //       }
    //     //   for(let i=0;i<dataGuest.length;i++){
    //     //     if(dataGuest[i].id !== detailUsers.nama){
    //     //         addGuest()
    //     //     }
    //     //     else{
    //     //         get1Guest()
    //     //     }
    //     //   }
    // }
    
    // async function get1Guest(){
    //     const data = await api.apiGuest.get1({
    //         "from_origin": "*",
    //         "userServer": "cooluruz",
    //         "userId": "admin",
    //         "id": input.Nama
    //       });
    //       console.log(data.data.data[0])
    //       localStorage.setItem("loyalty", JSON.stringify(data.data.data[0]))
    // }
    // async function addGuest(){
    //     const data = await api.apiGuest.save({
    //         "from_origin": "*",
    //         "userServer": "cooluruz",
    //         "userId": "admin",
    //         "mode": "insert",
    //         "detailData": [
    //           {
    //             "old_id": "",
    //             "id": input.Nama,
    //             "country_id": "",
    //             "name": input.Nama,
    //             "id_card_type": "ktp",
    //             "id_card_no": "123",
    //             "gender": "male",
    //             "nationality": "+62",
    //             "province": "UMUM",
    //             "city": "UMUM",
    //             "address": "",
    //             "email": '',
    //             "npwp": "",
    //             "phone1": input.NoTelp,
    //             "phone2": "",
    //             "fax": "",
    //             "marketing_id": "",
    //             "marketing_name": "",
    //             "mobile": "",
    //             "person_in_charge": "",
    //             "coa": "1.01.03",
    //             "coa_name": "PIUTANG USAHA",
    //             "coa_deposit": "",
    //             "coa_deposit_name": "",
    //             "picpath": "",
    //             "picpath_thumb": "",
    //             "top": 0,
    //             "top_tolerance": 0,
    //             "disc1": 0,
    //             "disc2": 0,
    //             "tax": 0,
    //             "note": "",
    //             "ar_limit": 0,
    //             "created_by": "",
    //             "created_by_name": "",
    //             "created_date": "",
    //             "updated_by": "-",
    //             "updated_by_name": "",
    //             "updated_date": "",
    //             "close_table_closing": 0,
    //             "active": 1,
    //             "kirim_nama": "-",
    //             "kirim_alamat": "-",
    //             "kirim_penerima": "-",
    //             "kirim_phone": "-",
    //             "kirim_province": "-",
    //             "kirim_city": "-",
    //             "isLogin": 0,
    //             "coa_point_internal": "",
    //             "coa_point_internal_name": "",
    //             "coa_point_external": "",
    //             "coa_point_external_name": "",
    //             "point_reward_internal": "",
    //             "point_reward_internal_name": "",
    //             "point_reward_external": "",
    //             "point_reward_external_name": ""
    //           }
    //         ]
    //       });
    //       console.log(data)
    // }

    async function onSignIn(e) {
        setLoading(true)
		const phoneNumber = "+62"+input.NoTelp.replace(/ /g,'');
        console.log(phoneNumber)
        if(!input.NoTelp) {
            setLoading(false)
            addToast("No Telepon harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })
            return
        }
        if(!input.Nama) {
            setLoading(false)
            addToast("Nama harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })
            return
        }
        if(captchaCalled === false) {
            configureCaptcha()
        }

		const db      = getFirestore();
		const docRef  = doc(db, "users", phoneNumber);
		const docSnap = await getDoc(docRef);

        if(docSnap.data()) {
            setLoading(false)
            addToast("No telp ini sudah pernah diregister, silahkan menggunakan nomor lain atau mencoba login lagi.", {
                appearance: 'error',
                autoDismiss: true,
            })
            return;
        }
        
        
        const appVerifier = window.recaptchaVerifier;
        const auth = getAuth();
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
            setLoading(false)
            window.confirmationResult = confirmationResult;
            
            navigate(`/${router}kode-otp-register`,  {state    : {detail : input}}) 
            // getAllGuest()

            localStorage.setItem('phoneNumberLogin', phoneNumber)
        }).catch((error) => {
            console.log("error", error)
            setLoading(false)
            if(error.code == 'auth/invalid-phone-number') {
                addToast("Nomor telepon yang dimasukkan tidak valid.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } 
            if(error.code == 'auth/too-many-requests') {
                addToast("Nomor telepon anda terlalu banyak melakukan percobaan, tunggulah berapa menit lagi", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } 
        });
	}

    return(
        <div>
            <div>
				<div style={{marginTop:10, marginLeft: 5, padding:10 }} onClick={()=>{navigate(`/${router}home`)}}>
					<BiArrowBack size={20}/>
				</div>
			</div>
        <div style={{ margin: '20% 10px 10px 10px' }}>
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', padding: '30px 0px' }}>
				<div style={{ fontSize: 25}} className="fonts700">Daftar akun sekarang, yuk!</div>
			</div>
            <div id="sign-in-button"></div>
            <div>
				<Input type="input" value={input.Nama} placeholder="Nama" label="Nama" onChange = {
					(val) => {
                        setInput((prevState) => ({
                            ...prevState,
                            Nama : val.target.value
                        }));
                        localStorage.setItem('register-nama', val.target.value);
					}
				
				}/>
                {/* <Gap height={10} />
				<Input type="input" value={input.Alamat} placeholder="Alamat" label="Alamat" onChange = {
                    (val) => {
                        setInput((prevState) => ({
                            ...prevState,
                            Alamat : val.target.value
                        }));
                        localStorage.setItem('register-alamat', val.target.value);
					}
                    
				}/> */}
                <Gap height={10} />
                <InputPhone placeholder="8123456789" type="input" value={input.NoTelp} label="Nomor Handphone" onChange = {
					(val) => {
                        setInput((prevState) => ({
                            ...prevState,
                            NoTelp : val
                        }));
                        localStorage.setItem('register-telp', val);
					}
				
				}/>

                {/* <button onClick={onSignInSubmit} style={{width: '100%', margin: '30px 0 10px 0', height: 40, borderRadius: 5, border: 'none', backgroundColor: '#5451D6'}}>
                    <span style={{ color: '#fff'}}>Verifikasi No Telp</span>
                </button>  */}
                <Gap height={20}/>
                <Button onClick={(e) => {
                    if (!loading) {
                        onSignInSubmit(e)
                    }
                }} loading={loading} backgroundColor="#5451D6" color="#fff">Verifikasi No Telp</Button>
			</div>

            <div style={{ marginTop: '70%', textAlign: 'center'}}>
                <CopyRight />
            </div>
        </div>
        </div>
    )
}

export default Home