import baseRequest  from "../utils/request"


export default class Gojek extends baseRequest {
    constructor() {
        super();
        this.urls = {
            order: "gojek/order",
            getDistanceAndPrice: "gojek/getDistanceAndPrice",
            getCurrentOrderStatus: "gojek/getCurrentOrderStatus",
        }
    }

    order = params => this.post(this.urls.order, params)
    getDistanceAndPrice = params => this.post(this.urls.getDistanceAndPrice, params)
    getCurrentOrderStatus = params => this.post(this.urls.getCurrentOrderStatus, params)
}