import React, { useEffect } from 'react'
import './ModalBayar.css'
import { AiOutlinePercentage } from "react-icons/ai";
import { BiPlus, BiMinus, BiX,BiArrowBack } from "react-icons/bi";
import { FiShare2 } from "react-icons/fi";
import formatterMoney from '../../utils/formatMoney'
import { encrypt, decrypt } from "../../utils/enkripsi"
import { url } from "../../Constants"
import ImageNotFound from "../../Assets/default-img.jpg"
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from "react-redux"
import { setKeranjang } from "../../redux/actions/prepareActions"
import api from '../../request';
import moment from 'moment';
import Logo from "../../Assets/Logo"
import { baseUrl, router } from "../../Constants"
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom'
import { AiOutlineLeft, AiOutlineExclamationCircle } from "react-icons/ai";
import { Container, Row, Col, Form } from 'react-bootstrap';
import Notes from '../../Assets/Icon/Notes.png';
import Gap from '../../components/Gap';

export default function ModalBayar({ showBayar, setShowBayar, onClickTambahPesanan, dataOrder, updateOrder }) {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);

    const { addToast } = useToasts()
    const [quantity, setQuantity] = React.useState(1)
    const [quantity2, setQuantity2] = React.useState(0)
    const [note, setNote] = React.useState('')
    let pesanan = localStorage.getItem('pesanan')
    pesanan = pesanan == null ? [] : decrypt(JSON.parse(pesanan))
    useEffect(() => {
        if (showBayar) {
            let pesanan1 = decrypt(JSON.parse(localStorage.getItem('pesanan')))
            setNote('')
            if (pesanan1) {
                if (pesanan1.length !== 0) {
                    let number = 1
                    let price = 0
                    for (const x of pesanan1) {
                        if (dataOrder['product_id'] == x['product_id']) {
                            number = x['item']
                            price = x['item']
                            setNote(x['note'])
                        }
                    }
                    setQuantity(number)
                    setQuantity2(price)
                }
            }
        }
    }, [showBayar])

    const handleInputChange = (event) => {
        setQuantity((val) => {
          const newValue = parseInt(event.target.value, 10);
          return isNaN(newValue) ? 1 : newValue; // If the input is not a valid number, default to 1
        });
      };
    function imageExists(url, callback) {
        var img = new Image();
        img.onload = function () { callback(true); };
        img.onerror = function () { callback(false); };
        img.src = url;
    }

    var imageUrl = url + dataOrder['picpath1'];
    imageExists(imageUrl, function (exists) {
        if (exists == true) {
            dataOrder["show_img"] = true
        } else {
            dataOrder["show_img"] = false
        }
    });

    return (
        
        <div>

            {showBayar===true &&
                
                <div>
                <div className={showBayar ? 'modal-menu-Background' : null} onClick={() => showBayar ? setShowBayar(false) : setShowBayar(true)}>
            </div>
            <div className={showBayar ? 'modal-menu active' : 'modal-menu'} >
            {/* <Container className='Share' fluid>
                <Row xs={3} style={{paddingTop:'0.5%', paddingBottom:'0.5%', margin:'auto'}}>
                    <Col xs={3} style={{paddingTop:'1vh', textAlign:'start'}}>
                        <button onClick={() => {
                            setShowBayar(false)
                        }} style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}} >
                            <AiOutlineLeft size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                        </button>
                    </Col>
                    <Col xs={6} style={{margin:'auto', display:'flex', alignItems:'center', fontSize:'20px', color:'white', justifyContent:'center', fontWeight:'600', paddingTop:'1%'}}>Detail Product</Col>
                    
                    <Col xs={3} style={{paddingTop:'1vh', textAlign:'end'}}>
                        <button onClick={() => {
                          addToast(dataOrder['product_name'] + " Berhasil disalin", {
                              appearance: 'success',
                              autoDismiss: true,
                              autoDismissTimeout: 1500,
                          })
                          copy(`${baseUrl}#/home?x2=${encrypt(
                               `${JSON.parse(localStorage.getItem("userServer"))},${brannch.id},${dataOrder.product_id}`
                      )}`)
                  }}  style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                            <FiShare2 size={25} color="#FFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                        </button>
                    </Col>
                </Row>
            </Container> */}
           
        
                        {/* {
                            dataOrder['picpath1'] !== '-' && dataOrder['picpath1'] ?
                            <img style={{width:'100%'}} src={dataOrder.base_url === ''? url + dataOrder['picpath1'] : dataOrder.base_url + dataOrder.picpath1} />
                            :
                            <img src={ImageNotFound} />
                        } */}
                        {
                            dataOrder['picpath1'] !== '-' && dataOrder['picpath1'] ?
                 <div style={{  width:'100%',height:'40%',
                    backgroundImage: dataOrder.base_url === ''?  "url(" + url + dataOrder['picpath1']+ ")" :  "url(" + dataOrder.base_url + dataOrder.picpath1+ ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',marginBottom:'5%'
                    }}>
                <div onClick={() => setShowBayar(false)} 
                        style={{  
                            margin: '0px 0px 0 10px',
                            borderRadius: 5,
                            height: 40, 
                            width: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#fff'
                        }} >
                        <BiArrowBack size={30} color="black"/>
                    </div>
                </div>
                :
                <div style={{  width:'100%',height:'40%',
                backgroundImage: `url(${ImageNotFound})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',marginBottom:'5%'
                }}>
            <div onClick={() => setShowBayar(false)} 
                    style={{  
                        margin: '0px 0px 0 10px',
                        borderRadius: 5,
                        height: 40, 
                        width: 40,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff'
                    }} >
                    <BiArrowBack size={30} color="black"/>
                </div>
            </div>
}
                 <Container className='Body-4'>
                 
                <Row className='Content-title' xs={2}>
                    <Col xs={7} style={{fontSize:'18px',fontWeight:'700'}} className='title'>
                        {dataOrder.product_name}
                    </Col>
                    <Col xs={5} className='price'>
                    <button onClick={() => {
                          addToast(dataOrder['product_name'] + " Berhasil disalin", {
                              appearance: 'success',
                              autoDismiss: true,
                              autoDismissTimeout: 1500,
                          })
                          copy(`${baseUrl}#/home?x2=${encrypt(
                               `${JSON.parse(localStorage.getItem("userServer"))},${JSON.parse(localStorage.getItem('dataBranch')).id},${dataOrder.product_id}`
                      )}`)
                  }}  style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                            <FiShare2 size={25} color="rgba(53, 130, 184, 1)" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                        </button>
                        {/* Rp. {formatterMoney(dataOrder.usePrice)} */}
                    </Col>
                </Row>
                    <Gap height={10}/>
                <Row style={{fontSize:'14px',fontWeight:300}}>
                    <Col>
                        {dataOrder.description_menu}
                    </Col>
                </Row>

                <div className='horizontal-divider'></div>

                <Row className='Content-2'>
                    <Col className='Notes'>
                        <div className='Notes-top'>
                            <div className='Notes-top-title'>
                                {/* <img src={Notes}></img> */}
                                <text>Additional Note</text>
                            </div>
                            <div className='counter-text'>
                                {/* 0/100 */}
                            </div>
                        </div>

                        <div className='Notes-bot'>
                        <textarea value={note} onChange={(val) => setNote(val.target.value)} style={{ height: '80px', width: '95%', marginTop: '20px', marginBottom: '20px', border: '1px solid #BFBFBF', borderRadius: 5 }} placeholder="Notes" />
                        </div>
                    </Col>
                </Row>
                <div style={{ display: 'flex', paddingTop: 20 }}>
                <div style={{ width: '60%', display: 'flex', justifyContent: 'flex-start' }}>
                            <div style={{flexDirection:'row'}}>
                                <div>
                                Harga
                                </div>
                                <div style={{fontSize:'20px',fontWeight:'600'}}>
                                {formatterMoney(quantity*dataOrder.price)}
                                </div>
                            </div>
                        
                        </div>
                        <div style={{ display: 'flex', width: '40%', backgroundColor: '#fff', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button onClick={() => {
                                setQuantity(val => {
                                    if (val > 1) {
                                        return  val - 1
                                    }
                                    else {
                                        return val
                                    }
                                })
                            }} style={{ border: 'none', height: 40, width: 40, border: `1px solid ${quantity !== 1 ? 'rgba(4, 41, 94, 1)' : '#BFBFBF'}`, borderRadius: 10, backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <BiMinus size={20} color={quantity !== 1 ? "rgba(4, 41, 94, 1)" : "#BFBFBF"}/>
                            </button>
                            <input value={quantity != 0 ? quantity : 1 } onChange={handleInputChange} style={{
                            fontSize:'20px',
                            width: '100%',
                            textAlign: 'center',
                            border: `1px solid transparent`,
                            borderRadius: '10px',
                            }}></input>
                            <button onClick={() => {
                                setQuantity((val) => {
                                    return val + 1
                                })
                            } } style={{ border: 'none', height: 40, width: 40, border: '1px solid rgba(4, 41, 94, 1)', borderRadius: 10, backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <BiPlus size={20} color="rgba(4, 41, 94, 1)" />
                            </button>
                        </div>
                        
                    </div>
            {/* <Container fluid className='BottomBarTwoItem3'>
                <Row className='Container'>
                    <Col xl={3} lg={9} md={9} sm={9} xs={8}>
                    <div>
                       Harga
                       </div>
                       <div>
                       {formatterMoney(quantity*dataOrder.price)}
                       </div>
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={3} xs={4}>
                        <div className='counter'>
                            <button onClick={() =>{
                                setQuantity(val => {
                                    if (val > 1) {
                                        return val - 1
                                    }
                                    else {
                                        return val
                                    }
                                })
                            }}>-</button>
                            
                            <span>{quantity != 0 ? quantity : 1} </span>
                            
                            <button onClick={() =>{
                                setQuantity((val) => {
                                    return val + 1
                                })
                            }}>+</button>
                        </div>
                    </Col>
                </Row>
            </Container> */}
            <Gap height={30}/>
            <div className='price-add'>
                            <button onClick={
                                async () => {
                                    try {
                                        const response = await api.order.soldOut({
                                            "product_id": dataOrder?.product_id,
                                            "today": moment().format('YYYY-MM-DD')
                                        })
                                
                                        const res = response.data
                                        if (res.sold_out) {
                                            addToast("Barang sudah soldout", {
                                                appearance: 'error',
                                                autoDismiss: true,
                                                autoDismissTimeout: 1500,
                                            })
                                        }
                                        else {
                                            let name = []
                                            for(const xi of pesanan){
                                                if (xi['product_id'] == dataOrder['product_id']) {
                                                    name.push(xi['product_id'])
                                                }
                                            }
                                            if (name.includes(dataOrder['product_id'])) {
                                                for(const resultOrder of pesanan){
                                                    if (resultOrder['product_id'] == dataOrder['product_id']) {
                                                        resultOrder['item'] = quantity 
                                                        resultOrder['total'] = dataOrder['usePrice'] * quantity
                                                    }
                                                }
                                            }
                                            else {
                                                pesanan.push(Object.assign(dataOrder, {
                                                    item: quantity,
                                                    total: dataOrder['usePrice'] * quantity,
                                                    note: note
                                                }))
                                            }
                                            const resultPesanan = pesanan.filter(items => items['item'].toString() !== '0')
                                            localStorage.setItem('pesanan', JSON.stringify(encrypt(resultPesanan)))
                                            dispatch(setKeranjang())
                                            if (quantity > quantity2) {
                                                addToast("Pesanan sudah ditambah", {
                                                    appearance: 'success',
                                                    autoDismiss: true,
                                                    autoDismissTimeout: 1500,
                                                })
                                            }
                                            setIsLoading(false);
    
                                            setShowBayar(false)
                                        }
                                    } catch (error) {
                                        console.log(error)
                                    }
                                    

                                    

                                }} disabled={isLoading}>
                                {isLoading ? (
                                    <span className='spinner' />
                                    ) : (
                                    <a>Tambahkan Rp. {formatterMoney(quantity*dataOrder.price)}</a>
                                )}
                            </button>
                        </div>
            </Container>

              
            </div>
            </div>}
            
        </div>
        //   </div>
    )
}