import React from "react";


import "./LoadingFull.css"

export default function LoadingFull(){

    return (
        <div className="loading-menu">
            <div className="spinner">
              <span style={{ color: '#04295E', fontSize: 23}}>Loading...</span>
              <div className="half-spinner"></div>
            </div>
        </div>
    )
}