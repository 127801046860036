import baseRequest  from "../utils/request"

export default class Guest extends baseRequest {
    constructor() {
        super();
        this.urls = {
            getAll: "mas_guest/getAllWithoutToken",
            get1: "mas_guest/get1WithoutToken",
            save: "mas_guest/saveWithoutToken",
            
        }
    }
    getAll = params => this.post(this.urls.getAll, params)
    get1 = params => this.post(this.urls.get1, params)
    save = params => this.post(this.urls.save, params)
}