import React from 'react'
import { Form, FormGroup, InputGroup } from 'react-bootstrap'

export default function InputPhone({ value, label, style, backgroundColor = '#fff', placeholder, onChange, type, disabled, onBlur }) {
    function onChangeInput(val) {
        const input = val.target.value
        if (isNaN(input)) {
            onChange('')
        }
        else {
            if ((value.length < 1 && input == 0) || (value.length < 2 && input == '62')) {
                onChange('')
            }
            else {
                onChange(input)
            }
        }
    }
    return (
        <div style={style}>
            {/* {
                label &&
                <div className="fonts500" style={{ paddingBottom: 5, fontSize: 15}}></div>
            } */}
            
            <Form.Group className="Form" >
                {
                    label &&
                    <Form.Label className='title'>{label}</Form.Label> 
                }

                <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend" style={{backgroundColor:'white', border:'0px'}}>+62</InputGroup.Text>
                    
                    <Form.Control required onBlur={onBlur} disabled={disabled} value={value} type={type} onChange={onChangeInput} placeholder="8123XXXX" />
                    
                    <Form.Control.Feedback type="invalid">
                        Please insert telephone number.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>

        </div>
    )
}