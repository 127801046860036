import React from 'react'
import Navbar from '../../components/Navbar'
import { BsFillBookmarkFill } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { getFirestore, doc, getDoc, setDoc, arrayRemove, updateDoc, deleteDoc } from "firebase/firestore";
import Loading from '../../components/Loading'
import { router } from "../../Constants"
import { Container, Row, Col } from 'react-bootstrap';

export default function AlamatPengiriman({location}) {
    let navigate = useNavigate()
    const [dataAlamat, setDataAlamat] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    let phone = JSON.parse(localStorage.getItem('login'))
    const addressUser  = JSON.parse(localStorage.getItem('without'))  
    let alamat_users = localStorage.getItem('alamatUsers')
    if (alamat_users == null) {
        alamat_users = null
    }
    else {
        alamat_users = JSON.parse(alamat_users)
    }
    console.log(alamat_users)
    async function onRefresh() {
        setLoading(true)
        if (!phone) {  
            const addressUser  = JSON.parse(localStorage.getItem('without'))  
            if (addressUser) {          
                setDataAlamat([{
                     type: addressUser['alamatSebagai'],
                     alamat: `${addressUser.alamatLengkap} ${addressUser.kecamatan}, ${addressUser.kabupaten}, ${addressUser.provinsi} ${addressUser.kodePos}, ${addressUser.negara}`,
                     detailAlamat: `${addressUser.detailAlamat}`,
                     name: addressUser.namaPenemerima,
                     nomerHp:addressUser.nomerHp,
                     lat: addressUser['latitude'],
                     lng: addressUser['longitude'],
                     dataWithout: addressUser
                 }])   
            }
        }
        else {
            const db = getFirestore();
            const docRef  = doc(db, "address", phone.telp);
            const docSnap = await getDoc(docRef);
            const docData = docSnap.data()
            if (docData) {            
                if (docData['alamat'].length !== 0) {        
                    let data = []
                    for(const items of docData['alamat']){
                        data.push({
                            type: items.alamatSebagai,
                            alamat: `${items.alamatLengkap} ${items.kecamatan}, ${items.kabupaten}, ${items.provinsi} ${items.kodePos}, ${items.negara}`,
                            detailAlamat: `${items.detailAlamat}`,
                            name: items.namaPenemerima,
                            nomerHp:items.nomerHp,
                            lat: items['latitude'],
                            lng: items['longitude'],
                            email: items.email,
                            dataWithout: items
                        })
                    }
                    setDataAlamat(data)
                }
                else {
                    setDataAlamat([])
                    localStorage.removeItem('alamatUsers')
                    localStorage.removeItem('saveDelivery')
                }
            }
            else {
                setDataAlamat([])
                localStorage.removeItem('alamatUsers')
                localStorage.removeItem('saveDelivery')
            }
        }
        setLoading(false)
    }
    React.useEffect( async () => {
        onRefresh()
    },[])


    function onClick(items) {
        localStorage.setItem('alamatUsers', JSON.stringify(items))
        const xi = JSON.parse(localStorage.getItem('longlat'))
        if (!xi) {     
            localStorage.setItem('longlat', false)
        }
        else {
            if (items.lat !== xi.lat && items.lng !== xi.lng) {
                localStorage.setItem('longlat', false)
            }
            else {
                localStorage.setItem('longlat', true)
            }
        }
        navigate(`/${router}detail-order`)
    }

    async function onDelete(items, index) {
        let users = JSON.parse(localStorage.getItem('login'))
        const db = getFirestore();
        const docRef  = doc(db, "address", phone.telp);
        const docSnap = await getDoc(docRef);
        let si = docSnap.data()
        let data = []
        for (let i = 0; i < si.alamat.length; i++) {
            const element = si.alamat[i];
            if (i !== index) {
                data.push(element)
            }
        }
        setDoc(doc(db, "address", users.telp), { nama : users['nama'], alamat: data }, { merge: true })
        .then((res) => {
            onRefresh()
        }).catch(error => console.log(error.response));
        // onRefresh()
    }

    return (
        <div>
            <Navbar urlBack="detail-order" type="Add-Address" label="Pilih Alamat"/>
            
            <div style={{width:'100%', backgroundColor:'white'}} className='Body-6' >
                
                {
                    dataAlamat.map((items, index) => {
                        return (
                            <div key={index}>
                                <div style={{marginLeft:'10px'}}>
                                    <Container style={{marginTop:'2vh', marginBottom:'2vh'}} onClick={() => onClick(items)}>
                                        <Row className="fonts700" style={{ fontSize: 14}}>{items['type']}</Row>
                                        <Row className="fonts400" style={{ fontSize: 12, color: '#BFBFBF', marginTop: 5}}>{items['alamat']}</Row>
                                        <Row className="fonts400" style={{ fontSize: 13, marginTop: 10}}>Detail Alamat</Row>
                                        <Row className="fonts400" style={{ fontSize: 12, marginTop: 5, color: '#BFBFBF'}}>{items['detailAlamat']}</Row>
                                    </Container>
                                    <Container style={{ textAlign:'end', display:'flex', justifyContent:'flex-end'}}>
                                        <div className="fonts500" style={{ marginLeft:'2%', marginRight:'2%', color:"#5451D6", fontSize: 14, cursor:'pointer'}} 
                                            onClick={() => navigate(`/${router}add-alamat-pengiriman`, {state:{
                                                position: {
                                                    lat: items.dataWithout.latitude,
                                                    lng: items.dataWithout.longitude
                                                },
                                                addressUsers: items,
                                                type: 'edit'
                                            }})} 
                                            >Edit</div>

                                        <div className="fonts500" style={{ marginLeft:'2%', marginRight:'2%', color:"red", fontSize: 14, cursor:'pointer'}} onClick={() => onDelete(items, index)}>Hapus</div>
                                    </Container>
                                </div>
                                
                                <div className='horizontal-divider'></div>

                            </div>
                        )
                    })
                }
                
                <div >
                    
                    {
                        loading &&
                        <Loading/>
                    }
                </div>

                <div style={{margin:'4%'}}>
                    <button onClick={() => navigate(`/${router}add-alamat-pengiriman`, { state:{ type: 'new',
                        addressUsers: {}}
                        
                    })} style={{ width: '100%', padding: '10px 0 10px 0', border: '1px solid #5451D6', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F3F3FF'}}>
                        <span style={{ fontSize: 13, color: '#5451D6', marginLeft: 5}} className="fonts500">
                            Tambah Alamat Baru
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}