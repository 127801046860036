import React from "react";

export default function Logo({ height = "20", width = "75"}) {
    return (
        <svg width={width} height={height} viewBox="0 0 158 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.7129 34.4457V17.2719H73.0969C73.8341 17.2719 74.4532 16.9914 75.0135 16.4616C75.5442 15.9005 75.8095 15.2148 75.8095 14.4045V11.537H54.1381C52.6049 11.537 51.3371 10.9759 50.2756 9.88505C49.2436 8.76299 48.7129 7.39159 48.7129 5.802H81.2348V17.2719C81.2348 18.8927 80.7041 20.2329 79.6131 21.355C78.5517 22.4459 77.2838 23.0069 75.8095 23.0069H70.3843L81.2348 34.4769H73.0969L62.2465 23.0069H54.1086V34.4769H48.7129V34.4457Z" fill="#5451D6"/>
            <path d="M86.6313 28.74V5.8313H92.0566V28.74H86.6313ZM92.0566 34.4438C90.5234 34.4438 89.2555 33.8828 88.194 32.7919C87.1621 31.6698 86.6313 30.2984 86.6313 28.7088H119.153C119.153 30.3296 118.623 31.6698 117.532 32.7919C116.47 33.8828 115.202 34.4438 113.728 34.4438H92.0566ZM113.728 29.9556V5.8313H119.153V28.74L113.728 29.9556Z" fill="#5451D6"/>
            <path d="M124.577 34.4457L146.249 11.537H130.002C128.469 11.537 127.201 10.9759 126.14 9.88505C125.108 8.76299 124.577 7.39159 124.577 5.802H157.099V8.66948L138.111 28.7419H151.644C153.148 28.7419 154.416 29.3029 155.448 30.4561C156.509 31.5782 157.07 32.9184 157.07 34.4768H124.577V34.4457Z" fill="#5451D6"/>
            <path d="M33.1498 0.0512695C33.1498 0.0512695 25.9551 2.92483 29.4497 9.78344C32.8415 16.4523 36.85 20.2747 38.3661 25.8049C38.3661 25.8049 40.8072 31.4978 32.7387 37.4347C30.2205 39.2782 25.7238 41.3384 20.1992 38.8444V30.4135C20.1992 30.4135 26.0578 30.8472 28.7816 28.3261C31.0171 26.2658 30.606 20.5186 27.7537 16.2625C27.7256 16.2205 18.0665 3.14171 33.1498 0.0512695Z" fill="#FFD12D"/>
            <path d="M5.56306 0C5.56306 0 12.7578 2.87357 9.26324 9.73217C5.87141 16.401 1.86289 20.2234 0.346846 25.7537C0.346846 25.7537 -2.09424 31.4466 5.97419 37.3835C8.49237 39.2269 12.9891 41.2872 18.5137 38.7931V30.3622C18.5137 30.3622 12.6551 30.7959 9.93132 28.2748C7.6958 26.2145 8.10693 20.4674 10.9591 16.2112C10.9873 16.1693 20.6464 3.09044 5.56306 0Z" fill="#FFD12D"/>
        </svg>

    )
}