import React from 'react'
import Button from '../../components/Button'
import Gap from '../../components/Gap'
import Text from '../../components/Text'
import { AiFillCheckCircle, AiOutlineFieldTime, AiOutlineCloseCircle } from "react-icons/ai"
import FETCH_API from '../../config/fetch_api'
import { formatDate, formatMinutes } from '../../utils/formatDate'
import formatterMoney from '../../utils/formatMoney'
import { dataPay } from "../../utils/pay"
import { useNavigate,useLocation } from "react-router-dom"
import { useToasts } from 'react-toast-notifications'
import Loading from '../../components/Loading'
import { send_email, proses_data } from "../../config/config"
import { mode, router } from "../../Constants"
import moment from "moment"
import { encrypt, decrypt } from "../../utils/enkripsi"
import api from "../../request"
import { useSelector } from "react-redux"
import { baseUrl } from '../../Constants'
import { SocketOnline } from "../../utils/socket";

function TransactionProcessed() {
    const { addToast } = useToasts()
    const navigate = useNavigate()
    const location = useLocation()
    const { state } = location
    console.log(state)
    const [data, setData] = React.useState()
    const [loading, setLoading] = React.useState(true)
    const [ovo, setOvo] = React.useState(false)
    const user = JSON.parse(localStorage.getItem("alamatUsers"))
    const login = JSON.parse(localStorage.getItem("login"))
    const orderDate = JSON.parse(localStorage.getItem("orderDate"))
    const branch = JSON.parse(localStorage.getItem('dataBranch'))
    const alamat = JSON.parse(localStorage.getItem('alamatUsers'))
    const pickup_delivery = localStorage.getItem('pickup_delivery')
    const metodeID = localStorage.getItem('metodeID')
    const metode = localStorage.getItem('metode')
    const userServer = localStorage.getItem('userServer')
    const id = JSON.parse(localStorage.getItem("idpesanan"))
    const [timeoutId, setTimeoutId] = React.useState(null);

    let detailBayar = decrypt(localStorage.getItem("detailDataOnBayar"))

    // console.log('>>' + metodeID)
    let without = ""
    if (pickup_delivery == "delivery") {
        without = alamat.dataWithout
    }

    React.useEffect(async () => {
        // console.log(process.env.NODE_ENV)
        checkStatus('1')
        // console.log(detailBayar.dataDetailData['sub_total'])
        // cekSuccess()
        // console.log('>>'+)
    }, [])

    async function cekSuccess(result) {
        try {
            let total = 0,
            namaProduk = ''
        let detailBayar = decrypt(localStorage.getItem("detailDataOnBayar"))
        const id = JSON.parse(localStorage.getItem("idpesanan"))
        const detailData = []
    
        for (const items of state.detailTrans) {
            detailData.push({
                "namaProduct": items['name'],
                "qty": items['qty'],
                "price": items['price'],
                "subtotal": items['total']
            })
        }

        let nomerHp = ''
        if (localStorage.getItem('login')) {
            nomerHp = login.telp
        }
        else {
            nomerHp = orderDate.nomerHp
        }
        const getData = await api.order.getDetailData({
            "noTelp": nomerHp,
            "id": id
        })
        // console.log('getData >>',(getData))
        let pesanan = getData?.data?.data
        for (const items of pesanan) {
            total += (items['price'] * items['qty'])
            namaProduk += items.name + " "
        }
        // console.log(pesanan)

        // let result = await api.apiPay.save({
        //     "from_origin": "*",
        //     "userServer": "-",
        //     "transMode": "edit",
        //     "userId": "-",
        //     "detailData": JSON.stringify(detailBayar.dataDetailData),
        //     "detailTrans": JSON.stringify(detailBayar.detailTrans),
        //     "discountsNote": [],
        //     "chargesNote": JSON.stringify(detailBayar.local_charges),
        //     "transCode": "",
        //     "transId": ""
        // })

               
            //    const a = encrypt(resultSave)
            //     console.log(a)
            //     const b = decrypt(a)
            //     console.log(b)
            //     const data = await api.xendit.paymentTrans({
            //         "data": a
            //     })

            //     console.log(data)

            //     let resultSave = await api.apiPay.save({
            //         "userId": "-",
            //         "transMode": "edit",
            //         "detailData": JSON.stringify(detailBayar.dataDetailData),
            //         "detailTrans": JSON.stringify(detailBayar.detailTrans),
            //         "discountsNote": "[]",
            //         "chargesNote": JSON.stringify(detailBayar.local_charges),
            //         "from_origin": "*",
            //         "userServer": userServer,
            //         "transCode": "so",
            //         "transId": id,
            //         "userId": "-"
            //     })
            //    const a = encrypt(resultSave)
            //     console.log(a)
            //     const b = decrypt(a)
            //     console.log(JSON.stringify(b))
            //     const data = await api.xendit.paymentTrans({
            //         "data": a
            //     })

            //     console.log(data)

            // TRANS-NOBU
            // console.log('>>'+JSON.stringify(detailBayar.dataDetailData.total))

            // const enkrip = JSON.stringify({
            //     "userId": "-",
            //     "transMode": "edit",
            //     "detailData": JSON.stringify(detailBayar.dataDetailData),
            //     "detailTrans": JSON.stringify(detailBayar.detailTrans),
            //     "discountsNote": "[]",
            //     "chargesNote": JSON.stringify(detailBayar.local_charges),
            //     "from_origin": "*",
            //     "userServer": userServer,
            //     "transCode": "so",
            //     "transId": id,
            // })
            // console.log(enkrip)

            // // const dataenkrip = encrypt(enkrip)
            // const dataenkrip = await FETCH_API("encryption/encrypt", {
            //     "string": enkrip
            // })
            // // const dataenkrip = await fetch("https://cool.uruz.id/api/encryption/encrypt",{
            // //     "string":enkrip
            // // })
            // console.log('enkrip >>>  '+dataenkrip.data)
            // // console.log(decrypt(dataenkrip))


            // let resultSave = await api.xendit.paymentTrans({
            //     "data": dataenkrip.data,
            //     "transId": id,
            //     "amount": JSON.stringify(detailBayar.dataDetailData.total)
            // })

            // TRANS-NOBU



            // console.log('---->>>> ' + JSON.stringify(resultSave))
            // const datadekrip = await FETCH_API("encryption/decrypt", {
            //     "string": dataenkrip
            // })
            // let resultSave = await api.xendit.paymentTrans({
            //     "userId": "-",
            //     "transMode": "edit",
            //     "detailData": JSON.stringify(detailBayar.dataDetailData),
            //     "detailTrans": JSON.stringify(detailBayar.detailTrans),
            //     "discountsNote": "[]",
            //     "chargesNote": JSON.stringify(detailBayar.local_charges),
            //     "from_origin": "*",
            //     "userServer": userServer,
            //     "transCode": "so",
            //     "transId": id,
            //     "userId": "-"
            // })
                const process = await proses_data()
                // for(const items of pesanan){
                //     detailData.push({
                //         "namaProduct" : items['name'],
                //         "qty": items['qty'],
                //         "price": items['price'],
                //         "subtotal" : items['price'] * items['qty'] + ((10/100)*(items['price'] * items['qty']))
                //     })
                // }


            // if (resultSave.data.status == "OK") {
            //     // const datadekrip = await FETCH_API("encryption/decrypt", {
            //     //     "string": dataenkrip
            //     // })
            //     // console.log(JSON.stringify(datadekrip))
            //     for (const items of pesanan) {
            //         detailData.push({
            //             "namaProduct": items['name'],
            //             "qty": items['qty'],
            //             "price": items['price'],
            //             "subtotal": items['price'] * items['qty']
            //         })
            //     }
                // if (resultSave.data.status == "OK") {
                    console.log(process)
                  
                // if (datadekrip.status == "Success") {
                    // console.log(dataPay.filter((items) => items.id == metodeID)[0]['name'])
                    send_email({
                        "subject": 'Pembayaran Uruz',
                        "carabayar": metode,
                        "idTransaksi": id,
                        "subTotal": result.hasil.charge_amount,
                        "grandTotal": result.hasil.charge_amount,
                        "linkLihatDetail": `${baseUrl}${router}`,
                        "detailData": detailData
            
                    })
                    addToast("Pembayaran Berhasil", {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    checkStatus('3')
                    SocketOnline.emit('delivery', {
                        branch_id   : JSON.parse(localStorage.getItem('dataBranch')).id,
                        userServer  : userServer,
                        id          : id,
                        type        : localStorage.getItem('type_delivery') 
                    });
                // else {
                //     addToast("Maaf ada kesalahan sistem, mohon coba lagi", {
                //         appearance: 'error',
                //     })
                //     setLoading(false)
                // }
                setLoading(false)
                return
    
        } catch (error) {
            console.log(error)
        }
        
       
    }

    async function checkStatus(params) {
        try {
            const userServer = JSON.parse(localStorage.getItem("userServer"))
        const id = JSON.parse(localStorage.getItem("idpesanan"))
        const id_wallet = JSON.parse(localStorage.getItem("id_wallet"))
        console.log('id wallet == ' + id_wallet)
        let nomerHp = {}
        if (login) {
            nomerHp.nomerHp = login.telp
        }
        else {
            nomerHp.nomerHp = orderDate.nomerHp
        }
        const res = await api.xendit.check({
            "id": id_wallet,
            "mode": 'prod'
        })
    
        // const result = JSON.parse(res?.config.data)
        console.log('++' + JSON.stringify(res))
        const result = res?.data
        console.log(result)
        if (result.status == "OK") {
            setData(result.hasil)
            console.log("checkSuccessWalletXendit1", result)
            if (params == '1') {
                if (result.hasil.channel_code == 'ID_OVO') {
                    setOvo(true)
                }
            }
            if (result.hasil.status == "SUCCEEDED" && params !== '3') {
                console.log("checkSuccessWalletXendit2", result)
                cekSuccess(result)
            }
            else {
                setLoading(false)
                if (params == '2') {
                    console.log("checkSuccessWalletXendit4", result)
                    addToast("Mohon untuk menyelesaikan pembayaran anda.", {
                        appearance: 'info',
                        autoDismiss: true,
                    })
                }
            }
        }
        } catch (error) {
                console.log(error)
        }
        
    }
    React.useEffect(() => {
        // GojekStatus()
        // GojekUpdateStatus()
    
        console.log('mengapa ini?', SocketOnline)
    
        SocketOnline.on('connect', () => {
  console.log('Socket connected');
  // Additional logic after the connection is established
});

SocketOnline.on("connect_error", (err) => {
          console.log(`aku error guys ${err}`);
        });
   
        SocketOnline.on("payment-success", (data) => {
          console.log("cobaa1", data);
          const timeout = setTimeout(() => {
                checkStatus('1')
                      
        }, 3000); // 3000 milliseconds = 3 seconds
        setTimeoutId(timeout);
        // Clean up the timeout when the component unmounts
        return () => {
          clearTimeout(timeout);
        };
        });
    }, []);
    return (
        <div style={{ position: 'fixed', height: '100%', width: '100%' }}>
            <div onClick={() => navigate(`/${router}home`)} style={{ margin: "10px 10px 0" }}>
                <AiOutlineCloseCircle size={30} color="#8B78FF" />
            </div>
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: '80%', margin: "10px 10px 0", }}>
                {
                    !loading ?
                        <div style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', padding: 25, borderRadius: 10 }}>
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    data?.status == "SUCCEEDED" ?
                                        <AiFillCheckCircle size={70} color="#03B134" />
                                        :
                                        <AiOutlineFieldTime size={70} color="#f0de16" />
                                }
                            </div>
                            <Gap height={10} />
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', }}>
                                {
                                    data?.status == "SUCCEEDED" ?
                                        <Text className="fonts700" style={{ fontSize: 15, }}>Transaksi sukses</Text>
                                        :
                                        <Text className="fonts700" style={{ fontSize: 15, }}>Transaksi sedang diproses</Text>
                                }
                            </div>
                            <Gap height={20} />
                            {
                                ovo && data?.status === "SUCCEEDED" &&
                                <div style={{ backgroundColor: '#8B78FF', borderRadius: 5 }}>
                                    <div style={{ padding: '10px 10px' }}>
                                        <span className="fonts700" style={{ color: "#fff" }}>Silahkan lakukan pembayaran menggunakan aplikasi OVO anda.</span>
                                    </div>
                                </div>
                            }
                            <Gap height={50} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <Text className="fonts700" >Tanggal</Text>
                                    <Text className="fonts700" >Metode Pembayaran</Text>
                                    <Text className="fonts700" >Total Pembayaran</Text>
                                </div>
                                <div style={{ textAlign: 'end' }}>
                                    <Text className="fonts700" >{formatDate(data?.created)}</Text>
                                    <Text className="fonts700" >{dataPay.filter((items) => items.id == data?.channel_code)[0]?.name}</Text>
                                    <Text className="fonts700" >{formatterMoney(data?.charge_amount)}</Text>
                                </div>
                            </div>
                            <Gap height={70} />
                            <Button loading={loading} onClick={() => {
                                if (data?.status !== "SUCCEEDED") {
                                    setLoading(true)
                                    checkStatus('2')
                                }
                                else {
                                    navigate(`/${router}home/lacak-pesanan`)
                                }
                            }} backgroundColor="#5451D6" color="#fff">{data?.status !== "SUCCEEDED" ? 'Cek Status' : 'Lacak Pesanan'}</Button>
                        </div>
                        : <Loading />
                }
            </div>
        </div>
    )
}

export default TransactionProcessed

