import { SET_GOJEK_ORDER, SET_GOJEK_GET_DISTANCE_AND_PRICE, SET_GOJEK_STATUS } from "../constants/gojekConstants"
import api from "../../request"

export const gojekOrder = () => async dispatch => {
    try {
        const result = await api.gojek.order()
        dispatch({type: SET_GOJEK_ORDER, value: result});
    } catch (error) {
    }
}
export const getDistanceAndPrice = (params) => async dispatch => {
    try {
        const result = await api.gojek.getDistanceAndPrice(params)
        dispatch({type: SET_GOJEK_GET_DISTANCE_AND_PRICE, value: result?.data?.hasil?.Instant});
    } catch (error) {
    }
}
export const getCurrentOrderStatus = (params) => async dispatch => {
    try {
        const result = await api.gojek.getCurrentOrderStatus(params)
        dispatch({type: SET_GOJEK_STATUS, value: Object.values(result?.data?.hasil)});
    } catch (error) {
    }
}