import baseRequest  from "../utils/request"

export default class Order extends baseRequest {
    constructor() {
        super();
        this.urls = {
            soldOut: "sold-out-v2/sold-out",
            coa_branch: "coa-web/get-default-branch-all",
            getDetailData: "trans-s-so-web/get"
        }
    }
    soldOut = params => this.post(this.urls.soldOut, params)
    coa_branch = params => this.post(this.urls.coa_branch, params)
    getDetailData = params => this.post(this.urls.getDetailData, params)
}