import { SET_GET_CHARGES } from "../constants/chargesConstants"

let initialState = { 
    getCharges : {}
};


export const chargesReducer = (state = initialState , action) => {
    if (action.type ===  SET_GET_CHARGES ) {
        return {
            ...state,
            getCharges: action.value
        }
    }
    return state
}