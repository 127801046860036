import React from "react";
import "./Loading.css"

function Loading(params) {
    return (
      <div className="spinner">
        <span style={{ color: '#04295E', fontSize: 23}}>Loading...</span>
        <div className="half-spinner"></div>
      </div>
    )
}

export default Loading