import React, { useEffect, useState } from 'react';
import '../../Pages/Order/DetailProduct.css';
import Navbar from '../../components/Navbar'

import './ModalBayar.css'
import { AiOutlinePercentage } from "react-icons/ai";
import { BiPlus, BiMinus, BiX } from "react-icons/bi";
import { FiShare2 } from "react-icons/fi";
import formatterMoney from '../../utils/formatMoney'
import { encrypt, decrypt } from "../../utils/enkripsi"
import { url,router } from "../../Constants"
import ImageNotFound from "../../Assets/default-img.jpg"
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from "react-redux"
import { setKeranjang } from "../../redux/actions/prepareActions"
import api from '../../request';
import moment from 'moment';
import Logo from "../../Assets/Logo"
import { baseUrl } from "../../Constants"
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom'
import { AiOutlineLeft, AiOutlineExclamationCircle } from "react-icons/ai";
import { Container, Row, Col, Form } from 'react-bootstrap';
import Notes from '../../Assets/Icon/Notes.png';


export default function DetailProduct(items) {
    console.log(items)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const dataOrder = items.location.state.items
    console.log(dataOrder)
    const { addToast } = useToasts()
    const [quantity, setQuantity] = React.useState(1)
    const [quantity2, setQuantity2] = React.useState(0)
    const [note, setNote] = React.useState('')
    let pesanan = localStorage.getItem('pesanan')
    let brannch = JSON.parse(localStorage.getItem('dataBranch'))
    pesanan = pesanan == null ? [] : decrypt(JSON.parse(pesanan))

    useEffect(() => {
        let pesanan1 = decrypt(JSON.parse(localStorage.getItem('pesanan')))
        setNote('')
        if (pesanan1) {
            if (pesanan1.length !== 0) {
                let number = 1
                let price = 0
                for (const x of pesanan1) {
                    
                }
                setQuantity(number)
                setQuantity2(price)
            }
        }
        
    }, [])

    function test(){
        const shareData = {
            title: 'MDN',
            text: 'Learn web development on MDN!',
            url: 'https://developer.mozilla.org'
          }
          
          const btn = document.querySelector('button');
          const resultPara = document.querySelector('.result');
          
          // Share must be triggered by "user activation"
          btn.addEventListener('click', async () => {
            console.log('masuk')
            try {
              await navigator.share(shareData)
              resultPara.textContent = 'MDN shared successfully'
            } catch (err) {
              resultPara.textContent = `Error: ${err}`
            }
          });
          
    }

    function imageExists(url, callback) {
        var img = new Image();
        img.onload = function () { callback(true); };
        img.onerror = function () { callback(false); };
        img.src = url;
    }

    // var imageUrl = url+dataOrder['picpath1'];
    // imageExists(imageUrl, function(exists) {
    //     if (exists == true) {
    //         dataOrder["show_img"] = true
    //     }else{
    //         dataOrder["show_img"] = false
    //     }
    // });

    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className='Main'>            
            {/* <div className="navBar" style={{ backgroundColor: "#fff" }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%'}}>     
                        {
                                <button onClick={() => {
                                        navigate.goBack()
                                   
                                }} style={{ marginLeft: 10, border: 'none', backgroundColor: 'transparent'}} >
                                    <AiOutlineLeft  size={25} color="#552CB7"/>
                                </button>
                            
                        }
                        {
                           
                                <span className="fonts500" style={{ marginLeft: 10, fontSize: 18 }}>
                                    {"Product Details"}
                                </span>
                            
                        }
                       
                    </div>
           
                    {
                        <div onClick={() => {
                                addToast(dataOrder['product_name'] + " Berhasil disalin", {
                                    appearance: 'success',
                                    autoDismiss: true,
                                    autoDismissTimeout: 1500,
                                })
                                copy(`${baseUrl}delivery/home?x2=${encrypt(
                                    {
                                        userServer: 'uruz2', 
                                        cabang: brannch, 
                                        produk: dataOrder
                                    }
                                )}`)
                            }} 
                            style={{  
                                margin: '10px 10px 0 0px',
                                borderRadius: 20,
                                height: 30, 
                                width: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                padding: 5
                            }} >
                            
                        </div>
                    }
                </div>
            </div> */}
 {/* {
                      
                      <div onClick={() => {
                          addToast(dataOrder['product_name'] + " Berhasil disalin", {
                              appearance: 'success',
                              autoDismiss: true,
                              autoDismissTimeout: 1500,
                          })
                          copy(`${baseUrl}delivery/detailproduct?x2=${encrypt(
                          {
                              userServer: JSON.parse(localStorage.getItem("userServer")), 
                              cabang: brannch, 
                              produk: dataOrder
                      })}`)
                  }} 
                      style={{  
                          margin: '10px 10px 0 0px',
                          borderRadius: 20,
                          height: 30, 
                          width: 30,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                          padding: 5
                      }} >
                      <FiShare2 size={30} color="#fff"/>
                  </div>
                  
              } */}
            <Container className='Share' fluid>
                <Row xs={3} style={{paddingTop:'0.5%', paddingBottom:'0.5%', margin:'auto'}}>
                    <Col xs={3} style={{paddingTop:'1vh', textAlign:'start'}}>
                        <button onClick={() => {
                            navigate.goBack()
                        }} style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}} >
                            <AiOutlineLeft size={25} color="#FFFFFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                        </button>
                    </Col>
                    <Col xs={6} style={{margin:'auto', display:'flex', alignItems:'center', fontSize:'20px', color:'white', justifyContent:'center', fontWeight:'600', paddingTop:'1%'}}>Detail Product</Col>
                    
                    <Col xs={3} style={{paddingTop:'1vh', textAlign:'end'}}>
                        <button onClick={() => {
                          addToast(dataOrder['product_name'] + " Berhasil disalin", {
                              appearance: 'success',
                              autoDismiss: true,
                              autoDismissTimeout: 1500,
                          })
                          copy(`${baseUrl}delivery/#/home?x2=${encrypt(
                          {
                              userServer: JSON.parse(localStorage.getItem("userServer")), 
                              cabang: brannch, 
                              produk: dataOrder
                      })}`)
                  }}  style={{backgroundColor:'transparent', border:'#fff solid 2px', height:'40px', width:'40px', borderRadius:'10%', cursor:'pointer'}}>
                            <FiShare2 size={25} color="#FFF" style={{margin:'auto', alignItems:'center', textAlign:'center', display:'flex'}}/>
                        </button>
                    </Col>
                </Row>
            </Container>
            
            <Container className='Body-4'>
                <Row className='product-Img'>
                    <Col>
                        {
                            dataOrder['picpath1'] !== '-' && dataOrder['picpath1'] ?
                            <img src={url+dataOrder.picpath1} />
                            :
                            <img src={ImageNotFound} />
                        }
                    </Col>
                </Row>

                <Row className='Content-title' xs={2}>
                    <Col xs={7} className='title'>
                        {dataOrder.product_name}
                    </Col>
                    <Col xs={5} className='price'>
                        Rp. {formatterMoney(dataOrder.usePrice)}
                    </Col>
                </Row>
                
                <Row className='Menu-desc'>
                    <Col>
                        {dataOrder.description_menu}
                    </Col>
                </Row>

                <div className='horizontal-divider'></div>

                <Row className='Content-2'>
                    <Col className='Notes'>
                        <div className='Notes-top'>
                            <div className='Notes-top-title'>
                                <img src={Notes}></img>
                                <text>Additional Note</text>
                            </div>
                            <div className='counter-text'>
                                {/* 0/100 */}
                            </div>
                        </div>

                        <div className='Notes-bot'>
                        <textarea value={note} onChange={(val) => setNote(val.target.value)} style={{ height: '80px', width: '95%', marginTop: '20px', marginBottom: '20px', border: '1px solid #BFBFBF', borderRadius: 5 }} placeholder="Order note..." />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='BottomBarTwoItem'>
                <Row className='Container'>
                    <Col xl={3} lg={3} md={3} sm={3} xs={4}>
                        <div className='counter'>
                            <button onClick={() =>{
                                setQuantity(val => {
                                    if (val > 1) {
                                        return val - 1
                                    }
                                    else {
                                        return val
                                    }
                                })
                            }}>-</button>
                            
                            <span>{quantity != 0 ? quantity : 1} </span>
                            
                            <button onClick={() =>{
                                setQuantity((val) => {
                                    return val + 1
                                })
                            }}>+</button>
                        </div>
                    </Col>
            
                    <Col xl={9} lg={9} md={9} sm={9} xs={8}>
                        <div className='price-add'>
                            <button onClick={
                                async () => {
                                    const response = await api.order.soldOut({
                                        "product_id": dataOrder?.product_id,
                                        "today": moment().format('YYYY-MM-DD')
                                    })
                            
                                    const res = response.data
                                    if (res.sold_out) {
                                        addToast("Barang sudah soldout", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                            autoDismissTimeout: 1500,
                                        })
                                    }
                                    else {
                                        let name = []
                                        for(const xi of pesanan){
                                            if (xi['product_id'] == dataOrder['product_id']) {
                                                name.push(xi['product_id'])
                                            }
                                        }
                                        if (name.includes(dataOrder['product_id'])) {
                                            for(const resultOrder of pesanan){
                                                if (resultOrder['product_id'] == dataOrder['product_id']) {
                                                    resultOrder['item'] = quantity 
                                                    resultOrder['total'] = dataOrder['usePrice'] * quantity
                                                }
                                            }
                                        }
                                        else {
                                            pesanan.push(Object.assign(dataOrder, {
                                                item: quantity,
                                                total: dataOrder['usePrice'] * quantity,
                                                note: note
                                            }))
                                        }
                                        const resultPesanan = pesanan.filter(items => items['item'].toString() !== '0')
                                        localStorage.setItem('pesanan', JSON.stringify(encrypt(resultPesanan)))
                                        dispatch(setKeranjang())
                                        if (quantity > quantity2) {
                                            addToast("Pesanan sudah ditambah", {
                                                appearance: 'success',
                                                autoDismiss: true,
                                                autoDismissTimeout: 1500,
                                            })
                                        }
                                        setIsLoading(true);

                                        // Simulate an API call or database query
                                        setTimeout(() => {
                                            setIsLoading(false);
                                        }, 6000);
                                        navigate(`/${router}detail-order`)
                                    }

                                    

                                }} disabled={isLoading}>
                                {isLoading ? (
                                    <span className='spinner' />
                                    ) : (
                                    <a>Add Rp. {formatterMoney(quantity*dataOrder.price)}</a>
                                )}
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}