import React from 'react'
import CopyRight from '../../Assets/CopyRight'
import Responsif from '../../utils/Responsif'
import firebase from '../../firebase'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc} from "firebase/firestore";
import Input from '../../components/Input'
import OtpInput from 'react-otp-input';
import { useNavigate, useLocation} from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import Gap from "../../components/Gap"
import Button from "../../components/Button"
import { router } from "../../Constants"
import ScreenOTP from './ScreenOTP';

async function saveData(location, navigate, user) {
	try {
		console.log(location)
		let data = location?.state?.detail
		let phoneNumber = "+62"+data.NoTelp.replace(/ /g,'')
		const auth = getAuth();
		const db = getFirestore();
		const docRef  = doc(db, "users", phoneNumber.trim());
		const docSnap = await getDoc(docRef);
	
		if (!docSnap.data()) {
			const uid = phoneNumber;
			const docData = {
				nama: data.Nama,
				telp  : phoneNumber,
				uuid  : user.uid
			};
			const docRef =  setDoc(doc(db, "users", uid), docData, { merge: true });
			localStorage.setItem('register-nama', '')
        	localStorage.setItem('register-telp', '')
			localStorage.setItem('login', JSON.stringify(docData))
			navigate(`/${router}home`)
		} else {
			if(location.status == 'insert') {
				alert("No Telepon ini sudah terdaftar, mohon untuk mengisi no telp yang lain.")
			} else {
				const uid = phoneNumber;
				const docData = {
					nama  : data.Nama,
					telp  : phoneNumber,
					uuid  : user.uid
				};
				const docRef =  setDoc(doc(db, "users", uid), docData, { merge: true });
				localStorage.setItem('register-nama', '')
				localStorage.setItem('register-alamat', '')
				localStorage.setItem('register-telp', '')
				localStorage.setItem('login', JSON.stringify(docData))
				navigate(`/${router}home`)
			}
		}
	} catch (e) {
	  console.error("Error adding document: ", e);
	}
}


function KodeOTP() {
	let navigate = useNavigate()
	let location = useLocation()
	const { addToast } = useToasts()
	const [input, setInput] = React.useState({
		mobile : '',
		otp : ''
	})
	const [loading, setLoading] = React.useState(false)

	async function onOtp() {
		const code = input.otp;
		if (window.confirmationResult !== undefined) {
			window.confirmationResult.confirm(code).then( async (result) => {
			  const user = result.user;
			  await saveData(location, navigate, user)
			  setLoading(false)
			  // ...
			}).catch((error) => {
				console.log(location?.state?.detail)
				switch(error.code) {
					case 'auth/too-many-requests':
						setLoading(false)
						addToast("Too many requests, please try again after 5 minutes.", {
							appearance: 'error',
							autoDismiss: true,
						})
						break;
					case 'auth/invalid-verification-code':
						setLoading(false)
						addToast("Nomor OTP tidak sama dengan yang dikirimkan", {
							appearance: 'error',
							autoDismiss: true,
						})
						setInput(val => ({
							...val,
							otp: ''
						}))
						break;
					case 'auth/code-expired':
						setLoading(false)
						addToast("Nomor OTP sudah expired, silahkan kirim ulang", {
							appearance: 'error',
							autoDismiss: true,
						})
						break;
					default:
						setLoading(false)
						addToast("An unknown error occured.", {
							appearance: 'error',
							autoDismiss: true,
						})
						break;
				}
			  console.log(error)
			});
		}
		else {
			navigate(`/${router}login`)
		}
	}
	
	function onSubmitOTP(e){
		e.preventDefault()
		onOtp()
		setLoading(true)
	}
	const { width } = Responsif()
    return(
		<ScreenOTP onClickBack={() => navigate(`/${router}register`)} input={input} setInput={setInput} onSubmitOTP={onSubmitOTP} loading={loading} ResendOTP={() => {
			{navigate(`/${router}register`)}
		}}/>
    )
}

export default KodeOTP
