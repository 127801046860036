import React from 'react'
import Head from '../../Assets/Head'
import Responsif from '../../utils/Responsif'
import { Link } from 'react-router-dom'
import {DMSansBold, DMSansMedium, } from '../../fonts/index'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged} from "firebase/auth";
import { useToasts } from 'react-toast-notifications'
import { collection, addDoc, doc, setDoc, getFirestore, getDoc} from "firebase/firestore";
import Input from '../../components/Input'
import InputPhone from '../../components/InputPhone';
import Button from '../../components/Button';
import CopyRight from '../../Assets/CopyRight'
import { useNavigate } from 'react-router-dom'
import {BiArrowBack} from 'react-icons/bi'
import Gap from '../../components/Gap'
import { router } from "../../Constants"

function Home(params) {
    let navigate = useNavigate()
    const { addToast } = useToasts()
    const { width } = Responsif()
    const [input, setInput] = React.useState({
        Nama   : JSON.parse(localStorage.getItem('login')).nama,
        Alamat : JSON.parse(localStorage.getItem('login')).alamat,
        NoTelp : JSON.parse(localStorage.getItem('login')).telp.replace('+62',''),
        status : 'update'
    }) 
    const [timeOut, setTimeOut] = React.useState('Verifikasi No Telp')
    const [loading, setLoading] = React.useState(false)

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (!user) {
            const uid = user.uid;
            alert("Anda telah sign out, silahkan login terlebih dahulu.") 
        } 
    });


    const [captchaCalled, setCaptchaCalled] = React.useState(false)
    function onSignInSubmit(e){
		e.preventDefault()
		onSignIn()
        setLoading(true)
	}

	function configureCaptcha(){
		const auth = getAuth();
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response) => {
			// reCAPTCHA solved, allow signInWithPhoneNumber.
			setCaptchaCalled(true)
			onSignInSubmit();
		}
	}, auth)}
    
    async function onSignIn(e) {
		const phoneNumber = "+62"+input.NoTelp.replace(/ /g,'');
        // if(!input.NoTelp) {
        //     alert("No Telepon harus diisi")
        //     return
        // }
        if(!input.Nama) {
            setLoading(false)
            addToast("Nama harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })
            return
        }
        if(captchaCalled === false) {
            setLoading(false)
            configureCaptcha()
        }

		const db      = getFirestore();
		const docRef  = doc(db, "users", phoneNumber);
		const docSnap = await getDoc(docRef);

        // if(docSnap.data()) {
        //     const auth = getAuth();
        //     onAuthStateChanged(auth, (user) => {
        //         if (user) {
        //             const uid = user.uid;
        //             if(docSnap.data().uuid != uid) {
        //                 alert("No ini telah diregistrasi, silahkan gunakan nomor lain.")
        //                 return
        //             }
        //         } else {
        //             alert("Anda telah sign out, silahkan login terlebih dahulu.")
        //             return
        //         }
        //     });
        // }


        const appVerifier = window.recaptchaVerifier;
        const auth = getAuth();
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            navigate({
                pathname : "/" + router + 'kode-otp-register',
                state    : {detail : input}
            })
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            if(error.code == 'auth/invalid-phone-number') {
                alert("Nomor telepon yang dimasukkan tidak valid.")
            } 
        });
	}

    return(
        <div>
        <div>
			<div style={{marginTop:10, marginLeft: 5, padding:10 }} onClick={()=>{navigate.goBack()}}>
				<BiArrowBack size={20}/>
			</div>
		</div>
        <div style={{ margin: '20% 10px 10px 10px' }}>
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', padding: '30px 0px' }}>
				<div style={{ fontSize: 25}} className="fonts700">Edit Profile</div>
			</div>
            <div id="sign-in-button"></div>
            <div>
				<Input type="input" value={input.Nama} placeholder="Nama" label="Nama" onChange = {
					(val) => {
                        setInput((prevState) => ({
                            ...prevState,
                            Nama : val.target.value
                        }));
                        localStorage.setItem('register-nama', val.target.value);
					}
				
				}/>
                <Gap height={10} />
				{/* <Input type="input" value={input.Alamat} placeholder="Alamat" label="Alamat" onChange = {
					(val) => {
                        setInput((prevState) => ({
                            ...prevState,
                            Alamat : val.target.value
                        }));
                        localStorage.setItem('register-alamat', val.target.value);
					}
				
				}/> */}
				{/* <Input type="input" value={input.NoTelp} placeholder="" label="Nomor Handphone" onChange = {
					(val) => {
                        setInput((prevState) => ({
                            ...prevState,
                            NoTelp : val.target.value
                        }));
                        localStorage.setItem('register-telp', val.target.value);
					}
                    
				}/> */}

                <InputPhone type="input" value={input.NoTelp} label="Nomer Handphone" placeholder="8123456789" onChange = {
                    (val) => {
                        setInput((prevState) => ({
                            ...prevState,
							NoTelp : val
						}));
                        localStorage.setItem('register-telp', val);
					}
				}/>

                {/* <button onClick={onSignInSubmit} style={{width: '100%', margin: '30px 0 10px 0', height: 40, borderRadius: 5, border: 'none', backgroundColor: '#5451D6'}}>
                    <span style={{ color: '#fff'}}>Verifikasi No Telp</span>
                </button>  */}
                <Gap height={30} />
                <Button onClick={(e) => {
                    if (!loading) {
						if (timeOut == "Verifikasi No Telp") {
							onSignInSubmit(e)
						}
                    }
                }} loading={loading} color="#fff" backgroundColor={timeOut == "Verifikasi No Telp" ? "#5451D6" : '#b5b5b5'}>
					{timeOut}
				</Button>
			</div>

            <div style={{ marginTop: '70%', textAlign: 'center'}}>
                <CopyRight />
            </div>



             {/* <div onClick={onSignInSubmit}>REGISTER</div> */}
        </div>
        </div>
    )
}

export default Home