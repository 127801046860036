import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { FaConciergeBell } from "react-icons/fa";
import formatterMoney from "../../utils/formatMoney";
import { useNavigate } from "react-router-dom";
import FETCH_API from "../../config/fetch_api";
import { formatDate } from "../../utils/formatDate";
import Input from "../../components/Input";
import InputPhone from "../../components/InputPhone";
import Gap from "../../components/Gap";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { useToasts } from "react-toast-notifications";
import { router } from "../../Constants";
import { useSelector, useDispatch } from "react-redux";
import { setOrder, setKeranjang } from "../../redux/actions/prepareActions";
import api from "../../request";
import {
  SET_KATEGORY,
  SET_LIST_KATEGORI,
  SET_PRODUK,
  SET_SHIFT,
  SET_KERANJANG,
  SET_ORDER,
} from "../../redux/constants/prepareConstants";
import { TabBottom } from "../../Layout";
import {
  Container,
  Row,
  Col,
  Form,
  Dropdown,
  FormGroup,
} from "react-bootstrap";

export default function LacakPesanan() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.dataPrepare?.order);
  // console.log(JSON.parse(state[1]?.data[0]?.delivery_status))
  // console.log(' >>  '+JSON.stringify(params.navigate.location.state.params.responseStatus))
  // const response = params.navigate.location.state.params.responseStatus
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const [input, setInput] = useState({
    phone: "",
    id: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataPesanan, setDataPesanan] = useState([]);
  const userServer = JSON.parse(localStorage.getItem("userServer"));
  let dataBranch = JSON.parse(localStorage.getItem("dataBranch"));
  const [sidebar, setSidebar] = React.useState(false);
  // console.log('datapesanan'+JSON.stringify(dataPesanan))
  const [filterStatusPesanan, setFilterStatusPesanan] =
    React.useState("Menunggu");
  const [filterPesanan, setFilterPesanan] = React.useState([]);
  const [style, setStyle] = React.useState({});
  const listStatus = ['Menunggu','Diterima','Diproses','Dikirim','Dibatalkan']
  async function onLacak(items) {
    try {
      let statusGojek = {};
      const res = await api.apiPrepare.getChargesNote({
        userServer: userServer,
        id: items.id,
      });
      let result = res.data;
      if (
        items.data[0].pickup_delivery == 1 &&
        items.is_accepted_rejected == 1
      ) {
        statusGojek = await api.gojek.getCurrentOrderStatus({
          detailData: {
            storeOrderId: items.id,
          },
        });
        console.log(statusGojek);
      }

      if (items["status"] == "Menunggu Pembayaran") {
        const coa = await api.xendit.getCoaXendit();
        localStorage.setItem("coaweb", JSON.stringify(coa?.data?.data[0]));
        navigate(`/${router}metode-pembayaran/:id`, {
          state: {
            data: items["data"],
            id: items["id"],
            local_charges: result.data.length !== 0 ? result.data[0] : "[]",
          },
        });
        localStorage.setItem("detailpembayaran", JSON.stringify(items?.charge));
      } else {
        navigate("/" + router + "list-lacak-pesanan", {
          state: {
            data: items,
            max: result.data[0],
            gojek:
              items.data[0].pickup_delivery == 1
                ? statusGojek?.data?.hasil
                : {},
          },
        });
        setLoading(false);
      }
      let data = [];
      for (const item of dataPesanan) {
        item["loading"] = false;
        data.push(item);
      }

      setDataPesanan(data);
    } catch (error) {
      console.log(error);
      navigate("/" + router + "list-lacak-pesanan", {
        state: {
          data: items,
        },
      });
    }
  }
  React.useEffect(async () => {
    const server = localStorage.getItem("userServer");
    if (server) {
      const local_charges = await api.apiPrepare.local_charges();
      localStorage.setItem(
        "local_charges",
        JSON.stringify(local_charges.data?.data)
      );
      dispatch(setKeranjang());
      dispatch(setOrder());
    }
  }, []);
  useEffect(() => {
    const notlp = JSON.parse(localStorage.getItem("orderDate"));
    window.addEventListener("popstate", (event) => {
      navigate("/" + router + "home");
    });

    if (notlp) {
      setInput((old) => ({
        ...old,
        phone: notlp?.nomerHp.substring(3),
      }));
      const pesanan = [];
      for (const items of state) {
        if (items.trans_status === 0 && filterStatusPesanan === "Menunggu") {
          pesanan.push(items);
        }
        if (
          items &&
          items["trans_status"] == 1 &&
          items.is_accepted_rejected == 0 &&
          filterStatusPesanan === "Diterima"
        ) {
          pesanan.push(items);
        }
        if (
          items &&
          items["trans_status"] == 1 &&
          (items["is_prepared"] == 0 || items["is_prepared"] == 1) &&
          items.is_accepted_rejected == 1&&
          filterStatusPesanan === "Diproses"
        ) {
          pesanan.push(items);
        }
        if (
          items &&
          items["trans_status"] == 1 &&
          items["is_prepared"] == 1 &&
          items.si_id && filterStatusPesanan === 'Dikirim'
        ) {
          pesanan.push(items);
        }
        if (items && items["trans_status"] == 1 && items["is_prepared"] == 2 && filterStatusPesanan === 'Selesai') {
          pesanan.push(items);
        }
        if (items && items["trans_status"] == 2 && items["is_prepared"] == 0 && filterStatusPesanan === 'Dibatalkan') {
          pesanan.push(items);
        }
        if (items && items["is_accepted_rejected"] == 2 && filterStatusPesanan === 'Dibatalkan') {
          pesanan.push(items);
        }
      }
      setDataPesanan(pesanan);
    }
    // setDataPesanan(res.data)
    setTimeout(() => {
      setLoadingButton(false);
    }, 1000);
  }, [state, filterStatusPesanan]);
  useEffect(() => {
    dispatch(setOrder());
  }, []);
  const buttonGroupContainerStyle = {
    width: "100%",
    overflowX: "scroll",
    padding: "10px",
    whiteSpace: "nowrap", // Prevents buttons from wrapping to the next line
  };

  const buttonStyle = {
    display: "inline-block",
    margin: "0 2px", // Adjust spacing as needed
  };
  return (
    <div>
      <Navbar
        type="Lacak-Pesanan"
        sidebar={sidebar}
        setSidebar={(val) => setSidebar(val)}
        label="Daftar Transaksi"
      />
      {/* <div style={{ backgroundColor: '#F0F0F0' }}>
                <div style={{ padding: '25% 0 20px 0', display: 'flex', margin: '0 10px 0 10px', }}>
                    <FaConciergeBell color="#5451D6" size={25} />
                    <div style={{ marginLeft: 20}}>
                        <div className="fonts700" style={{ fontSize: 15}}>{JSON.parse(localStorage.getItem('dataBranch')).branchName}</div>
                        <div className="fonts400" style={{ fontSize: 12, color: '#7E7E7E'}}>{JSON.parse(localStorage.getItem('dataBranch')).branchAddress}</div>
                    </div>
                </div>
            </div> */}
      <div style={{ margin: "0px 20px 20px 10px" }}>
        <div style={buttonGroupContainerStyle}>
        {
          listStatus.map((items, index) => {
            return(
              
          <div style={buttonStyle}>
            <button
              onClick={() => {
                setFilterStatusPesanan(items);
              }}
              style={{
                width: 100,
                height: 35,
                borderRadius: 5,
                border: "none",
                backgroundImage:  filterStatusPesanan === items ? 'linear-gradient(#04295E, #04295E)' : "linear-gradient(#fff, #c7c7c7)",
              }}
            >
              <span
                className="fonts500"
                style={{ color: filterStatusPesanan === items ? "#fff":"#04295E", fontSize: "12px" }}
              >
                {items}
              </span>
            </button>{" "}
          </div>
                            )})}
          {/* <div style={buttonStyle}>
            <button
              onClick={() => {
                setFilterStatusPesanan("Menunggu");
              }}
              style={{
                width: "100%",
                marginRight: 10,
                height: 35,
                borderRadius: 5,
                border: "none",
                backgroundImage: "linear-gradient(#fff, #c7c7c7)",
              }}
            >
              <span
                className="fonts500"
                style={{ color: "#04295E", fontSize: "12px" }}
              >
                Menunggu
              </span>
            </button>{" "}
          </div>
          <div style={buttonStyle}>
            <button
              onClick={() => {
                setFilterStatusPesanan("Diterima");
              }}
              style={{
                width: "100%",
                height: 35,
                borderRadius: 5,
                border: "none",
                backgroundImage: "linear-gradient(#fff, #c7c7c7)",
              }}
            >
              <span
                className="fonts500"
                style={{ color: "#04295E", fontSize: "12px" }}
              >
                Diterima
              </span>
            </button>{" "}
          </div>
        

          <div style={buttonStyle}>
            <button
              onClick={() => {
                setFilterStatusPesanan("Dibatalkan");
              }}
              style={{
                width: "100%",
                height: 35,
                borderRadius: 5,
                border: "none",
                backgroundImage: "linear-gradient(#fff, #c7c7c7)",
              }}
            >
              <span
                className="fonts500"
                style={{ color: "#04295E", fontSize: "12px" }}
              >
                Dibatalkan
              </span>
            </button>{" "}
          </div> */}
       
          {/* Add more button divs as needed */}
        </div>
        {/* <Container>
          <Row xs={2} style={{ paddingTop: "1vh", paddingBottom: "1vh" }}>
            <Col xs={4}>
              <button
                onClick={() => {
                  setFilterStatusPesanan("Menunggu");
                }}
                style={{
                  width: "100%",
                  marginRight: 10,
                  height: 35,
                  borderRadius: 5,
                  border: "none",
                  backgroundImage: "linear-gradient(#fff, #c7c7c7)",
                }}
              >
                <span
                  className="fonts500"
                  style={{ color: "#04295E", fontSize: "12px" }}
                >
                  Menunggu
                </span>
              </button>
            </Col>
            <Col xs={4}>
              <button
                onClick={() => {
                  setFilterStatusPesanan("Diterima");
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderRadius: 5,
                  border: "none",
                  backgroundImage: "linear-gradient(#fff, #c7c7c7)",
                }}
              >
                <span
                  className="fonts500"
                  style={{ color: "#04295E", fontSize: "12px" }}
                >
                  Diterima
                </span>
              </button>
            </Col>
            <Col xs={4}>
              <button
                onClick={() => {
                  setFilterStatusPesanan("Dibatalkan");
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderRadius: 5,
                  border: "none",
                  backgroundImage: "linear-gradient(#fff, #c7c7c7)",
                }}
              >
                <span
                  className="fonts500"
                  style={{ color: "#04295E", fontSize: "12px" }}
                >
                  Dibatalkan
                </span>
              </button>
            </Col>
            <Col xs={4}>
              <button
                onClick={() => {
                  setFilterStatusPesanan("Dibatalkan");
                }}
                style={{
                  width: "100%",
                  height: 35,
                  borderRadius: 5,
                  border: "none",
                  backgroundImage: "linear-gradient(#fff, #c7c7c7)",
                }}
              >
                <span
                  className="fonts500"
                  style={{ color: "#04295E", fontSize: "12px" }}
                >
                  Dibatalkan
                </span>
              </button>
            </Col>
          </Row>
        </Container> */}

        {/* <div style={{ margin: '20px 10px 0 10px' }}>
                    <InputPhone value={input.phone} onChange={(val) => setInput(old => ({
                        ...old,
                        phone: val
                    }))} label="Nomer Telephone" placeholder="8123456789"/>
                    <Gap height={20}/>
                    <Button onClick={() => {
                            dispatch(setOrder(input.phone))
                            setLoadingButton(true)
                    }} backgroundColor="#5451D6" color="#fff" loading={loadingButton}>Search</Button>
                </div> */}
        {dataPesanan.length !== 0 ? (
          <div>
            {dataPesanan.map((items, index) => {
              let colors;
              if (items && items["trans_status"] == 0) {
                items["status"] = "Menunggu Pembayaran";
                colors = "#FE8718";
              }
              if (
                items &&
                items["trans_status"] == 1 &&
                items.is_accepted_rejected == 0
              ) {
                items["status"] = "Pembayaran Diterima";
                colors = "#8FBDD3";
              }
              if (
                items &&
                items["trans_status"] == 1 &&
                (items["is_prepared"] == 0 || items["is_prepared"] == 1) &&
                items.is_accepted_rejected == 1
              ) {
                items["status"] = "Pesanan Diproses";
                colors = "#0593D0";
              }
              if (
                items &&
                items["trans_status"] == 1 &&
                items["is_prepared"] == 1 &&
                items.si_id
              ) {
                items["status"] = "Pesanan Dikirim";
                colors = "#5584AC";
              }
              if (
                items &&
                items["trans_status"] == 1 &&
                items["is_prepared"] == 2
              ) {
                items["status"] = "Pesanan Selesai";
                colors = "#05D03E";
              }
              if (
                items &&
                items["trans_status"] == 2 &&
                items["is_prepared"] == 0
              ) {
                items["status"] = "Pesanan Dibatalkan";
                colors = "#FF5353";
              }
              if (items && items["is_accepted_rejected"] == 2) {
                items["status"] = "Pesanan Dibatalkan";
                colors = "#FF5353";
              }
              return (
                <div
                  key={index}
                  style={{
                    margin: "30px 10px 0 10px",
                    backgroundColor: "#fff",
                    borderRadius: 5,
                  }}
                >
                  {/* <div className="fonts400">{ items['id']}</div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0 10px 0",
                      margin: "0px 10px 0px 10px",
                      borderBottom: "1px solid #BFBFBF",
                    }}
                  >
                    <div>
                      <div className="fonts400">Tanggal</div>
                      <Gap height={5} />
                      <div className="fonts400">Status Pesanan</div>
                    </div>
                    <div style={{ textAlign: "end" }}>
                      <div className="fonts400">
                        {formatDate(items["date"])}
                      </div>
                      <Gap height={5} />

                      <div
                        className="fonts400"
                        style={{
                          backgroundColor: colors,
                          color: "#fff",
                          padding: "0 10px",
                          borderRadius: 20,
                          fontSize: "13px",
                        }}
                      >
                        {items["status"]}
                      </div>
                    </div>
                  </div>
                  {items.data.map((data, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0 10px 0",
                          margin: "0px 10px 0px 10px",
                        }}
                      >
                        <div>
                          <div className="fonts500">
                            {data["qty"]} x {data["name"]}
                          </div>
                          <div
                            className="fonts400"
                            style={{ color: "#BFBFBF" }}
                          >
                            {data["detail_note"]}
                          </div>
                        </div>
                        <div className="fonts500">
                          Rp.{formatterMoney(data["price"] * data["qty"])}
                        </div>
                      </div>
                    );
                  })}
                  {/* <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 0' , margin: '0px 10px 0px 10px'  }}>
                                                        <div>
                                                            <div className="fonts500">{items['qty']} x   {items['name']}</div>
                                                            <div className="fonts400" style={{ color: '#BFBFBF'}}>{items['detail_note']}</div>
                                                        </div>
                                                        <div className="fonts500">Rp.{formatterMoney(items['price'] * items['qty'])}</div>
                                                    </div> */}

                  {items.charge.map((x, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding:
                          items.charge.length == index + 1
                            ? "10px 0 10px 0"
                            : "5px 0 0px 0",
                        margin: "0px 10px 0px 10px",
                        borderTop:
                          items.charge.length == index + 1
                            ? "1px solid #BFBFBF"
                            : "0px",
                      }}
                    >
                      <span
                        className={
                          items.charge.length == index + 1
                            ? "fonts500"
                            : "fonts400"
                        }
                      >
                        {x.label}
                      </span>
                      <span
                        className={
                          items.charge.length == index + 1
                            ? "fonts500"
                            : "fonts400"
                        }
                      >
                        Rp.{formatterMoney(x?.value)}
                      </span>
                    </div>
                  ))}
                  {items["trans_status"] !== 2 &&
                    items["is_accepted_rejected"] !== 2 && (
                      <div
                        style={{
                          padding: "10px 0 10px 0",
                          margin: "0px 10px 0px 10px",
                        }}
                      >
                        {/* <div onClick={() => onLacak(items)} className="fonts700" style={{ marginTop: 10, color: '#5451D6', width: '100%'}}>Lacak Pesanan</div> */}
                        <div style={{ width: "50%" }}>
                          <Button
                            onClick={() => {
                              let data = [];
                              for (const item of dataPesanan) {
                                if (items["id"] == item["id"]) {
                                  item["loading"] = true;
                                } else {
                                  item["loading"] = false;
                                }
                                data.push(item);
                              }
                              localStorage.setItem('detailpembayaran', JSON.stringify(items.charge))

                              setDataPesanan(data);
                              onLacak(items);
                            }}
                            loading={items.loading}
                            backgroundColor="#04295E"
                            color="#fff"
                          >
                            {items["status"] == "Menunggu Pembayaran"
                              ? "Bayar Sekarang"
                              : "Lacak Pesanan"}
                          </Button>
                        </div>
                      </div>
                    )}
                </div>
              );
            })}
            <Gap height={70} />
          </div>
        ) : loading ? (
          <Loading />
        ) : (
          <div
            style={{
              margin: "20px 10px 0 10px",
              backgroundColor: "#fff",
              borderRadius: 5,
              height: 200,
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="fonts700">Tidak ada data</span>
          </div>
        )}
      </div>
      <TabBottom
        sidebar={sidebar}
        setSidebar={(val) => {
          if (!val && style.width) {
            sessionStorage.setItem("prepareLogin", JSON.stringify("1"));
          }
          setSidebar(val);
        }}
      />
    </div>
  );
}
