import { SET_KATEGORY, SET_LIST_KATEGORI, SET_PRODUK, SET_SHIFT, SET_KERANJANG, SET_ORDER,SET_GET_PAKET } from "../constants/prepareConstants"
import api from "../../request"
import { decrypt } from "../../utils/enkripsi"
import moment from 'moment';

export const getKategory = () => async dispatch => {
    try {
        const result = await api.apiPrepare.getKategory({
            "from_origin": "*",
            "search1": "",
            "on_pos":1
        })
        const data = result?.data?.data
        
        const kat = data.map(item => ({
            label: item.id,
            id: item.id
        }))


        let x = []

        for(const i of kat){
            if (i.id !== "ADD ON") {
                x.unshift(i)
            }
        }
        x.unshift({
            label : "Promo Diskon",
            id: "Promo Buy X Free Y %"
        })

    
        x.unshift({
            label: "Promo Paket",
            id: "promo_paket"
        })

        // x.unshift({
        //     label: "Promo",
        //     id: "promo",
        //     details: [
        //         {
        //             label: "Promo Buy X Free Y",
        //             id: "promo_buy_x_free_y"
        //         },
        //         {
        //             label: "Promo Paket",
        //             id: "promo_paket"
        //         }
        //     ]
        // })
        x.unshift({
            label: "All Kategory",
            id: "all"
        })
        x[0]['selection'] = true
        dispatch({type: SET_KATEGORY, value: x});
        dispatch({type: SET_LIST_KATEGORI, value: x});
    } catch (error) {
        console.log(error)
    }
}
export const SetKategori = (value) => async dispatch => {
    dispatch({type: SET_KATEGORY, value: value});
}
export const Produk = (value = {}) => async dispatch => {
    const { currentPage = 0, category_id = "all", data = [], total_baris} = value
    let baris = 0
    try {   
        const res = await api.apiPrepare.produk({
            "search1": "",
            "pageSize" : "10",
            "currentPage" : currentPage.toString(),
            "category_id" : category_id
        })
        const response = res?.data?.data
        if (!total_baris) {
            baris = res.data.total_baris
        }
        else {
            baris = total_baris
        }
        dispatch({type: SET_PRODUK, value: {
            data: data.concat(response),
            currentPage,
            category_id,
            total_baris: baris,
            loading: false
        }})
        
    } catch (error) {
        console.log(error)
    }
}
export const setOrder = (value) => async dispatch => {
    const orderDate = JSON.parse(localStorage.getItem('orderDate'))
    const login = JSON.parse(localStorage.getItem('login'))
    const idPesanan = JSON.parse(localStorage.getItem('idpesanan'))
    // let tex = {}
    // let local_charges = refreshCharges()
    // for(const item of local_charges){
    //     tex['label'] = item.id
    //     tex['percent_value'] = item.percent_value
    // }
    try {   
        let tlp
        if (value) {
            tlp = value
        }
        else {            
            if (login) {
                tlp = login.telp 
            }
            else if (orderDate) {    
                tlp =     orderDate.nomerHp
            }
        }
        const res = await api.apiPrepare.getOrder({
            "noTelp": tlp,
            "id": ""
        })
        // console.log('res >> '+JSON.stringify(res))
        let getStatus = res.data.data
        getStatus.sort((a, b) => new Date(b.trans_date) - new Date(a.trans_date))
        let array = []
        let so_id = ''

        for(const item of getStatus){
            const charges_note = JSON.parse(item.charges_note) == null?[]:JSON.parse(item.charges_note)
            // console.log(charges_note)

            let ongkir = charges_note.filter(x => (x.id.toLowerCase().includes('gosend') || (x.id.toLowerCase().includes('delivery'))))

            let charger = charges_note.filter(x => !x.id.toLowerCase().includes('gosend') && !x.id.toLowerCase().includes('delivery'))
            let data = []
            let subTotal = 0
            for(const temps of getStatus){
                if (item.so_id == temps.so_id) {
                    data.push(temps)
                }
            }

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                // subTotal += (element['price'] * element['qty'])
                subTotal += (element['price'] * element['qty'] )

            }
            if (so_id !== item.so_id) { 
                so_id = item.so_id              
                array.push({
                    id: item.so_id,
                    si_id: item.si_id,
                    date: item.trans_date,
                    data: data,
                    trans_status: item.trans_status,
                    is_prepared: item.is_prepared,
                    loading: false,
                    is_accepted_rejected: item.is_accepted_rejected,
                    subTotal: subTotal,
                    charges_note: charger,
                    ongkir: ongkir[0] ? ongkir[0]['value'] : 0
                })
            }       
            
        }
        for(const item of array){
            const chargeNotes = item['charges_note']
            let dataChargeNote = {}
            let total = 0
            dataChargeNote.charges_value_non_pb1 = 0;
            dataChargeNote.charges_value_pb1 = 0;

            for(var i = 0; i < item.data?.length; i++){
                let x = item.data[i]
                // let totall = (x.qty * x.price)
                let subTotal = (x.qty * x.price)
                total += subTotal
                dataChargeNote.sub_total = total
                // console.log(subTotal)
            }
            let charge = []
            charge.push( 
                {
                label: 'Subtotal Produk',
                value:  item['subTotal']
            })
            chargeNotes.forEach(d=>{
                if (d.pb1 == 0) {
                    if ((dataChargeNote.sub_total > d.max_value) && (d.max_value > 0) ){
                        d.value = d.percent_value / 100 * d.max_value;
                        dataChargeNote.charges_value_non_pb1 += d.value; 
                    }
                    else {
                        d.value = (d.percent_value / 100 * dataChargeNote.sub_total);
                        dataChargeNote.charges_value_non_pb1 += d.value; 
                    }
                    charge.push({
                        label: `${d.id} ${d.percent_value}%`,
                        value: d.value
                    })
                }

                if (d.pb1 == 1) {
                    if ((dataChargeNote.sub_total > d.max_value) && ((d.max_value || 0) > 0) ){
                        d.value = d.max_value || 0;
                        dataChargeNote.charges_value_pb1 += d.value; 
                    }
                    else {
                        d.value = (d.percent_value / 100 * (dataChargeNote.sub_total + dataChargeNote.charges_value_non_pb1 + item['ongkir']));
                        dataChargeNote.charges_value_pb1 += d.value; 
                    }
                    charge.push({
                        label: `${d.id} ${d.percent_value}%`,
                        value: d.value
                    })
                }
            })
            // item['value'] = dataChargeNote.charges_value_pb1
            item['grand_total'] = (dataChargeNote['sub_total'] + item['ongkir']) 
            // console.log('hasil ===  '+item['grand_total'])
            // console.log('hasil ===  '+dataChargeNote.charges_value_pb1)

            item['grand_total_net'] = (item['grand_total'] + dataChargeNote.charges_value_pb1) 
            item['charges_value'] = (dataChargeNote['charges_value_pb1'] + item.ongkir) 
            let x = [
                // {
                //     label: 'Pajak Restoran 10%',
                //     // value: (item['subTotal'])*(10/100)
                //     value: (item['subTotal'])

                // },
                {
                    label: 'Subtotal Pengiriman',
                    value: item['ongkir']
                },
                {
                    label: 'Total Pembayaran',
                    // value: item['grand_total_net']+(item['subTotal'])*(10/100)
                    value: item['grand_total_net']

                },
            ]
            charge.push(...x)
            item['charge'] = charge
        }
        dispatch({type: SET_ORDER, value: array})
        
    } catch (error) {
        console.log(error)
    }
}
export const Shift = (value) => async dispatch => {
    try {   

        const res = await api.apiPrepare.shift()
        const time = res?.data?.data
        let data = {}
        if (time.length !== 0) {
            data['min'] = time.min.substring(0, time.min.lastIndexOf(':'))
            data['max'] = time.max.substring(0, time.max.lastIndexOf(':'))
        }
        dispatch({type: SET_SHIFT, value: data})
        
    } catch (error) {
        console.log(error)
    }
}
export const setKeranjang = (value) => async dispatch => {
    let pesanan = decrypt(JSON.parse(localStorage.getItem('pesanan')))
    let x = 0
    if (pesanan) {        
        for(const i of pesanan){
            x += Number(i.item)
        }
    }

    dispatch({type: SET_KERANJANG, value: x})
}
export const paketActions = (value) => async dispatch => {
    const date = new Date()
    const dateawal = moment(date).format('YYYY-M-DD 23:59:59')
    const dateakhir = moment(date).format('YYYY-M-DD 00:00:00')
    const { currentPage = 0, category_id = "", data = [], total_baris} = value

    let baris = 0
    try {
        const result = await api.apiPrepare.getPaket({
            "from_origin": "*",
            "tgl_awal": dateawal,
            "tgl_akhir": dateakhir,
            "category_id": category_id.toString(),
            "filter": "{\"search1\":\"\",\"search_col1\":\"all\",\"search2\":\"\",\"search_col2\":\"\",\"search3\":\"\",\"search_col3\":\"\",\"sort_col1\":\"m.updated_date\",\"sort_dir1\":\"desc\",\"sort_col2\":\"m.name\",\"sort_dir2\":\"asc\",\"sort_col3\":\"m.description\",\"sort_dir3\":\"asc\",\"jumlah_baris\":50,\"total_baris\":12,\"halaman\":0,\"kolom_tanggal\":\"none\",\"showUpdated\":false}"
          })
          

        const response = result?.data.data
        if (!total_baris) {
            baris = result.total_baris
        }
        else {
            baris = total_baris
        }
        dispatch({type: SET_GET_PAKET, value: {
            data: data.concat(response),
            currentPage,
            category_id,
            total_baris: baris,
            loading: false
        } });
        dispatch({type: SET_PRODUK, value: {
            data: data.concat(response),
            currentPage,
            category_id,
            total_baris: baris,
            loading: false
        }})
   
    } catch (error) {
        console.log("data produk", error)
    }
}


export const promoBuyXGetYActions = (value) => async dispatch => {
    const date = new Date()
    const dateawal = moment(date).format('YYYY-M-DD 23:59:59')
    const dateakhir = moment(date).format('YYYY-M-DD 00:00:00')
    const { currentPage = 0, category_id = "", data = [], total_baris} = value

    let baris = 0
    try {
        const result = await api.apiPrepare.getBuyXGetY({
            "from_origin": "*",
            "userId": "admin",
            "tgl_awal": dateawal,
            "tgl_akhir": dateakhir,
            "filter": "{\"search1\":\"\",\"search_col1\":\"all\",\"search2\":\"\",\"search_col2\":\"\",\"search3\":\"\",\"search_col3\":\"\",\"sort_col1\":\"id\",\"sort_dir1\":\"desc\",\"sort_col2\":\"id\",\"sort_dir2\":\"asc\",\"sort_col3\":\"id\",\"sort_dir3\":\"asc\",\"jumlah_baris\":50,\"total_baris\":9,\"halaman\":0,\"kolom_tanggal\":\"none\",\"showUpdated\":false}"
          })
          

        const response = result?.data.data
        if (!total_baris) {
            baris = result.total_baris
        }
        else {
            baris = total_baris
        }
        dispatch({type: SET_GET_PAKET, value: {
            data: data.concat(response),
            currentPage,
            category_id,
            total_baris: baris,
            loading: false
        } });
        dispatch({type: SET_PRODUK, value: {
            data: data.concat(response),
            currentPage,
            category_id,
            total_baris: baris,
            loading: false
        }})
   
    } catch (error) {
        console.log("data produk", error)
    }
}