// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCLbqXS0umqjHxyUKL75V4dVdYDLERdvRs",
  authDomain: "uruz-n.firebaseapp.com",
  databaseURL: "https://uruz-n-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "uruz-n",
  storageBucket: "uruz-n.appspot.com",
  messagingSenderId: "369318221179",
  appId: "1:369318221179:web:6fbf01295556a1f24d19de"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export default app