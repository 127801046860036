import React from 'react'
import Navbar from '../../components/Navbar'
import { GoogleMap, useJsApiLoader, Marker, LoadScript } from '@react-google-maps/api';
import Input from '../../components/Input';
import { useLocation, useNavigate } from 'react-router-dom'
import Geocode from "react-geocode";
import { getFirestore, doc, getDoc, setDoc} from "firebase/firestore";
import InputPhone from '../../components/InputPhone';
import formatPhone from '../../utils/FormatPhone';
import Button from '../../components/Button';
import { googleMapApiKey, router } from "../../Constants"
import Gap from '../../components/Gap';
import { useToasts } from 'react-toast-notifications'
import uuid from 'react-uuid'
import { Footer } from '../../components/BottomBar/BottomBar';
import { Container } from 'react-bootstrap';
import UruzB from '../../Assets/UruzBlue.png';

const containerStyle = {
    width: '100%',
    height: '300px'
  };
  
Geocode.setApiKey(googleMapApiKey);

export default function AddAlamatPengirim({}) {
    const { addToast } = useToasts()
    const navigate = useNavigate()
    const location = useLocation()
    const [position, setPosition] = React.useState({
        lat: 0, lng: 0
    })
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

    const [loading, setLoading] = React.useState(false)
    const [cekLokasi, setCekLokasi] = React.useState(false)
    const [alamat, setAddress] = React.useState({
        alamatSebagai: '',
        namaPenemerima: '',
        nomerHp: '',
        provinsi: '',
        kabupaten: '',
        kecamatan: '',
        kodePos: '',
        alamatLengkap: '',
        detailAlamat: '',
        longitude: 0,
        latitude: 0,
        email: ''
    })
    
    const onLoad = marker => {
        console.log('marker: ', marker)
    }

    function geoCode(latitude, longitude) {
        const users = JSON.parse(localStorage.getItem('login'))
        Geocode.fromLatLng(latitude, longitude).then(
            response => {
                const address = response.results[0].address_components;
                const geometry = response.results[0].geometry;
                let alamatUsers = {}
                let data = ''
                let indexPolitical = 0
                for(const items of address){
                    if (items['types'][0] == 'administrative_area_level_4') {
                        alamatUsers['desa'] = items['long_name']
                    }
                    if (items['types'][0] == 'administrative_area_level_3') {
                        alamatUsers['kecamatan'] = items['long_name']
                    }
                    if (items['types'][0] == 'administrative_area_level_2') {
                        alamatUsers['kabupaten'] = items['long_name']
                    }
                    if (items['types'][0] == 'administrative_area_level_1') {
                        alamatUsers['provinsi'] = items['long_name']
                    }
                    if (items['types'][0] == 'country') {
                        alamatUsers['negara'] = items['long_name']
                    }
                    if (items['types'][0] == 'postal_code') {
                        alamatUsers['kodePos'] = items['long_name']
                    }
                    if (['route', 'street_number', 'political','administrative_area_level_4'].includes(items['types'][0])) {
                        if (items['types'][0] == 'route') {
                            data += items['short_name'] + ' '
                        }
                        if (items['types'][0] == 'street_number') {
                            data += items['short_name'] + ' '
                        }
                        if (items['types'][0] == 'political') {
                            indexPolitical += 1
                            if (indexPolitical == 1) {
                                data += `RT.${items['short_name']}/`
                            }
                            if (indexPolitical == 2) {
                                data += `RW.${items['short_name']} `
                            }
                        }
                        if (items['types'][0] == 'administrative_area_level_4') {
                            data += items['short_name'] + ' '
                        }
                        alamatUsers['alamatLengkap'] = data
                    }
                }
                
                const { type, name, nomerHp, detailAlamat } = location['state']['addressUsers']
                let input = {}

                if (users && location['state']['type'] !== "edit") {
                    input['nama'] = users['nama']
                    input['phone'] = formatPhone(users['telp'])
                    input['alamatSebagai'] = ''
                    input['detailAlamat'] = ''
                    input['email'] = ''
                }
                
                if (location['state']['type'] == "edit") {
                    const { email } = location['state']['addressUsers']['dataWithout']
                    if (users) {      
                        input['nama'] = name
                        input['phone'] = formatPhone(nomerHp)
                    }
                    input['alamatSebagai'] = type
                    input['detailAlamat'] = detailAlamat
                    input['email'] = email
                }
                
                if (!users && location['state']['type'] !== "edit") {
                    input['nama'] = ''
                    input['phone'] = ''
                    input['alamatSebagai'] = ''
                    input['detailAlamat'] = ''
                    input['email'] = ''
                }
                setAddress((val) => (Object.assign({
                    ...val,
                    namaPenemerima: input['nama'],
                    nomerHp: input['phone'],
                    longitude: geometry['location']['lng'],
                    latitude: geometry['location']['lat'],
                    alamatSebagai: input['alamatSebagai'],
                    detailAlamat: input['detailAlamat'],
                    email: input['email']
                }, alamatUsers )))
            },
            error => {
                console.log(error);
            }
        );
    }

    React.useEffect(() => {
        if (location['state']) {        
            if (location['state']['position']) {
                setPosition(location['state']['position'])
                geoCode(location['state']['position']['lat'], location['state']['position']['lng'])
            }
        }
    },[location])
        
    React.useEffect( async () => {
        if (location['state']) {            
            if (location['state']['type'] == "new") {
                if (navigator.geolocation) {
                    await navigator.geolocation.getCurrentPosition((position) =>  {
                        // let pos = {
                        //     lat: position.coords.latitude,
                        //     lng: position.coords.longitude
                        //   }
                          
                        // setPosition(pos)
                        // geoCode(position.coords.latitude, position.coords.longitude)
                        let pos = {
                            lat: location?.['state']?.['position']?.['lat'] ? location?.['state']?.['position']?.['lat'] : position.coords.latitude,
                            lng: location?.['state']?.['position']?.['lng'] ? location?.['state']?.['position']?.['lng'] : position.coords.longitude
                          }
                          
                        setPosition(pos)
                        geoCode(location?.['state']?.['position']?.['lat'] ? location?.['state']?.['position']?.['lat'] : position.coords.latitude, location?.['state']?.['position']?.['lng'] ? location?.['state']?.['position']?.['lng'] : position.coords.longitude)
                    }, (error) => {
                        if (!localStorage.getItem('validasi')) {                
                            addToast("Izinkan permission lokasi untuk mendapatkan informasi lokasi", {
                                appearance: 'error',
                                autoDismiss: true,
                                autoDismissTimeout: 4000
                            })
                            localStorage.setItem('validasi', true)
                        }
                    })
                }
            }
        }

        window.addEventListener('popstate', (event) => {
            if (location['state']['type'] == "edit" || !localStorage.getItem('login')) {
                navigate(`/${router}detail-order`)
            }
            else {
                navigate(`/${router}alamat-pengiriman`)
            }
        });
    },[])


    async function onSave() {
        if (alamat.alamatSebagai == "") {
            addToast("Alamat sebagai harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })

            return
        }
        if (alamat.namaPenemerima == "" && localStorage.getItem("login")) {
            addToast("Nama penerima harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })

            return
        }
        if (alamat.nomerHp == "" && localStorage.getItem("login")) {
            addToast("Nomer handphone harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })

            return
        }
        if (alamat.alamatLengkap == "") {
            addToast("Alamat lengkap harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })

            return
        }
        if (alamat.detailAlamat == "") {
            addToast("Detail alamat harus diisi", {
                appearance: 'error',
                autoDismiss: true,
            })

            return
        }
        if (alamat.provinsi == "") {
            addToast("Provinsi harus diisi, silakan klik cari alamat", {
                appearance: 'error',
                autoDismiss: true,
            })

            return
        }

        else {
            setLoading(true)
            alamat['nomerHp'] = '+62' + alamat['nomerHp']
            let users = JSON.parse(localStorage.getItem('login'))

            const items = {
                type: alamat['alamatSebagai'],
                alamat: `${alamat.alamatLengkap} ${alamat.kecamatan}, ${alamat.kabupaten}, ${alamat.provinsi} ${alamat.kodePos}, ${alamat.negara}`,
                detailAlamat: `${alamat.detailAlamat}`,
                lat: alamat['latitude'],
                lng: alamat['longitude'],
                dataWithout: alamat
            }
            if (!users) {
                localStorage.setItem('without', JSON.stringify(alamat))
                localStorage.setItem('alamatUsers', JSON.stringify(items))
            }
            if (users) {            
                let docData = []
                const db = getFirestore();
                const docRef  = doc(db, "address", users.telp);
                const docSnap = await getDoc(docRef);
                const dataUsers = docSnap.data()
                if (location['state']['type'] !== "edit") {
                    alamat['key'] = uuid()
                    if (dataUsers !== undefined) {
                        docData.push(...dataUsers.alamat)
                        docData.push(alamat)
                    }
                    else {
                        docData.push(alamat)
                    }
                }
                else {
                    const res = dataUsers.alamat.filter(items => items.key !== location?.state?.addressUsers?.dataWithout?.key)
                    alamat['key'] = location?.state?.addressUsers?.dataWithout?.key
                    res.push(alamat)
                    docData.push(...res)
                }
                try {
                    setDoc(doc(db, "address", users.telp), { nama : users['nama'], alamat: docData }, { merge: true })
                    .then((res) => {
                    }).catch(error => console.log(error.response));
                } catch (error) {
                    console.log("response2", error.response)
                    
                }
            }
            setLoading(false)
            setAddress({})
            if (location['state']['type'] == "edit" || !users) {                    
                if (users) {
                    items.name = alamat.namaPenemerima
                    items.nomerHp = alamat.nomerHp
                }
                
                localStorage.setItem('alamatUsers', JSON.stringify(items))
                const xi = JSON.parse(localStorage.getItem('longlat'))
                if (!xi) {     
                    localStorage.setItem('longlat', false)
                }
                else {
                    if (items.lat !== xi.lat && items.lng !== xi.lng) {
                        localStorage.setItem('longlat', false)
                    }
                    else {
                        localStorage.setItem('longlat', true)
                    }
                }
                navigate(`/${router}detail-order`)
            }
            else {
                navigate(`/${router}alamat-pengiriman`, {state:{
                    addressUsers: alamat}
                })
            }
        }
    }
    
    return (
        <div>
            <Navbar setCekLokasi={setCekLokasi} cekLokasi={cekLokasi} urlBack={ (location['state'] && location['state']['type'] == "edit") || !localStorage.getItem('login') ? "detail-order" :"alamat-pengiriman"} 
                type="Add-Address" label={location?.state?.type == "edit" ? "Edit Alamat Pengiriman" :"Tambah Alamat Pengiriman"}/>
            
            <div style={{ paddingTop: '1vh', margin: '10px', paddingBottom:'1vh'}}>
                <LoadScript googleMapsApiKey={googleMapApiKey} >             
                    <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={14}>
                        <Marker onLoad={onLoad} position={position} />
                    </GoogleMap>
                </LoadScript>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30}}>
                    <button onClick={() => navigate(`/${router}search-alamat`, {state:location?.state})} style={{ width: '100%', padding: '10px 0 10px 0', border: '1px solid #5451D6', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F3F3FF'}}>
                        <span style={{ fontSize: 13, color: '#5451D6', marginLeft: 5}} className="fonts500">Cari Alamat</span>
                    </button>
                </div>
                
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Input onChange={(value) => setAddress(val => ({
                        ...val,
                        alamatSebagai: value.target.value
                    }))} value={alamat['alamatSebagai']} placeholder="Contoh: rumah, kantor, gudang, dll" label="Alamat Sebagai" backgroundColor="#F8F8F8"/>
                    {
                        localStorage.getItem("login") &&
                        <Input onChange={(value) => setAddress(val => ({
                            ...val,
                            namaPenemerima: value.target.value
                        }))} value={alamat['namaPenemerima']} placeholder="Nama Penerima" label="Nama Penerima" style={{marginTop: 10}} backgroundColor="#F8F8F8"/>
                    }
                    {
                        localStorage.getItem("login") &&
                        <InputPhone disabled={ location['state']['type'] == "edit" ? true : false} onChange={(value) => setAddress(val => ({
                            ...val,
                            nomerHp: value
                        }))} value={alamat['nomerHp']} placeholder="Nomor Handphone" label="Nomer Handphone" style={{marginTop: 10}} backgroundColor="#F8F8F8"/>
                    }
                    {
                        localStorage.getItem("login") &&
                        <Input onChange={(value) => setAddress(val => ({
                            ...val,
                            email: value.target.value
                        }))} value={alamat['email']} placeholder="example@gmail.com" label="Email" style={{marginTop: 10}} backgroundColor="#F8F8F8"/>
                    }
                    <Input disabled={true} onChange={(value) => setAddress(val => ({
                        ...val,
                        provinsi: value.target.value
                    }))} value={alamat['provinsi']} placeholder="Provinsi" label="Provinsi" style={{marginTop: 10}} backgroundColor="#d6d4d4"/>
                    <Input disabled={true} onChange={(value) => setAddress(val => ({
                        ...val,
                        kabupaten: value.target.value
                    }))} value={alamat['kabupaten']} placeholder="Kabupaten/Kota" label="Kabupaten/Kota" style={{marginTop: 10}} backgroundColor="#d6d4d4"/>
                    <Input disabled={true} onChange={(value) => setAddress(val => ({
                        ...val,
                        kecamatan: value.target.value
                    }))} value={alamat['kecamatan']} placeholder="Kecamatan" label="Kecamatan" style={{marginTop: 10}} backgroundColor="#d6d4d4"/>
                    <Input disabled={true} onChange={(value) => setAddress(val => ({
                        ...val,
                        kodePos: value.target.value
                    }))} value={alamat['kodePos']} placeholder="Kode Pos" label="Kode Pos" style={{marginTop: 10}} backgroundColor="#d6d4d4"/>
                    <Input onChange={(value) => setAddress(val => ({
                        ...val,
                        alamatLengkap: value.target.value
                    }))} value={alamat['alamatLengkap']} placeholder="Alamat Lengkap" label="Alamat Lengkap" style={{marginTop: 10}} backgroundColor="#F8F8F8"/>
                    <Input onChange={(value) => setAddress(val => ({
                        ...val,
                        detailAlamat: value.target.value
                    }))} value={alamat['detailAlamat']} placeholder="Contoh: Deket alfamart sebelah pom mini" label="Detail Alamat" style={{marginTop: 10}} backgroundColor="#F8F8F8"/>
                    <Gap height={30} />
                    <Button onClick={() => {
                        onSave()
                    }} color="#fff" backgroundColor="#5451D6" loading={loading}>
                        Simpan Alamat
                    </Button>
                    <Gap height={100} />
                
                </div>
                <Container>
          <div className='bottomNav'>
              Powered by <img className='Footer-Logo' src={UruzB}></img>
          </div>
      </Container> 
            </div>


            </div>
    )
}