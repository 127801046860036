import React, { useState } from 'react'
import './Style.css'
import Navbar from '../../components/Navbar';
import Head from '../../Assets/Head'
import Responsif from '../../utils/Responsif'
import { Link, useNavigate } from 'react-router-dom'
import {DMSansBold, DMSansMedium, } from '../../fonts/index'
import { AppBarThreeItem } from '../../components/AppBar/AppBar'
import ModalCategory from '../Order/ModalCategory'
import { Kategory } from '../../Layout'
import DetailProduct from '../Order/DetailProduct'
import { MenuBottomNav } from '../../components/BottomBar/BottomBar'
import Merch1 from '../../Assets/Merchant/M1.png';
import { Container, Row, Col } from 'react-bootstrap';
import Order from '../Order/Order'
import { useSelector, useDispatch } from "react-redux"
import Gap from '../../components/Gap';
import { url, router } from '../../Constants.js'

import { SetKategori, getKategory, Produk, Shift } from "../../redux/actions/prepareActions"

import { BiMap, BiChevronDown } from "react-icons/bi";
import { FiPhone } from "react-icons/fi";

import FETCH_API from '../../config/fetch_api'
import formatterMoney from '../../utils/formatMoney'
import ModalBayar from '../../Pages/Order/ModalBayar';
import Loading from '../../components/Loading';
import { useToasts } from 'react-toast-notifications'
import { decrypt, encrypt } from "../../utils/enkripsi"
import api from '../../request';
import moment from 'moment';
import { RiEditBoxLine } from "react-icons/ri"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { GrRefresh } from "react-icons/gr"
import Modal from '../../components/Modal';
import { getFirestore, doc, getDoc, setDoc, collection, getDocs} from "firebase/firestore";
import Logo from "../../Assets/Logo"
import { getDistanceAndPrice } from "../../redux/actions/gojekActions"
import { Kategory as LayoutKategory, TabBottom } from "../../Layout"
import Food1 from '../../Assets/Food/Food1.png';
import Time from '../../Assets/Icon/Time.png';
import Phone from '../../Assets/Icon/Phone.png';

// const button = [
//   {
//     name : 'Book Table'
//   },
//   {
//     name : 'Order Food Online'
//   },
//   {
//     name : 'GOFOOD'
//   },
//   {
//     name : 'GRABFOOD'
//   },
//   {
//     name : 'Feast At Home'
//   },
//   {
//     name : 'Contact Us On Whatsapp'
//   },
// ]

function Home(params) {
    function onClickCategory(items) {
        let newArr = [...state?.kategory]
        for(const x of newArr){
            if (x.id == items.id) {
                x['selection'] = true
            }
            else {
                x['selection'] = false
            }
        }
        dispatch(SetKategori(newArr))
        dispatch({
            type: 'SET_PRODUK',
            value: {
                data: [],
                loading: true
            }
        })
        dispatch(Produk({
            category_id: items.id
        }))
    }

    const { width } = Responsif()
    const navigate = useNavigate()
    // const navigate = useNavigate();
    const dispatch = useDispatch()
    const state = useSelector(state => state?.dataPrepare)
    const produk = state?.produk
    const shift = state?.shift
    const { addToast } = useToasts()
    const [showBayar, setShowBayar] = React.useState(false)
    const [sidebar, setSidebar] = React.useState(false)
    const [dataProduct, setDataProduct] = React.useState([])
    let login = localStorage.getItem('login')
    const [dataOrder, setDataOrder] = React.useState({})
    const [dataBranch, setDataBranch] = React.useState({})
    const [visible, setVisible] = React.useState(false)
    
    let pesanan = localStorage.getItem('pesanan')
    pesanan = pesanan == null ? [] : decrypt(JSON.parse(pesanan))
    const [style, setStyle] = React.useState({})
    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,   
        function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    const handleScroll = (e) => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom + 1 >= docHeight) {
            if (produk.total_baris > 0) {                
                dispatch(Produk({
                    ...produk,
                    currentPage : produk.currentPage + 10,
                    total_baris : produk.total_baris - 10
                }))
            }
        }
    };
    
    React.useEffect(() => {
        if (produk.data) {
            setDataProduct(produk.data)
        }
        document.addEventListener('scroll', handleScroll);
    
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [produk]);

    React.useEffect(async () => {
        // console.log(decrypt('U2FsdGVkX1/HagwcVfOGuhaF4V1a9Lc0RrEIACSbdUXrNMwcK+oBVppeGkpJNStuPCCsK9C6i987Ayn6MH0FIkw7cy1/Om9IvmajnKU0Agf19S/CZiOsd3tFWX9FanlfPy17Kqsmzf4jQHpLxyyuxeGEET/MkZmrNnxXMMXQ6DB/2ZyjQybaBYv/9+mIsiCT'))
        const prepareLogin = JSON.parse(sessionStorage.getItem('prepareLogin'))
        const dataBranch = JSON.parse(localStorage.getItem('dataBranch'))
        const val = getUrlVars()
        if (!login && !prepareLogin) {     
            setTimeout(() => {
                setSidebar(true)
                setStyle({
                    width: '90%',
                    top: '30%',
                    position: 'absolute',
                    left: '5%'
                })
            }, 2000)    
        }
        if (val?.x2) {   
            const branchQueryString = decrypt(val.x2)
            if (branchQueryString?.userServer && branchQueryString?.cabang) {
                setDataBranch(branchQueryString?.cabang)
                localStorage.setItem('dataBranch', JSON.stringify(branchQueryString?.cabang))
                localStorage.setItem("userServer", JSON.stringify(branchQueryString?.userServer))
                const coa_transaksi = await api.order.coa_branch({
                    userId: ""
                })
                localStorage.setItem("coa_transaksi", JSON.stringify(coa_transaksi.data.data[0]))
                
                const db = getFirestore();
                const querySnapshot = await getDocs(collection(db, "clients"));
                querySnapshot.forEach(async (doc) => {
                    const restoran = {
                        namaRestoran : doc.id,
                        cabang: doc.data().branches,
                        connection: doc.data().connection,
                        location: ""
                    }
                    if (restoran?.connection == branchQueryString?.userServer) {
                        let branch = []
                        for(const dataBranch of restoran?.cabang){
                            branch.push(dataBranch.branch_id)
                        }
                        const resultData = await api.branches.getPos({ "branches"   : branch })
                        const res = resultData?.data?.data
                        localStorage.setItem('local_branch', JSON.stringify(res))
                        setShowBayar(true)
                        setDataOrder(branchQueryString.produk)
                        dispatch(getKategory())
                        dispatch(Produk())
                        dispatch(Shift()) 
                    }
                });
            }
            else {
                navigate("/")
            }
        }
        else {
            if (dataBranch) {
                setDataBranch(dataBranch)
                const coa_transaksi = await api.order.coa_branch({
                    userId: ""
                })
                
                localStorage.setItem("coa_transaksi", JSON.stringify(coa_transaksi.data.data[0]))
                if (produk.data.length == 0) {           
                    dispatch(getKategory())
                    dispatch(Produk())
                    dispatch(Shift())
                }
            }
            else {
                navigate("/")
            }
        }

    },[])

    function onOrder(items) {
        console.log(items)
        navigate("/delivery/detailproduct",{items})
        setDataOrder(items)
    }

    if (pesanan.length !== 0) {
        for(const item of dataProduct){
            for(const items of pesanan){
                if (item['product_id'] == items['product_id']) {
                    item['item'] = items['item']
                }
            }
        }
    }
    else {
        for(const item of dataProduct){
            item['item'] = null
        }
    }

    function imageExists(url, callback) {
        var img = new Image();
        img.onload = function() { callback(true); };
        img.onerror = function() { callback(false); };
        img.src = url;
    }

    const [isVisible, setIsVisible] = useState(false);

    const handlePress = () => {
        setIsVisible(true);
    }

    const handleClose = () => {
        setIsVisible(false);
    }
    return(
        <div className='Main'>
            
            
            
            <MenuBottomNav />
            
            {/* <TabBottom /> */}
        </div>
    )
}

export default Home